import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ILogRequest,
  ISupportLogResponse,
  LogType,
  useGetAppDeviceLogs,
} from 'src/Utils/Hooks/AppDeviceLogs/useGetAppDeviceLogs';
import DeviceSearch, {
  UrlParam_DeviceType,
  UrlParam_SerialNumber,
} from '../DeviceSearch/DeviceSearch';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import Loader from '../Loader';
import { selectDevice } from 'src/Redux/genericSlice';
import { useAppSelector } from 'src/Redux/hooks';
import tableStyles from 'src/CssModules/table.module.css';
import spanStyles from 'src/CssModules/span.module.css';
import SearchBar from '../SearchBar/SearchBar';
import DateRangePicker, {
  getStartDate,
  UrlParam_FromDate,
  UrlParam_ToDate,
} from '../DateRangePicker/DateRangePicker';
import { useSearchParams } from 'react-router-dom';
import { Required_Serial_Number } from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearch';
import InfoDialog from '../InfoDialog/InfoDialog';
import PaginationButtons from '../PaginationButtons/PaginationButtons';
import IconWithTooltip, { TooltipPosition } from 'src/Components/IconWithTooltip/IconWithTooltip';
import SupportContactInfoDialog from 'src/Components/SupportContactInfoDialog/SupportContactInfoDialog';

import ShowSelfTestDialog from 'src/Components/SelfTestsReportDialog/SelfTestsReportDialog';
import DownloadLogs from 'src/Components/DownloadLogs/DownloadLogs';

import selfTestsImageLogo from 'src/images/SelfTestsLogo.png';
import selfTestsLogoWithErrors from 'src/images/SelfTestsLogoWithErrors.png';
import FlightReplay from '../FlightReplay/FlightReplay';

const DeviceIssues: React.FC = () => {
  // Trigger device search request on search click
  type DeviceSearchHandle = React.ElementRef<typeof DeviceSearch>;
  const ref = React.useRef<DeviceSearchHandle>(null);
  // Url params get and set
  const [searchParams, setSearchParams] = useSearchParams();
  let urlParms = {
    fileName: searchParams.get('FileName'),
    fromDate: searchParams.get(UrlParam_FromDate),
    toDate: searchParams.get(UrlParam_ToDate),
    sn: searchParams.get(UrlParam_SerialNumber),
    deviceType: searchParams.get(UrlParam_DeviceType),
  };
  const toDate = useMemo(
    () => (urlParms.toDate !== null ? new Date(urlParms.toDate) : new Date()),
    [urlParms.toDate]
  );
  let fromDate = useMemo(() => getStartDate(toDate), [toDate]);
  fromDate =
    urlParms.fromDate !== null ? new Date(urlParms.fromDate) : fromDate;

  const from = useRef(fromDate.toUTCString());
  const to = useRef(toDate.toUTCString());

  const fileName = useRef<string>(
    urlParms.fileName === null ? '' : urlParms.fileName
  );

  const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
  const [showContactInfoDialog, setShowContactInfoDialog] = useState(false);
  const [showSelfTestDialog, setShowSelfTestDialog] = useState(false);


  const [deviceSearchError, setDeviceSearchError] = useState<string>('');
  const [deviceSearhLoading, setDeviceSearchLoaing] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ISupportLogResponse>();

  const serialNumber = useRef(urlParms.sn ?? '');
  const deviceInfo = useAppSelector(selectDevice);
  const deviceType = useRef(
    urlParms.deviceType !== null
      ? urlParms.deviceType
      : deviceInfo.data.DeviceType
  );

  const getFileName = () => {
    return fileName.current !== '' ? `${fileName.current}` : '';
  };

  const [logRequest, setLogRequest] = useState<ILogRequest>({
    FromDateTimeUtc: from.current,
    LogType: LogType.issue,
    ToDateTimeUtc: to.current,
    FileName: getFileName(),
  });
  const { supportLogDetails, logError, paginationLoading, getPageDetails, getPreviousPageDetails } =
    useGetAppDeviceLogs(logRequest);

  const [replayToolLoading, setReplayToolLoading] = useState(false);
  const [replayLoadStatus, setReplayLoadStatus] = useState<string | null>(null);

  const setUrlParams = useCallback(() => {
    let urlParams = {
      FromDate: from.current.toString(),
      ToDate: to.current.toString(),
      LogType: LogType.issue.toString(),
      DeviceType: deviceType.current,
      SerialNumber: serialNumber.current,
      FileName: fileName.current,
    };
    setSearchParams(urlParams);
  }, [setSearchParams]);

  const handleSubmit = useCallback(() => {
    ref.current?.UpdateDeviceSearch();
    let formRequest: ILogRequest = {
      FromDateTimeUtc: from.current,
      ToDateTimeUtc: to.current,
      LogType: LogType.issue,
      FileName: getFileName(),
    };
    let areEqual =
      Object.entries(formRequest).toString() ===
      Object.entries(logRequest).toString();
    setUrlParams();
    if (!areEqual) {
      setLogRequest(formRequest);
    }
  }, [from, to, setUrlParams, logRequest]);
  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );
  useEffect(() => {
    setUrlParams();
  }, [setUrlParams]);

  const onChangeStartDate = useCallback((date: Date) => {
    from.current = date.toUTCString();
  }, []);
  const onChangeEndDate = useCallback((date: Date) => {
    to.current = date.toUTCString();
  }, []);

  const showDiscription = (item:ISupportLogResponse) => {
    setShowDescriptionDialog(true);
    setSelectedItem(item);
  }

  const showCotactInfo = (item:ISupportLogResponse) => {
    setShowContactInfoDialog(true);
    setSelectedItem(item);
  }

  const showSelfTestInfo = (item: any) => {
    setShowSelfTestDialog(true);
    setSelectedItem(item);
  }

  const contactDetailsExist = (item:ISupportLogResponse) => {
    return (item.contactEmail && item.contactEmail !== '' )||
           (item.countryCode && item.countryCode !== '')||
           (item.contactName && item.contactName !== '');
  }

  const selfTestReportsExist = (item: any) => {
    return item.deviceTestResult && Object.entries(item.deviceTestResult).length > 0;
  }

  const replayToolDataExists = (item: any) => {
    return item.replayLog && item.replayLog !== '' && item.replayLogDownLoadLink && item.replayLogDownLoadLink !== '';
  }

  return (
    <>
      <LayoutWrapper>
      { showDescriptionDialog && 
      <InfoDialog 
           title='Description'
            onClose={() => setShowDescriptionDialog(false)}>
                <p style={{ color: 'white' }}>{selectedItem?.description}</p>
            </InfoDialog>
      }
      {
        replayLoadStatus !== null && replayLoadStatus.includes('Specified blob does not exist') && 
        <InfoDialog 
           title=''
            onClose={() => {setShowDescriptionDialog(false); setReplayLoadStatus(null)}}>
                <p style={{ color: 'white' }}>{replayLoadStatus}</p>
            </InfoDialog>
      }
       { showContactInfoDialog && 
         <InfoDialog 
          title='Contact Information'
          onClose={() => setShowContactInfoDialog(false)}
          headerAlign='center'
          dialogWidth='25%'
          >
              <SupportContactInfoDialog contactName={selectedItem?.contactName} contactEmail={selectedItem?.contactEmail} countryCode={selectedItem?.countryCode}/>
          </InfoDialog>
       }

       { showSelfTestDialog && 
         <InfoDialog 
          title='Self-Test Results'
          onClose={() => setShowSelfTestDialog(false)}
          headerAlign='center'
          dialogWidth='65%'
          >
              <ShowSelfTestDialog deviceTestsResult={selectedItem?.deviceTestResult}/>
          </InfoDialog>
       }

        <form onSubmit={onSubmit}>
          <div className="row">
            <DeviceSearch
              onDeviceTypeChange={(val) => {
                deviceType.current = val;
              }}
              onSerialNumberChange={(val) => {
                serialNumber.current = val;
              }}
              ref={ref}
              setErrorMessage={setDeviceSearchError}
              setLoading={setDeviceSearchLoaing}
            />
            <DateRangePicker
              onStartDateChange={onChangeStartDate}
              onEndDateChage={onChangeEndDate}
            />
          </div>

          <div className="row mb-3">
            <div className="col-sm-3">
              <SearchBar
                testId='fileName'
                placeholder="File Name (optional)"
                value={fileName.current}
                onTextChange={(e) => {
                  fileName.current = e;
                }}
              />
            </div>

            <div className="col-sm-3">
              <button className="btn-prim" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </form>
        <div className="row">
          {deviceSearhLoading && <Loader />}
          {paginationLoading && <Loader />}
          {replayToolLoading && <Loader message='Please wait, Flight replay tool is loading' />}

          {deviceSearchError && deviceSearchError !== Required_Serial_Number ? (
            <ErrorMessage message={deviceSearchError} />
          ) : logError ? (
            <ErrorMessage message={logError} />
          ) : supportLogDetails.data.length === 0 ? (
            <ErrorMessage message="No logs found for given search criteria" />
          ) : (
            <>
            <div style={{paddingLeft:'130px'}}>
              <PaginationButtons 
                    hideNext={supportLogDetails.hideNextButton} 
                    hidePrevious={supportLogDetails.hidePrevButton} 
                    getNextPageDetails = {getPageDetails}
                    getPreviousPageDetails = {getPreviousPageDetails}
                    />
            </div>
            <div className='col-md-12' style={{ height: '52.5vh' }}>
              <table style={{ width: '100%' }} data-testid="table">
                <thead className={`${tableStyles.tableHead}`}>
                  <tr key={'header'}>
                    <th className={tableStyles.heading}>Serial Number</th>
                    <th className={tableStyles.heading}>Created On (UTC)</th>
                    <th className={tableStyles.heading}>Description</th>


                    <th className={tableStyles.heading}>App Version</th>
                    <th className={tableStyles.heading}>Device Version</th>
                    <th className={tableStyles.heading}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {supportLogDetails.data.map((item, index) => (
                    <tr key={index}>
                      <td className={tableStyles.data}>{item.serialNumber}</td>
                      <td className={tableStyles.data}>{item.createdOn}</td>
                      <td className={tableStyles.data}>
                        <span className={spanStyles.ellipsisView} style={{textAlign: 'left'}} onClick={() => showDiscription(item)}>
                           {item.description}
                        </span>
                      </td>


                      <td className={tableStyles.data}>{item.appVersion}</td>
                      <td className={tableStyles.data}>{item.deviceVersion}</td>
                      <td className={tableStyles.data} style={{width: '140px'}}>

                      {
                        ((item.appLogDownLoadLink && item.appLogDownLoadLink !== '') || 
                          (item.deviceLogDownLoadLink && item.deviceLogDownLoadLink !== '') || 
                          (item.replayLogDownLoadLink && item.replayLogDownLoadLink !== '') || 
                          (item.telemetryLogDownLoadLink && item.telemetryLogDownLoadLink !== '')) && 
                        <DownloadLogs item={item}></DownloadLogs>
                      }

                      {contactDetailsExist(item)  &&
                            <span 
                              onClick={() =>
                                showCotactInfo(item)
                              }
                              style={{margin: '0px 10px 0px 0px'}}
                              data-testid={`info-${item.createdOn}`}
                            >
                              <IconWithTooltip
                                tooltipText="Contact"
                                icon="fa fa-user"
                                tooltipPosition={TooltipPosition.Top}
                                fontSize="19px"
                                top="2px"
                              ></IconWithTooltip>
                            </span>
                        }

                        {selfTestReportsExist(item)  &&
                            <span
                              data-testid="self-test-reports-icon-id"
                              onClick={() =>
                                showSelfTestInfo(item)
                              }
                            >
                              <IconWithTooltip
                                tooltipText="Self Tests"
                                icon="fa fa-pencil-square-o" 
                                style={{color: 'white'}}
                                imageLogo={item.selfTestsSuccessful ? selfTestsImageLogo : selfTestsLogoWithErrors}
                                tooltipPosition={TooltipPosition.Top}
                              ></IconWithTooltip>
                              
                            </span>
                        }

                        {replayToolDataExists(item)  &&
                          <FlightReplay replayLogRelativePath={item.replayLog} deviceType={urlParms.deviceType} setLoading={setReplayToolLoading}
                          setErrorMessage={setReplayLoadStatus}/>
                        }
                                    
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </>
          )}
        </div>
      </LayoutWrapper>
    </>
  );
};
export default DeviceIssues;
