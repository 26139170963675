import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { IEnrolmentGroup } from './useGetEnrollmentGroups';

export interface ICreateEnrollmentGroup {
    group: IEnrolmentGroup,
    withCertificates: boolean
}
export const useCreateEnrollmentGroup = (groupDetails: ICreateEnrollmentGroup) => {
  const [groupCreateDetails, setGroupCreateDetails] = useState<any>('');
  const [groupCreateError, setGroupCreateError] = useState<string>('');
  const [groupCreateLoading, setGroupCreateLoading] = useState<boolean>(true);

  const getEnrolmentGroupFormData = useCallback((): FormData => {
    let group = groupDetails.group;
    const formData = new FormData();
    let crtFile = group.groupCrtFile ? group.groupCrtFile : '';
    let keyFile = group.groupKeyFile ? group.groupKeyFile : ''
    let passwordFile = group.groupPasswordFile ? group.groupPasswordFile : ''
    let issuerFile = group.groupIssuerFile ? group.groupIssuerFile : ''
    formData.append('groupName', group.groupName);
    formData.append('commonName', group.commonName);
    formData.append('dpsName', group.dpsName);
    formData.append('isCertificateAvailable', group.isCertificateAvailable.toString());
    formData.append('deviceType', group.deviceType);
    formData.append('groupCrtFile', crtFile);
    formData.append('groupKeyFile', keyFile);
    formData.append('groupPasswordFile', passwordFile);
    formData.append('groupIssuerFile', issuerFile);
    return formData;
  },[groupDetails.group])

  const isValidData= useCallback(() => {
    if(groupDetails.group.groupName === '') {
        return false;
    } else if(groupDetails.group.commonName === ''){
        return false;
    }
    if(groupDetails.withCertificates) {
        if(groupDetails.group.groupCrtFile === undefined 
            || groupDetails.group.groupKeyFile === undefined 
            || groupDetails.group.groupIssuerFile === undefined
            || groupDetails.group.groupPasswordFile === undefined) {
                return false;
            } 
    }
    return true; 
  },[groupDetails.group, groupDetails.withCertificates])

  useEffect(() => {
    
    setGroupCreateLoading(true);
    const scope = Config.GetConfigData().provisioningService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    let requestUrl = `${baseUrl}provisioning/enrolment/groups`;
    if(!isValidData()) {
        setGroupCreateLoading(false);
    }
    
    else if (scope === undefined) {
      setGroupCreateError('Invalid scope');
      setGroupCreateLoading(false);
    } else {
      AuthConfig.getToken(scope).then(
        (success) => {
          let headers = {}  
          let data : any = '';
          
          if(!groupDetails.withCertificates) {
            headers = {
                Authorization: `Bearer ${success}`,
                'Content-Type': 'application/json'
            };
            data = JSON.stringify(groupDetails.group)
           } else {
            headers = {
                Authorization: `Bearer ${success}`,
            };
            data = getEnrolmentGroupFormData();
            requestUrl = requestUrl + '/certificates';
          }    
          logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and Adding group`);
          axios
            .post(requestUrl, data, { headers })
            .then(
              (response) => {
                setGroupCreateDetails(response);
                setGroupCreateError('');
              },

              (error) => {
                logger.logError(
                  'Failed to create group: ' +
                  JSON.stringify(groupDetails)
                );
                setGroupCreateError(error.response.data.Message);
                setGroupCreateDetails('');
              }
            )
            .finally(() => {
                setGroupCreateLoading(false);
            });
        },
        (error) => {
            setGroupCreateLoading(false);
            setGroupCreateError(error);
        }
      );
    }
  }, [groupDetails,getEnrolmentGroupFormData, isValidData]);

  return { groupCreateDetails, groupCreateError, groupCreateLoading };
};
