import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InfoMessageType } from '../InfoBar/InfoBar';
import Modal from 'src/CssModules/modal.module.css';
import { IStepDetails } from '../StepNavigation/Step';
import StepNavigation from '../StepNavigation/StepNavigation';
import AddDevice from './AddDevice';
import Summary from './Summary';
import Loader from '../Loader';
import { IDeviceEnrolmentRequest } from 'src/Utils/Hooks/DeviceEnrollment/useDevice';
import Status from './Status';
export interface IModelParams {
  onClose: () => void;
  onStatus: (message: string, messageType: InfoMessageType) => void;
}

const CreateDeviceEnrollment: React.FC<IModelParams> = ({
  onClose,
  onStatus,
}) => {
  const [display, setdisplay] = useState('block');
  const [loading, setLoading] = useState<boolean>(false);
  const [isNext, setNext] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [child, setChild] = useState<React.ReactNode>('');
  let enrollmentRequest: IDeviceEnrolmentRequest = {
    EnrollmentGroupName: '',
    SerialNoFrom: '',
    SerialNoTo: '',
  };
  const refRequest = useRef<IDeviceEnrolmentRequest>(enrollmentRequest);
  const close = useCallback(() => {
    setdisplay('none');
    onClose();
  }, [onClose]);

  const updateStep = (step: number) => {
    setStep(step);
  };
  const stepsInfo: IStepDetails[] = [
    {
      stepLabel: 'Add Devices',
      stepNumber: 1,
    },
    {
      stepLabel: 'Summary & Validation',
      stepNumber: 2,
    },
    {
      stepLabel: 'Download Certificates',
      stepNumber: 3,
      stepConfirmMessage: `Please verify the parameters provided for enrolling the devices, these cannot be modified later`,
    },
  ];

  const populateChild = useCallback(() => {
    if (step === 1) {
      return (
        <AddDevice
          addEnrollmentRequest={refRequest.current}
          addDeviceLoading={(load) => setLoading(load)}
          addDeviceError={(message) => () => {}}
          addDeviceData={(req) => {
            refRequest.current = req;
          }}
          isNext={(next) => setNext(next)}
        />
      );
    } else if (step === 2) {
      return (
        <Summary
          enrollmentRequest={refRequest.current}
          summaryLoading={(load) => setLoading(load)}
          isNext={(next) => setNext(next)}
        />
      );
    } else if (step === 3) {
      return (
        <Status
          enrollmentRequest={refRequest.current}
          statusLoading={(load) => setLoading(load)}
        />
      );
    }
  }, [step]);
  
  useEffect(() => {
    setChild(populateChild());
  }, [step, populateChild]);
  return (
    <>
      <div id="myModal" className={Modal.modal} style={{ display: display }}>
        <div className={Modal.content}>
          <div className={Modal.header}>
            <h4 className='text-center'>Device Enrollment</h4>
          </div>
          <hr style={{ color: 'white' }} />
          {loading && <Loader />}
          <div className={Modal.body}>
            <div style={{paddingLeft:90}}>
              <StepNavigation
                stepsInfo={stepsInfo}
                activeStep={step}
                isNext={isNext}
                enableBackOnLastStep={false}
                setCurrentStep={(stp) => updateStep(stp)}
                children={child}
              />
            </div>
          </div>
          <hr style={{ color: 'white' }} />
          <div className={Modal.footer}>
            <div className="row">
              <div className="col-sm-4" style={{ paddingLeft: '90px' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => close()}
                  data-testid="close"
                >
                  Close
                </button>
              </div>
              <div className="col-sm-3  ms-auto"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDeviceEnrollment;
