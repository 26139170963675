import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export interface IEnrolmentGroup {
  groupName: string;
  commonName: string;
  dpsName: string;
  isCertificateAvailable: boolean;
  deviceType: string;
  groupCrtFile?: File;
  groupKeyFile?: File;
  groupPasswordFile?: File;
  groupIssuerFile?: File;
  isProduction: boolean;
}
export const useGetEnrollmentGroups = (load: boolean) => {
  const [enrollmentGroupsLoading, setEnrollmentGroupsLoading] =
    useState<boolean>(true);
  const [enrollmentGroupsError, setEnrollmentGroupsError] =
    useState<string>('');
  const [enrollmentGroups, setEnrollmentGroups] = useState<IEnrolmentGroup[]>(
    []
  );
  useEffect(() => {
    setEnrollmentGroupsLoading(true);
    const scope = Config.GetConfigData().provisioningService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    const enrolmentGroupUrl = 'provisioning/enrolment/groups';
    const dpsName = Config.GetConfigData().dpsName;
    if (scope === undefined) {
      setEnrollmentGroupsError('Invalid scope');
      setEnrollmentGroupsLoading(false);
    } else {
      AuthConfig.getToken(scope).then(
        (success) => {
          const headers = {
            Authorization: `Bearer ${success}`,
            'Content-Type': 'application/json',
          };

          logger.logInformation(
            `Access token is  Received successfully  for scope :  ${scope} + 'and requesting enrollment groups`
          );
          axios
            .get<[IEnrolmentGroup]>(
              `${baseUrl}${enrolmentGroupUrl}/?DpsName=${dpsName}`,
              {
                headers,
              }
            )
            .then(
              (response) => {
                setEnrollmentGroups(response.data);
                setEnrollmentGroupsError('');
              },
              (error) => {
                logger.logError(
                  'Failed to get list of enrollment groups for given request: ' +
                    'error: ' +
                    JSON.stringify(error)
                );
                if (error.response !== undefined) {
                  setEnrollmentGroupsError(error.response.data.Message);
                }
                setEnrollmentGroups([]);
              }
            )
            .finally(() => {
              setEnrollmentGroupsLoading(false);
            });
        },
        (error) => {
          setEnrollmentGroupsError(error);
          setEnrollmentGroupsLoading(false);
        }
      );
    }
  }, [load]);
  return { enrollmentGroups, enrollmentGroupsError, enrollmentGroupsLoading };
};
