import { useCallback, useState } from 'react';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import usePagination, { IPageData, IPagingOption } from '../Pagination/useGetPagination';

export const usePaginationWrapper = <T extends Partial<T>>(pageOptions:IPagingOption, baseUrl:string, scope:string, searchQuery:string, additionalParams: any) => {
  let details : IPageData<T> = {
    data: [],
    hideNextButton: true,
    hidePrevButton: true
  }
  const [paginationDetails, setPaginationDetails] = useState<
   IPageData<T> | undefined
  >(details);
  const [paginationError, setPaginationError] = useState<string>('');
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const {getNextDisplayData, getPrevDisplayData, reset} = usePagination<T>(pageOptions, baseUrl, scope, searchQuery, additionalParams);
  
  const getPageDetails = useCallback(() => {
        setPaginationLoading(true);
        getNextDisplayData().then(
        paginationData => {
            setPaginationDetails(paginationData);
            setPaginationError('');
        },
        error => {
            logger.logError(
                'Failed to get list for given request: ' +
                  searchQuery +
                  '.error' +
                  JSON.stringify(error)
              );
              setPaginationError(error.message);
              setPaginationDetails(undefined);
        }
    ).finally(() => {
        setPaginationLoading(false);
      });
    
  },[getNextDisplayData, searchQuery])

  const getPreviousPageDetails = useCallback(() => {
    setPaginationLoading(true);
    getPrevDisplayData().then(
        paginationData => {
            setPaginationDetails(paginationData);
            setPaginationError('');
        },
        error => {
            logger.logError(
                'Failed to get device list for given request: ' +
                   searchQuery +
                  '.error' +
                  JSON.stringify(error)
              );
              setPaginationError(error.message);
              setPaginationDetails(undefined);
        }
    ).finally(() => {
        setPaginationLoading(false);
      });
  },[getPrevDisplayData, searchQuery])

  return {
    paginationDetails,
    paginationError,
    paginationLoading,
    getPageDetails,
    getPreviousPageDetails,
    reset
  };
};