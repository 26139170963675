import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { ISubNavbarItemList} from '../SubNavBar/SubNavBar';
import TopNavBar from '../TopNavBar/TopNavBar';
import { CommonItems, MapViewItems, ProductionSubNavMenuItems, ProductionTeamTopItems, SupportSubNavMenuItems, SupportTeamTopItems } from './NavbarItems';
export interface ITopNavbarItems{
  id:string;
  name:string
  testid:string;
  routePath:string;
  hasChildren:boolean;
}
export interface ITopnavbarItemList {
  items: ITopNavbarItems[]
}
export interface INavbarItemList {
  topNav: ITopnavbarItemList;
  subNav: ISubNavbarItemList;
}
const NavBar: React.FC<{isSupportUser: boolean, isProductionUser:boolean, isMapUser: boolean}> = (users) => {

  const refNavbarItems = useRef<INavbarItemList>({topNav: {items: CommonItems}, subNav:{items: []}});
  const [navBarItems, setNavBarItems] = useState<INavbarItemList>(refNavbarItems.current);

  const createList = (supportUser:boolean, productionUser:boolean, mapUser:boolean) => {

    if(supportUser) {
      refNavbarItems.current.topNav.items = [...refNavbarItems.current.topNav.items, ...SupportTeamTopItems];
      refNavbarItems.current.subNav.items = [...refNavbarItems.current.subNav.items, ...SupportSubNavMenuItems];
    }

    if(productionUser) {
  
      refNavbarItems.current.topNav.items = [...refNavbarItems.current.topNav.items, ...ProductionTeamTopItems];
      refNavbarItems.current.subNav.items = [...refNavbarItems.current.subNav.items, ...ProductionSubNavMenuItems];
    }
    if(mapUser) {
      refNavbarItems.current.topNav.items = [...refNavbarItems.current.topNav.items, ...MapViewItems];
    }

    let details = {...refNavbarItems.current};
    setNavBarItems(details)
  }

  useEffect(() => {
    createList(users.isSupportUser,users.isProductionUser, users.isMapUser)
  },[users.isSupportUser, users.isProductionUser,users.isMapUser])
  
  return (
    <>
      <TopNavBar topNavList={navBarItems.topNav} subNavList= {navBarItems.subNav} />
    </>
  );
};
export default React.memo(NavBar);
