import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ILogRequest,
  useGetAppDeviceLogs,
} from 'src/Utils/Hooks/AppDeviceLogs/useGetAppDeviceLogs';
import DeviceSearch, {
  UrlParam_DeviceType,
  UrlParam_SerialNumber,
} from '../DeviceSearch/DeviceSearch';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import Loader from '../Loader';
import tableStyles from 'src/CssModules/table.module.css';
import SearchBar from '../SearchBar/SearchBar';
import DateRangePicker, {
  getStartDate,
  UrlParam_FromDate,
  UrlParam_ToDate,
} from '../DateRangePicker/DateRangePicker';
import { useSearchParams } from 'react-router-dom';
import { Required_Serial_Number } from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearch';
import { selectDevice } from 'src/Redux/genericSlice';
import { useAppSelector } from 'src/Redux/hooks';
import DropDown, { IOptions } from '../DropDown/DropDown';
import { FormatBytes } from 'src/Utils/FormatBytes';
import styles from 'src/CssModules/downloadFile.module.css';
import InfoBar, { InfoMessageType } from '../InfoBar/InfoBar';
import IconWithTooltip, {
  TooltipPosition,
} from '../IconWithTooltip/IconWithTooltip';
import UploadLogFile from '../UploadLogFile/UploadLogFile';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import PaginationButtons from '../PaginationButtons/PaginationButtons';

const defaultLogType: string = 'Device Logs';
const LogFileBrowser: React.FC = () => {
  // Trigger device search request on search click
  type DeviceSearchHandle = React.ElementRef<typeof DeviceSearch>;
  let logTypeOptions = React.useMemo(() => Config.GetConfigData().LogTypes, []);
  const ref = React.useRef<DeviceSearchHandle>(null);
  // Url params get and set
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadLogs, setUploadLogs] = useState(false);
  let urlParms = {
    fileName: searchParams.get('FileName'),
    logType: searchParams.get('LogType'),
    fromDate: searchParams.get(UrlParam_FromDate),
    toDate: searchParams.get(UrlParam_ToDate),
    sn: searchParams.get(UrlParam_SerialNumber),
    deviceType: searchParams.get(UrlParam_DeviceType),
  };
  const toDate = useMemo(
    () => (urlParms.toDate !== null ? new Date(urlParms.toDate) : new Date()),
    [urlParms.toDate]
  );
  let fromDate = useMemo(() => getStartDate(toDate), [toDate]);
  fromDate =
    urlParms.fromDate !== null ? new Date(urlParms.fromDate) : fromDate;

  const from = useRef(fromDate.toUTCString());
  const to = useRef(toDate.toUTCString());

  const fileName = useRef<string>(
    urlParms.fileName === null ? '' : urlParms.fileName
  );

  const logType = useRef(
    urlParms.logType !== null ? urlParms.logType : logTypeOptions.find((x: IOptions) => x.displayData === defaultLogType).codeData
  );
  const [deviceSearchError, setDeviceSearchError] = useState<string>('');
  const [deviceSearhLoading, setDeviceSearchLoaing] = useState<boolean>(false);

  const serialNumber = useRef(urlParms.sn ?? '');
  const deviceInfo = useAppSelector(selectDevice);

  const deviceType = useRef(
    urlParms.deviceType !== null
      ? urlParms.deviceType
      : deviceInfo.data.DeviceType
  );

  const [logRequest, setLogRequest] = useState<ILogRequest>({
    FromDateTimeUtc: from.current,
    LogType: Number(logType.current),
    ToDateTimeUtc: to.current,
    FileName: fileName.current,
  });

  // Device logs request
  const { logDetails, logError, paginationLoading, getPageDetails, getPreviousPageDetails } = useGetAppDeviceLogs(logRequest);

  // Log report request

  // Re generate logs report
  const [infoBar, setInfoBar] = useState<boolean>(false);

  const setUrlParams = useCallback(() => {
    let urlParams = {
      FromDate: from.current.toString(),
      ToDate: to.current.toString(),
      LogType: logType.current,
      DeviceType: deviceType.current,
      SerialNumber: serialNumber.current,
      FileName: fileName.current,
    };
    setSearchParams(urlParams);
  }, [setSearchParams]);

  const handleSubmit = useCallback(() => {
    ref.current?.UpdateDeviceSearch();
    let formRequest: ILogRequest = {
      FromDateTimeUtc: from.current,
      ToDateTimeUtc: to.current,
      LogType: Number(logType.current),
      FileName: fileName.current,
    };
    let areEqual =
      Object.entries(formRequest).toString() ===
      Object.entries(logRequest).toString();
    setUrlParams();
    if (!areEqual) {
      setLogRequest(formRequest);
    }
  }, [from, to, setUrlParams, logRequest]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  useEffect(() => {
    setUrlParams();
  }, [setUrlParams]);

  const onChangeStartDate = useCallback((date: Date) => {
    from.current = date.toUTCString();
  }, []);

  const onChangeEndDate = useCallback((date: Date) => {
    to.current = date.toUTCString();
  }, []);


  const uploadLogFile = () => {
    setUploadLogs(true);
  }
  const msg = useRef('')
  const msgType = useRef<InfoMessageType>(InfoMessageType.success)
  const status = (message: string, type: InfoMessageType) => {
    msgType.current = type;
    msg.current = message;
    setInfoBar(true);
  }
  return (
    <>
      <LayoutWrapper>
        {
          infoBar &&
          <div className="row">
            <InfoBar Message={msg.current} MessageType={msgType.current} closeClick={() => { setInfoBar(false) }} />
          </div>
        }
        {
          uploadLogs &&
          <UploadLogFile onClose={() => setUploadLogs(false)} onStatus={
            (message, messageType) => status(message, messageType)
          } />
        }
        <div className="row">
          <div className="card" style={{ position: 'inherit', backgroundColor: 'black', border: '1px solid white' }}>
            <div
              className="card-header"
              style={{
                color: 'white',
                backgroundColor: 'black',
                fontSize: '1.14vw',
                paddingLeft: '40px',
                borderBottomColor: 'white',
              }}
              data-testid="logfilebrowser-header"
            >

              <div className="row col-md-12 " >
                Log File Browser
                <div className="col-sm-1  ms-auto" >
                  <span style={{ marginLeft: '5vw', fontSize: '1.4vw' }}
                    data-testid='upload-logs'
                    onClick={() => uploadLogFile()}
                  >
                    <IconWithTooltip
                      tooltipText='Upload Log File'
                      icon='bi bi-plus'
                      tooltipPosition={TooltipPosition.Bottom}
                    >
                    </IconWithTooltip>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="card-body card-body-padding mt-4 pt-1"
              data-testid="logfile-content"
              style={{ backgroundColor: 'black', paddingLeft: '55px' }}
            >
              { 
                <>
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <DeviceSearch
                        onDeviceTypeChange={(val) => {
                          deviceType.current = val;
                        }}
                        onSerialNumberChange={(val) => {
                          serialNumber.current = val;
                        }}
                        ref={ref}
                        setErrorMessage={setDeviceSearchError}
                        setLoading={setDeviceSearchLoaing}
                      />
                      <DateRangePicker
                        onStartDateChange={onChangeStartDate}
                        onEndDateChage={onChangeEndDate}
                      />
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <DropDown
                          id="logType"
                          selectedItem={logType.current}
                          items={logTypeOptions}
                          onSelectionChange={(e) => {
                            logType.current = e.codeData;
                          }}
                        />
                      </div>
                      <div className="col-sm-3">
                        <SearchBar
                          testId='fileName'
                          placeholder="File Name (optional)"
                          value={fileName.current}
                          onTextChange={(e) => {
                            fileName.current = e;
                          }}
                        />
                      </div>

                      <div className="col-sm-3">
                        <button className="btn-prim" onClick={handleSubmit}>
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    {deviceSearhLoading && <Loader />}
                    {paginationLoading && <Loader />}

                    {deviceSearchError &&
                      deviceSearchError !== Required_Serial_Number ? (
                      <ErrorMessage message={deviceSearchError} />
                    ) : logError ? (
                      <ErrorMessage message={logError} />
                    ) : logDetails.data.length === 0 ? (
                      <ErrorMessage message="No logs found for given search criteria" />
                    ) : (
                      <>
                       <div style={{paddingLeft:'30px'}}>
                        <PaginationButtons 
                              hideNext={logDetails.hideNextButton} 
                              hidePrevious={logDetails.hidePrevButton} 
                              getNextPageDetails = {getPageDetails}
                              getPreviousPageDetails = {getPreviousPageDetails}
                        />
                      </div>
                      <div className={`${tableStyles.fixTableHead} col-md-12`} style={{ height: '60vh' }}>
                        <table data-testid="table">
                          <thead className={`${tableStyles.tableHead}`}>
                            <tr key={'header'}>
                              <th className={tableStyles.heading}>Serial Number</th>
                              <th className={tableStyles.heading}>File Name</th>
                              <th className={tableStyles.heading}>Size</th>
                              <th className={tableStyles.heading}>
                                Created On (UTC)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {logDetails.data.map((item) => (
                              <tr key={item.fileName}>
                                <td className={tableStyles.data}>
                                  {item.serialNumber}
                                </td>
                                <td className={tableStyles.data}>
                                  <a
                                    className={styles.downloadFile}
                                    href={item.downLoadLink}
                                  >
                                    {item.fileName}
                                  </a>
                                </td>
                                <td className={tableStyles.data}>
                                  {FormatBytes(item.size)}
                                </td>
                                <td className={tableStyles.data}>{item.createdOn}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </>
                    )}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </LayoutWrapper>
    </>
  );
};
export default LogFileBrowser;
