import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.css';

export interface IDateDetails {
  SelectedDate: Date;
  setDate: (val: Date) => void;
  Label?: string;
  MaxDate: Date;
  Disabled?:boolean;
  testId?: string
}
const Datepicker: React.FC<IDateDetails> = ({
  SelectedDate,
  setDate = () => {},
  Label,
  MaxDate,
  Disabled = false,
  testId
}) => {
  let labelClass = `date ${
    SelectedDate.getTime() > MaxDate.getTime() ? 'highLight' : ''
  }`;
  labelClass = Label
    ? `${labelClass} center_align`
    : `${labelClass} no_padding_left`;
  return (
    <div
      className="datepicker_container"
      style={{
        position: 'inherit',
      }}
    >
      {Label && <span className="datepicker__title"> {Label} </span>}
        <DatePicker
        id={testId}
        disabled = {Disabled}
        className={labelClass}
        selected={SelectedDate}
        onChange={(date: Date) => {
          setDate(date);
        }}
        maxDate={MaxDate}
        dateFormat="yyyy-MM-dd"
      />
    </div>
  );
};

export default Datepicker;
