import axios from 'axios';
import { useCallback, useRef, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { IReleaseEntry } from './useFirmwareRelease';

export const useFileUpload = () => {
  const cancelSource = useRef<any>(null);
  const getScope = useCallback(
    () => Config.GetConfigData().firmwareReleaseService?.scopes[0],
    []
  );
  const getUrl = useCallback(
    () =>
      Config.GetConfigData().firmwareReleaseService?.serviceUrl + 'releases',
    []
  );

  const [progressPercent, setProgressPercent] = useState<number>(0);

  const cancelFileUpload = useCallback(() => {
    if(cancelSource.current)
    {
      cancelSource.current.cancel('Upload aborted.')
    }
  }, [])
  const uploadToAws = useCallback(
    (
      url: string,
      contentType: string,
      file?: File,
      html?: string
    ): Promise<any> => {
      var promise = new Promise((resolve, reject) => {
        cancelSource.current = axios.CancelToken.source();
        const config = {
          cancelToken: cancelSource.current.token,
          onUploadProgress: (progressEvent: any) => {
            let percentUpload = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercent(percentUpload)
          },
          headers: {
            'Content-Type': contentType,
          }
        }
        let cont = file !== undefined ? file : html;
        axios.put(url, cont, config).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            logger.logError(
              `Failed to upload the file ${JSON.stringify(file?.name)}/ html ${JSON.stringify(html)}. error: ${JSON.stringify(error)}`
            );
            if (error.response !== undefined) {
              reject(error.response.data);
            } 
            else if(error.message)
            {
              reject(error.message)
            }else {
              reject(JSON.stringify(error));
            }
          }
        );
      });
      return promise;
    },
    [cancelSource]
  );

  const fileUpload = useCallback(
    (
      url: string,
      request: any,
      releaseType: string,
      version: string,
      file?: File,
      html?: string
    ): Promise<any> => {
      var promise = new Promise<any>((resolve, reject) => {
        var scope = getScope();

        AuthConfig.getToken(scope).then((token) => {
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          };
          logger.logInformation(
            `Access token is  Received successfully  for scope :  ${scope} + 'and upload OTA file`
          );
          axios
            .post(url, request, {
              params: {
                type: releaseType,
              },
              headers,
            })
            .then(
              (response) => {
                uploadToAws(
                  response.data.upload_url,
                  response.data.mime_type,
                  file,
                  html
                ).then(
                  (success) => {
                    resolve(success.data);
                  },
                  (uploadError) => {
                    reject(uploadError);
                  }
                );
              },
              (error) => {
                logger.logError(
                  `Failed to upload the OTA file for given request ${JSON.stringify(
                    request
                  )}. error: ${JSON.stringify(error)}`
                );
                if (error.response !== undefined) {
                  reject(error.response.data);
                } else {
                  reject(JSON.stringify(error));
                }
              }
            );
        });
      });
      return promise;
    },
    [uploadToAws, getScope]
  );

  const deleteFile = useCallback((releaseType: string, version: string, attachmentUrl: string): Promise<IReleaseEntry[]> => {

    var promise = new Promise<IReleaseEntry[]>((resolve, reject) => {
      var scope = getScope();
      var request = {
        version: version,
        attachment_url: attachmentUrl
      };
      AuthConfig.getToken(scope).then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        logger.logInformation(
          `Access token is  Received successfully  for scope :  ${scope} + 'and to delete release attachments`
        );
        axios
          .delete<IReleaseEntry[]>(`${getUrl()}/attachments`, {
            params: {
              type: releaseType,
            },
            data: request,
            headers,
          })
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              logger.logError(
                `Failed to delete the release attachment for given request ${JSON.stringify(
                  request
                )}. error: ${JSON.stringify(error)}`
              );
              if (error.response !== undefined) {
                reject(error.response.data);
              } else {
                reject(JSON.stringify(error));
              }
            }
          );
      });
    })
    return promise;
  }, [getScope, getUrl])
  return { progressPercent, cancelFileUpload, fileUpload, deleteFile };
};
