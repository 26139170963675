import { useState } from "react";
import "./ExpandCollapse.css";
import IconWithTooltip, {TooltipPosition } from "src/Components/IconWithTooltip/IconWithTooltip";
interface IExpandCollapse {
  titles: { expand: string; collapse: string };
  children?: React.ReactNode;
  log?: string;
}
const ExpandCollapse: React.FC<IExpandCollapse> = ({
  titles,
  children,
  log,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const tooltipText = {
    copy: "Copy to clipboard",
    copied: "Copied!",
  };

  const [tooltip, setTooltip] = useState(tooltipText.copy);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    setTooltip(tooltipText.copy);
  };

  const handleMouseLeaveIcon = () => {
    setTooltip(tooltipText.copy);
  };

  const handleMouseEnterIcon = () => {
    setTooltip(tooltipText.copy);
  };

  const copyToClipboard = () => {
    if (children) {
      navigator.clipboard.writeText(log!);
      setTooltip(tooltipText.copied);
    }
    return;
  };

  const cl = isExpanded
    ? "fa fa-chevron-right rotate down"
    : "fa fa-chevron-right rotate";
  return (
    <>
      <div data-testid="iconWithHeader">
        <div
          onClick={handleExpand}
          style={{ color: "white", cursor: "pointer" }}
          data-testid="header"
        >
          <i
            style={{ color: "white", marginLeft: "40px", marginRight: "20px" }}
            className={cl}
            data-testid="icon"
          ></i>
          {
            <u
              className="row"
              style={{
                color: "white",
                fontSize: "small",
                fontStyle: "italic",
                fontFamily: "auto",
                cursor: "pointer",
                display: "inline-flex",
              }}
            >
              {!isExpanded ? titles.expand : titles.collapse}
            </u>
          }
        </div>

        <div className="row">
          <div className="col-md-11">
          {isExpanded && (
          <div style={{ marginLeft: "30px" }} data-testid="content">
            {children}
          </div>
        )}
          </div>
          <div className="col-md-1" data-testid="copy-to-clipboard-id">
            {children && isExpanded && (
              <IconWithTooltip
                tooltipText={tooltip}
                icon="fa fa-regular fa-copy"
                iconColor="antiquewhite"
                tooltipPosition={TooltipPosition.Top}
                click={copyToClipboard}
                cursor="pointer"
                mouseLeave={handleMouseLeaveIcon}
                mouseEnter={handleMouseEnterIcon}
              ></IconWithTooltip>
            )}
          </div>
        </div>

        
      </div>
    </>
  );
};
export default ExpandCollapse;
