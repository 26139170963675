import React, { useEffect, useRef, useState } from 'react';
import Step, { IStepDetails } from './Step';

export interface IStepNavigation {
  stepsInfo: IStepDetails[];
  activeStep: number;
  isNext: boolean;
  enableBackOnLastStep: boolean;
  setCurrentStep: (step: number) => void;
}
const StepNavigation: React.FC<IStepNavigation> = ({
  stepsInfo,
  activeStep,
  isNext,
  enableBackOnLastStep,
  setCurrentStep,
  children,
}) => {
  const [currentStep, updateCurrentStep] = useState<number>(activeStep ?activeStep: 1);
  const [showBack, setShowBack] = useState<boolean>(false);
  const showNext = useRef<boolean>(true);
  const confirmAndUpdateStep = (stp: number) => {
    var stepInfo = stepsInfo.filter((obj) => {
      return obj.stepNumber === stp;
    })[0];
    if (
      stepInfo.stepConfirmMessage === undefined ||
      stepInfo.stepConfirmMessage === ''
    ) {
      updateCurrentStep(stp);
    } else {
      const confirmBox = window.confirm(stepInfo.stepConfirmMessage);
      if (confirmBox === true) {
        updateCurrentStep(stp);
      } else {
        return;
      }
    }
  };
  useEffect(() => {
    setCurrentStep(currentStep);
  }, [currentStep, setCurrentStep]);
  useEffect(() => {
    if (currentStep === stepsInfo[stepsInfo.length - 1].stepNumber) {
      showNext.current = false;
    } else {
      showNext.current = true;
    }
    if (currentStep === stepsInfo[0].stepNumber) {
    
     setShowBack(false)
    } else {
      if (
        currentStep === stepsInfo[stepsInfo.length - 1].stepNumber &&
        !enableBackOnLastStep
      ) {
        setShowBack(false)
      } else {
        setShowBack(true)
      }
    }
  }, [currentStep, enableBackOnLastStep, stepsInfo, showBack]);
  return (
    <>
      <div className="stepWrapper">
        {stepsInfo.map((item, index) => (
          <Step
            key={index}
            stepDetails={item}
            selected={currentStep === item.stepNumber}
            updateStep={updateCurrentStep}
          />
        ))}
      </div>
      {children}
      <div className="row" style={{ justifyContent: 'end' }}>
        {showBack && (
          <button
            style={{ width: '60px', backgroundColor: '#4792C8' }}
            className="btn btn-primary"
            onClick={() => confirmAndUpdateStep(currentStep - 1)}
            data-testid="back"
          >
            Back
          </button>
        )}

        {showNext.current && (
          <button
            style={{
              width: '60px',
              marginLeft: '10px',
              marginRight: '80px',
              backgroundColor: '#4792C8',
            }}
            className="btn btn-primary"
            disabled={!isNext}
            onClick={() => confirmAndUpdateStep(currentStep + 1)}
            data-testid="next"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};
export default StepNavigation;
