import {
  IReleaseEntryCompanion,
  useFirmwareRelease,
} from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import tableStyles from 'src/CssModules/table.module.css';
import { epochToDate } from 'src/Utils/DateHelper';
import { IReleaseEntry } from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import IconWithTooltip, {
  TooltipPosition,
} from 'src/Components/IconWithTooltip/IconWithTooltip';
import InfoBar, { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import AddCompanionSoftware from './AddCompanionSoftware';
import { useGetValidateRoles, UserRoles } from 'src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles';
export interface IModelCompanionParams {
  releaseEntry: IReleaseEntry;
  deviceType: string;
  updateEditReleaseLoader: (load: boolean) => void,
  updateList: (list: IReleaseEntry[]) => void;
}
const CompnionSoftware: React.FC<IModelCompanionParams> = ({
  releaseEntry,
  deviceType,
  updateEditReleaseLoader,
  updateList
}) => {
  const {
    addOrUpdateFirmwareReleases,
    firmwareReleases,
    firmwareReleaseError,
    firmwareReleaseLoading,
  } = useFirmwareRelease();
  const [companionList, setCompanionList] = useState<
    IReleaseEntryCompanion[] | undefined
  >(releaseEntry.companions);
  const [toggleComapnionInfoBar, setToggleComapnionInfoBar] =
    useState<boolean>(false);
  const [isAddCompanion, setIsAddCompanion] = useState(false);
  const companionMsgType = useRef<InfoMessageType>(InfoMessageType.none);
  const companionMsg = useRef('');
  var { validateUser } = useGetValidateRoles();
  var isEditorUser = useMemo(() => validateUser([UserRoles.FirmwareReleaseEditor]),[validateUser]);
  const convertEpochToDate = (timeStamp: number) => {
    let date = epochToDate(timeStamp);
    let day = ('0' + date.getDate()).slice(-2);
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let formatted = date.getFullYear() + '-' + month + '-' + day;
    return formatted;
  };
  const setInfo = useCallback((msg: string, msgType: InfoMessageType) => {
    companionMsg.current = msg;
    companionMsgType.current = msgType;
    setToggleComapnionInfoBar(true);
  }, []);
  useEffect(() => {
    if (firmwareReleases.length > 0) {
      var updatedReleaseComapnions = firmwareReleases.filter(
        (x) => x.version === releaseEntry.version
      )[0].companions;
      if (
        releaseEntry.companions?.length === updatedReleaseComapnions?.length
      ) {
        setCompanionList(updatedReleaseComapnions);
        setInfo('Companion deleted successfully', InfoMessageType.success);
        updateList(firmwareReleases);
      }
    }
  }, [
    firmwareReleases,
    setCompanionList,
    setInfo,
    releaseEntry.companions,
    releaseEntry.version,
    updateList
  ]);

  useEffect(() => {
    if (firmwareReleaseError !== '') {
      setInfo(firmwareReleaseError, InfoMessageType.error);
    }
  }, [firmwareReleaseError, setInfo]);
  useEffect(() => {
    updateEditReleaseLoader(firmwareReleaseLoading)
  }, [firmwareReleaseLoading, updateEditReleaseLoader]);
  const deleteCompanion = useCallback(
    (companionEntry: IReleaseEntryCompanion) => {
      const confirmBox = window.confirm(
        `Please verify companion software to be removed`
      );
      if (confirmBox) {
        if (companionList !== undefined) {
          companionList.splice(
            companionList.findIndex(
              (a) =>
                a.name === companionEntry.name &&
                a.version === companionEntry.version &&
                a.release_date === companionEntry.release_date
            ),
            1
          );
        }
        releaseEntry.companions = companionList;
        addOrUpdateFirmwareReleases(releaseEntry, deviceType);
      }
    },
    [addOrUpdateFirmwareReleases, releaseEntry, deviceType, companionList]
  );
  return (
    <>
      {toggleComapnionInfoBar && (
        <div className="row" style={{ width: '50%' }}>
          <InfoBar
            Message={companionMsg.current}
            MessageType={companionMsgType.current}
            closeClick={() => {
              setToggleComapnionInfoBar(false);
            }}
          />
        </div>
      )}

      {isAddCompanion && (
        <AddCompanionSoftware
          releaseEntry={releaseEntry}
          deviceType={deviceType}
          setCompanions={(companionList) => {
            if (companionList !== undefined && companionList.length > 0) {
              setCompanionList(companionList);
              setInfo('Companion added successfully', InfoMessageType.success);
            }
          }}
          onClose={() => {
            setIsAddCompanion(false);
          }}
          setCompanionError={(error: string) => {
            if (error !== '') {
              setInfo(error, InfoMessageType.error);
            }
          }}
          updateEditReleaseLoader={updateEditReleaseLoader}
        />
      )}
      <div className="col-sm-6">
        <button
          disabled={!isEditorUser}
          data-testid="add-companion"
          className="btn-prim"
          style={{ float: 'right' }}
          onClick={() => {
            setIsAddCompanion(true);
          }}
        >
          Add
        </button>
      </div>
      <div
        className={`${tableStyles.fixTableHead} col-md-12`}
        style={{ height: '55.5vh', marginLeft: '10px' }}
      >
        <table style={{ width: '50%' }}>
          <thead className={`${tableStyles.tableHead}`}>
            <tr key={'header'}>
              <th className={tableStyles.heading}>Companion Software</th>
              <th className={tableStyles.heading}>Minimum Version</th>
              <th className={tableStyles.heading}>Release Date</th>
              <th className={tableStyles.heading}>Delete</th>
            </tr>
          </thead>
          {
            <tbody className="mb-2">
              {companionList !== undefined &&
                companionList.map((item, index) => (
                  <tr key={index}>
                    <td className={tableStyles.data}>{item.name}</td>
                    <td className={tableStyles.data}>{item.version}</td>
                    <td className={tableStyles.data}>
                      {convertEpochToDate(item.release_date)}
                    </td>
                    <td className={tableStyles.data}>
                      <span
                        style={{ pointerEvents: !isEditorUser? 'none' : 'auto'}}        
                        data-testid={`remove-companion-${item.version}`}
                        className="px-2"
                        onClick={() => deleteCompanion(item)}
                      >
                        <IconWithTooltip
                          tooltipText="Remove"
                          icon="bi bi-trash"
                          tooltipPosition={TooltipPosition.Right}
                          iconColor={!isEditorUser? 'grey' : 'white'}
                        ></IconWithTooltip>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};
export default CompnionSoftware;
