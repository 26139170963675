import React, { useEffect, useState } from 'react';
import { useDebounce } from 'src/Utils/Hooks/Debounce';
import { ISizeOptions } from 'src/Utils/Interfaces/ISizeOptions';

interface ISearchData extends ISizeOptions {
  // Value to be displayed on text box
  value?: string;
  // Placeholder to be displayed on text box
  placeholder: string;
  // Debounce time in Milliseconds
  debounceTimeInMilliseconds?: number;
  // Callback method on change
  onTextChange: (text: string) => void;
  isRequired?: boolean;
  minVal?: number;
  testId?: string
}
const SearchBar: React.FC<ISearchData> = ({
  value,
  placeholder,
  debounceTimeInMilliseconds,
  onTextChange,
  isRequired,
  minVal,
  testId = "txtId"
}) => {
  // State and setters for input value value
  let initialVal = value ? value : '';
  isRequired = isRequired ? isRequired : false;
  let debounceTime = debounceTimeInMilliseconds
    ? debounceTimeInMilliseconds
    : 0;
  const [text, setText] = useState<string>(initialVal);
  // Debounce value
  const debouncedSearchTerm = useDebounce(text, debounceTime);
  const [invalidText, setInvalidText] = useState<boolean>(true);
  useEffect(() => {
    onTextChange(debouncedSearchTerm);
    if (isRequired && debouncedSearchTerm.length === minVal) {
      setInvalidText(false);
    }
  }, [debouncedSearchTerm, onTextChange, isRequired, invalidText, minVal]);
  const isNumInValid = () => {
    if (isRequired && minVal) {
      if (text.length < minVal) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <div data-testid="classesId">
      <input
        className="form-control"
        type="text"
        data-testid={testId}
        placeholder={placeholder}
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
        style={
          isRequired && isNumInValid()
            ? { border: 'red', borderStyle: 'double' }
            : {}
        }
      />
    </div>
  );
};
export default React.memo(SearchBar);
