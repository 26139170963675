import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'src/CssModules/modal.module.css';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { useUploadLogFile, IUploadLogFile } from 'src/Utils/Hooks/AppDeviceLogs/useUploadLogFile';
import DropDown, { IOptions } from '../DropDown/DropDown';
import FileForm from '../FormFileControl/FileForm';
import InfoBar, { InfoMessageType } from '../InfoBar/InfoBar';
import Loader from '../Loader';
import { getDeviceTypes } from '../SupportUserDeviceTYpes';

export interface IModelParams {
    onClose: () => void;
    onStatus: (message: string, messageType: InfoMessageType) => void;
}

const defaultDeviceType: string = 'BLK2GO';
const defaultLogType: string = 'Device Logs';
const UploadLogFile: React.FC<IModelParams> = ({
    onClose,
    onStatus,
}) => {
    const [display, setdisplay] = useState('block');
    let deviceTypeOptions = React.useMemo(() => getDeviceTypes(), []);
    let logTypeOptions = React.useMemo(() => Config.GetConfigData().LogTypes, []);
    
    const deviceType = useRef(defaultDeviceType)
    const logType = useRef(logTypeOptions.find((x: IOptions) => x.displayData === defaultLogType).codeData);
    const logFile = useRef<File | undefined>();
    const [enableSubmit, setEnableSubmit] = useState(false);
    let req: IUploadLogFile = {
        logFile: logFile.current,
        deviceType: deviceType.current,
        logType: logType.current
    }
    const [uploadLogRequest, setUploadLogRequest] = useState<IUploadLogFile>(req);
    const { uploadLogResponse, uploadLogError, uploadLogLoading } = useUploadLogFile(uploadLogRequest);
    const [infoBar, setInfoBar] = useState<boolean>(false);
    const [msgType, setMsgType] = useState<InfoMessageType>(InfoMessageType.none);
    const [msg, setMsg] = useState<string>('');
    const onSubmit = () => {
        let request: IUploadLogFile = {
            deviceType: deviceType.current,
            logType: logType.current,
            logFile: logFile.current
        }
        setUploadLogRequest(request);
    };

    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);

    const verifySubmitEnable = useCallback(() => {
        setInfoBar(false);
        let fileExtension = logFile.current?.name.split('.').pop();
        if (!(fileExtension === 'gz' || fileExtension === 'enc')) {
            setMsg('Please do upload files with only .gz/.enc extension');
            setMsgType(InfoMessageType.error);
            setInfoBar(true);
            setEnableSubmit(false);
        }
        else{
            setEnableSubmit(true);
        }
    }, []);

    useEffect(() => {
        if (uploadLogResponse) {
            close();
            onStatus(
                `Log file added successfully`,
                InfoMessageType.success
            );
        } else if (uploadLogError !== '') {
            close();
            onStatus(uploadLogError, InfoMessageType.error);
        }
    }, [uploadLogError, uploadLogResponse, close, onStatus]);

    return (
        <>
            {uploadLogLoading && <Loader />}
            {
                <div id="myModal" className={Modal.modal} style={{ display: display }}>
                    <div className={Modal.content}>
                        <div className={Modal.header}>
                            <h4 className='text-center'>Upload Log File</h4>
                        </div>
                        <hr style={{ color: 'white' }} />
                        <div className={Modal.body}>
                            <div style={{ marginLeft: '41px', paddingRight: '30px' }}>
                                <div className="form-group row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label"
                                        style={{ color: 'white', paddingLeft: '85px' }}
                                    >
                                        Device Type
                                    </label>
                                    <div className="col-md-7">
                                        <DropDown
                                            selectedItem={deviceType.current}
                                            items={deviceTypeOptions}
                                            onSelectionChange={(e) => {
                                                deviceType.current = e.codeData;
                                            }}
                                            id="deviceType-id"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label"
                                        style={{ color: 'white', paddingLeft: '85px' }}
                                    >
                                        Log Type
                                    </label>
                                    <div className="col-md-7">
                                        <DropDown
                                            selectedItem={logType.current}
                                            items={logTypeOptions}
                                            onSelectionChange={(e) => {
                                                logType.current = e.codeData;
                                            }}
                                            id="logType-id"
                                        />
                                    </div>
                                </div>

                                <FileForm
                                    lableText="Log File"
                                    acceptType=".gz,.enc"
                                    testid="log-file"
                                    disable={false}
                                    paddingLeft="85px"
                                    onFileSelected={(files: FileList) => {
                                        logFile.current = files[0];
                                        verifySubmitEnable();
                                    }}
                                />
                                <div style={{marginRight: '110px', marginLeft: '72px'}}>
                                    {infoBar && <InfoBar
                                        Message={msg}
                                        MessageType={msgType}
                                        closeClick={() => { setInfoBar(false) }}
                                    />}
                                </div>
                            </div>
                        </div>
                        <hr style={{ color: 'white' }} />
                        <div className={Modal.footer}>
                            <div className="row" style = {{ paddingLeft: '30px', paddingRight: '30px'}}>
                                <div className="col-sm-4" style={{ paddingLeft: '90px' }}>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => close()}
                                        data-testid="close"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className="col-sm-3  ms-auto">
                                    <button
                                        className="btn btn-primary"
                                        disabled={!enableSubmit}
                                        onClick={() => onSubmit()}
                                        data-testid="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
export default UploadLogFile;
