import React, { useState } from 'react';
import { ISizeOptions } from 'src/Utils/Interfaces/ISizeOptions';
export interface IOptions {
  displayData: string;
  codeData: string;
  
}
interface ISelectControlData extends ISizeOptions {
  // Selected Item which will be shown by default
  selectedItem: string;

  // List of Options to be shown on drop down
  items: IOptions[];

  // Callback method on change
  onSelectionChange: (event: IOptions) => void;
  id?: string;
  disabled?:boolean
}
const DropDown: React.FC<ISelectControlData> = ({
  selectedItem,
  items,
  onSelectionChange,
  id='selectId',
  disabled
}) => {
  const [selectedTxt, setSelectedTxt] = useState<string>(selectedItem);
  const onChangeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    let value = items.filter((x) => x.codeData === event.target.value)[0];
    setSelectedTxt(event.target.value);
    onSelectionChange(value);
  };
 
  const dropDownItems = items.map((item) => (
    <option value={item.codeData} key={item.codeData}>
      {item.displayData}
    </option>
  ));

  return (
    <div data-testid="classesId">
      <select
        disabled={disabled}
        className="form-select"
        onChange={onChangeSelection}
        data-testid={id}
        value={selectedTxt}
      >
        {' '}
        {dropDownItems}
      </select>
    </div>
  );
};
export default React.memo(DropDown);
