import { useEffect} from 'react';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import { IReleaseEntry, useFirmwareRelease } from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import tableStyles from 'src/CssModules/table.module.css';
import IconWithTooltip, { TooltipPosition } from 'src/Components/IconWithTooltip/IconWithTooltip';
import { FormatBytes } from 'src/Utils/FormatBytes';
interface IReleaseNotesAttachments {
    releaseEntry: IReleaseEntry,
    releaseType: string
    updateList: (list: IReleaseEntry[]) => void,
    updateEditReleaseLoader: (load: boolean) => void,
    showStatusBar: (msg: string, msgType: InfoMessageType) => void,
    deleteReleaseNotes: (releaseType:string, version:string, attachemetUrl:string) => void,
    isEditor: boolean;
}

const ReleaseNotesAttachments: React.FC<IReleaseNotesAttachments> = ({ 
  releaseEntry, 
  releaseType,
  updateList, 
  updateEditReleaseLoader, 
  showStatusBar, 
  deleteReleaseNotes, 
  isEditor}) => {

    const {
        firmwareReleases,
        firmwareReleaseError,
        firmwareReleaseLoading,
        addOrUpdateFirmwareReleases
      } = useFirmwareRelease();


    useEffect(() => {
        if (firmwareReleases.length > 0) {
            updateList(firmwareReleases);
            showStatusBar("Updated release notes successfully.", InfoMessageType.success)
        }
    }, [firmwareReleases, updateList, showStatusBar])

    useEffect(() => {
        if (firmwareReleaseError !== '') {
            showStatusBar(firmwareReleaseError, InfoMessageType.error)
        }
    }, [firmwareReleaseError, showStatusBar])

    useEffect(() => {
        updateEditReleaseLoader(firmwareReleaseLoading)
    }, [firmwareReleaseLoading, updateEditReleaseLoader]);

    const getFileName = (url:string) :string=> {
        return url.substring(url.lastIndexOf('/')+1);
    }

    const onActive = (url:string) => {
        releaseEntry.changelog_url = url;
        addOrUpdateFirmwareReleases(releaseEntry, releaseType)
    }
    return (
        <>
                    <div className='row ' data-testid='attachment_window'>
                        <table className="col-md-9">
                          <thead className={`${tableStyles.tableHead}`}>
                            <tr key={'header'}>
                              <th className={tableStyles.heading}>Attachment</th>
                              <th className={tableStyles.heading}>Size</th>
                              <th className={tableStyles.heading}>Actions</th>
                            </tr>
                          </thead>
                          <tbody className="mb-2" data-testid="releases">
                          
                            {releaseEntry.attachments !== undefined &&
                                releaseEntry.attachments.length > 0 && (
                                    releaseEntry.attachments.map((item, index) => (
                                        <tr key={index}>
                                            <td className={tableStyles.data} style={{width:"40vw", textAlign:'left', paddingLeft:'30px'}} >
                                              {getFileName(item.url)}
                                              {item.url === releaseEntry.changelog_url && 
                                               <button 
                                                   className="btn-prim" 
                                                   data-testid="Active" 
                                                   style={{background:'green', marginLeft:'20px', fontSize:'inherit' }} >
                                                      Active
                                               </button>
                                              }
                                            </td>
                                            <td className={tableStyles.data}>
                                              {FormatBytes(item.size)}
                                            </td>
                                            <td className={tableStyles.data}>
                                                <div className='row'>
                                               <div  style={{paddingLeft:'33px', width:'55%'}}>
                                                {item.url !== releaseEntry.changelog_url &&
                                                    <button className="btn-prim"  disabled={!isEditor} onClick={() => onActive(item.url)} data-testid="active" style={{fontSize:'inherit'}}>
                                                            Make Active
                                                    </button>
                                                }
                                                </div>
                                                <div  style={{marginTop:'10px', width:'33%'}}>                                                  
                                                      <span
                                                        style={{ pointerEvents: !isEditor? 'none' : 'auto'}}   
                                                        data-testid={`Remove_btn_${item.size}`}
                                                        className="px-2"
                                                          onClick={() => deleteReleaseNotes(releaseType, releaseEntry.version, item.url)}
                                                        >
                                                            <IconWithTooltip
                                                                tooltipText="Remove"
                                                                icon="bi bi-trash"
                                                                tooltipPosition={TooltipPosition.Right}
                                                                iconColor={!isEditor? 'grey' : 'white'}
                                                            ></IconWithTooltip>
                                                    </span>
                                                 </div>   
                                               
                                               </div>
                                            </td>
                                        </tr>
                                        
                                    ))
                                )}
                                </tbody>
                        </table>
                        

                    </div>
                  
                    
              
        </>
    )
}
export default ReleaseNotesAttachments;