import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DropDown, { IOptions } from '../DropDown/DropDown';
import './DeviceSearch.css';
import SearchBar from '../SearchBar/SearchBar';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import { DeviceState, selectDevice, UpdateState } from 'src/Redux/genericSlice';
import {
  DeviceStateRequest,
  useDeviceSearch,
} from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearch';
import DivStyles from 'src/CssModules/div.module.css';
import { useSearchParams } from 'react-router-dom';
import { getDeviceTypes } from '../SupportUserDeviceTYpes';
export const UrlParam_SerialNumber = 'SerialNumber';
export const UrlParam_DeviceType = 'DeviceType';
export interface DeviceSearchRequest {
  SerialNumber: string;
  DeviceType: string;
}
type DeviceSearchHandle = {
  UpdateDeviceSearch: () => void;
};

interface IDeviceSearch {
  // Callback method on change
  onDeviceTypeChange: (deviceType: string) => void;
  onSerialNumberChange: (serialNo: string) => void;
  showSerialNumber?: boolean;
  setErrorMessage: (message: string) => void;
  setLoading: (loading: boolean) => void;
  serialNumber?: string;
  deviceType?: string;
  isRequired?: boolean;
}

const DeviceSearch: React.ForwardRefRenderFunction<
  DeviceSearchHandle,
  IDeviceSearch
> = (props, forwardedRef) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  let urlParamSn = useMemo(() => {
    return searchParams.get(UrlParam_SerialNumber);
  }, [searchParams]);
  let urlParamDeviceType = useMemo(() => {
    return searchParams.get(UrlParam_DeviceType);
  }, [searchParams]);

  // Bydefault serial no box is shown
  let showSearchBox =
    props.showSerialNumber !== undefined ? props.showSerialNumber : true;
  const deviceInfo = useAppSelector(selectDevice);

  let options: IOptions[] | undefined = React.useMemo(
    () => getDeviceTypes(),
    []
  );
  const serailNo = React.useRef<string>(
    urlParamSn ? urlParamSn : deviceInfo.data.SerialNumber
  );
  let selectedDeviceType = deviceInfo.data.DeviceType;
  const deviceType = React.useRef<string>(
    urlParamDeviceType ? urlParamDeviceType : selectedDeviceType
  );
  const [req, setReq] = useState<DeviceStateRequest>({
    deviceType: deviceType.current,
    searchTerm: serailNo.current,
  });

  useEffect(() => {
    if (
      deviceInfo.data.DeviceType !== deviceType.current ||
      deviceInfo.data.SerialNumber !== serailNo.current
    ) {
      let device: DeviceState = {
        SerialNumber: serailNo.current,
        ProvisioningId: '',
        DeviceType: deviceType.current,
      };
      dispatch(UpdateState(device));
    }
  }, [
    searchParams,
    dispatch,
    deviceInfo.data.DeviceType,
    deviceInfo.data.SerialNumber,
  ]);

  let { deviceSearchError, deviceSearchLoading } = useDeviceSearch(req);
  const localRef = useRef<DeviceStateRequest>(req);
  React.useImperativeHandle(forwardedRef, () => ({
    UpdateDeviceSearch() {
      if (
        deviceType.current !== localRef.current.deviceType ||
        serailNo.current !== localRef.current.searchTerm
      ) {
        let details = {
          deviceType: deviceType.current,
          searchTerm: serailNo.current,
        };
        let device: DeviceState = {
          SerialNumber: serailNo.current,
          ProvisioningId: '',
          DeviceType: deviceType.current,
        };
        dispatch(UpdateState(device));
        localRef.current = details;
        setReq(details);
      }
    },
  }));

  useEffect(() => {
    props.setErrorMessage(deviceSearchError);
    props.setLoading(deviceSearchLoading);
  }, [deviceSearchError, deviceSearchLoading, props]);

  const onDeviceTypeSelectionChanged = useCallback(
    (val: IOptions) => {
      deviceType.current = val.displayData;
      props.onDeviceTypeChange(deviceType.current);
    },
    [props]
  );

  const onSearchTextChanged = useCallback(
    (val: string) => {
      serailNo.current = val;
      props.onSerialNumberChange(val);
    },
    [props]
  );
  return (
    <>
      <div className={`${DivStyles.width} mb-3 `}>
        <DropDown
          id="deviceType"
          selectedItem={deviceType.current}
          items={options}
          onSelectionChange={onDeviceTypeSelectionChanged}
        />
      </div>
      {showSearchBox && (
        <div className={`${DivStyles.width} mb-3 `} data-testid="serialno-id">
          <SearchBar
            testId='serialNo'
            placeholder="Enter Serial no"
            value={serailNo.current}
            onTextChange={onSearchTextChanged}
            isRequired={props.isRequired}
            minVal={7}
          />
        </div>
      )}
    </>
  );
};
function deviceSearchPropsAreEqual(
  prevSearch: IDeviceSearch,
  nextSearch: IDeviceSearch
) {
  return (
    prevSearch.serialNumber === nextSearch.serialNumber &&
    prevSearch.deviceType === nextSearch.deviceType &&
    prevSearch.showSerialNumber === nextSearch.showSerialNumber
  );
}
export default React.memo(
  React.forwardRef(DeviceSearch),
  deviceSearchPropsAreEqual
);
