import IconWithTooltip, { TooltipPosition } from '../IconWithTooltip/IconWithTooltip';
import './PaginationButtons.css'
export interface IPaginationButtonsOptions {
    hidePrevious?: boolean;
    hideNext?: boolean;
    getNextPageDetails: () => void;
    getPreviousPageDetails: () => void;
}
const PaginationButtons:React.FC<IPaginationButtonsOptions> = ({hidePrevious, hideNext, getNextPageDetails, getPreviousPageDetails}) => {
    return(
        <div className="prevNextButtons">
              <button className='btn btn-prim mx-1 prevBorder' disabled={hidePrevious} onClick={getPreviousPageDetails}>
                <IconWithTooltip
                  tooltipText="Previous"
                  icon="bi bi-chevron-left"
                  tooltipPosition={TooltipPosition.Left}
                ></IconWithTooltip>
              </button>
              <button className='btn btn-prim nextBorder' disabled={hideNext} onClick={getNextPageDetails}>
                <IconWithTooltip
                  tooltipText="Next"
                  icon="bi bi-chevron-right"
                  tooltipPosition={TooltipPosition.Right}
                ></IconWithTooltip>
              </button>
            </div>   
    )
}
export default PaginationButtons;