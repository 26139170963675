
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import OuterLayout from "../LayoutWrapper/LayoutWrapper";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
const ContactAdmin = () => {
    const [msg, setMessage] = useState('Please contact admin for access...');
    const location = useLocation();
    useEffect(() => {
        let page = '';
        let path :any = location.state;
        if(path !== window.location.pathname) {
            if(path !== null) {
            if(path.indexOf('-') >= 0) {
                let result = path.replaceAll('-', " ");
                page = result.charAt(1).toUpperCase() + result.slice(2);
            }
            let message = "Please contact admin for " + page +  " page access...";
            setMessage(message);
          }
        }
    }, [location])
    return(
        <OuterLayout>
            <div className="d-flex justify-content-center" style={{fontSize:'50px'}}>
               <ErrorMessage message={msg}></ErrorMessage>
            </div>
        </OuterLayout>
    )
}
export default React.memo(ContactAdmin);