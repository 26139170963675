// import { IConfigServiceData } from "./IConfigService";
export class Config {
  private static configData: any;
  public static GetConfigData = () => {
    if (!Config.configData) {
      var json = Config.loadTextFileAjaxSync('config.json', "application/json");
      // Parse json
      let data = JSON.parse(json);
      Config.configData = data;
    }
    return Config.configData;
  }

  private static loadTextFileAjaxSync = (filePath: string, mimeType: string): string => {
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", filePath, false);
    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    if (xmlhttp.status === 200 && xmlhttp.readyState === 4) {
      return xmlhttp.responseText;
    }
    else {
      throw new Error('Error in loading data');
    }
  }
}
