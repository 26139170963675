import { useEffect, useMemo,  useState } from 'react';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { IPagingOption } from '../Pagination/useGetPagination';
import {usePaginationWrapper} from '../Pagination/usePaginationWrapper';

export interface IDisplayDeviceDetails {
  serialNumber?: string;
  provisioningId?: string;
  provisioningStatus?: string;
  isCertificateAvailable?: boolean;
  certificateCreatedTime?: string;
  enrollmentGroup?: string;
  createdBy?: string;
}

export interface IDeviceDetails extends IDisplayDeviceDetails {
  deviceType?: string;
  isSelected?: boolean;
  enrolmentGroupCommonName?: string;
  onLineStatus?: string;
  deviceLastActiveTime?: Date;
  ioTHubAssigned?: string;
}
export interface ISerialNumberRange {
    fromSerialNumber:string,
    toSerialNumber:string
}

export interface IDeviceSearchRequest {
    searchDetails: IDeviceDetails,
    serailNoRange?: ISerialNumberRange
}

export enum DeviceAttributes {
  SerialNumber = 'Serial Number',
  EnrollmentGroup = 'Enrollment Group',
  ProvisioningId = 'Provisioning Id',
  ProvisioningStatus = 'Provisioning Status',
  CertificateAvailable= 'Certificate Available',
  CertificateCreatedTime = 'Certificate Created Time',
  CreatedBy= 'Created By'
}

export const useDeviceSearchDetails = (searchRequest: IDeviceSearchRequest ) => {
   let pageOptions:IPagingOption = {
    chunkSize : 500,
    itemsPerPage : 20
  }
  const scope = useMemo(() => Config.GetConfigData().provisioningService?.scopes[0],[]);
   let baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);

  baseUrl = `${baseUrl}provisioning/enrolment/devices`;
  const searchQuery: string = JSON.stringify(searchRequest.searchDetails);
  const [refSearch, setSearch] = useState(searchQuery);
  let serialNumberRange;
  if(searchRequest.serailNoRange) {
    serialNumberRange = {serialNumberRange: JSON.stringify(searchRequest.serailNoRange)}
  }
  
  const {paginationDetails, paginationError, paginationLoading, getPageDetails, getPreviousPageDetails, reset} = usePaginationWrapper<IDeviceDetails>(pageOptions, baseUrl, scope, refSearch, serialNumberRange);
  
  useEffect(() => {
    setSearch(JSON.stringify(searchRequest.searchDetails))
  }, [searchRequest])
  return {
    paginationDetails,
    paginationError,
    paginationLoading,
    getPageDetails,
    getPreviousPageDetails,
    reset,
    refSearch
  };
};
