import React, {Fragment, useEffect, useState} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {logger} from './LoggerService';

const TelemetryProvider:React.FC<{ children: React.ReactNode}> = ({children}) => {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (!Boolean(initialized) ) {
            logger.initialize();
            setInitialized(true)
        }
    },[initialized])
    return (
        <div>
            {initialized && 
              <Fragment>
                  {children}
              </Fragment>
            }
        </div>
    )

}

export default withAITracking(logger.reactPlugin, TelemetryProvider);