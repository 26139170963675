import {ApplicationInsights, SeverityLevel} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { Config } from '../ConfigService/LoadConfig';

let reactPlugin :ReactPlugin ;
let appInsights :ApplicationInsights;
let minLogLevel: number ;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (): void => {
        const settings = Config.GetConfigData().ApplicationInsights;
        const browserHistory = createBrowserHistory();
        minLogLevel = settings.minLevel;
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!settings.key) {
            throw new Error('Instrumentation key not provided')
        }
       
        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: settings.key,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
    };

    const logTrace = (message: string, logLevel:number) => {
        // There is no way set minimum log level.
        // https://github.com/microsoft/ApplicationInsights-JS/issues/1259
        if (minLogLevel <= logLevel ) {
              appInsights.trackTrace({ message: message, severityLevel: logLevel});
        }
      }
    const logException = (exception: string) => {
        let error = new Error(exception)
        appInsights.trackException({ exception: error});
      }
    const logEvent = (message: string) =>  {
        appInsights.trackEvent({name: message});
      }
    const logInformation = (message: string) => {
        logTrace(message, SeverityLevel.Information);
      }
    
    const logWarning = (message: string) => {
        logTrace(message, SeverityLevel.Warning);
      }
    const logError = (message: string) => {
        logTrace(message, SeverityLevel.Error);
      }
    const logDebug = (message: string) => {
        logTrace(message, SeverityLevel.Verbose);
      }
    const logCritical = (message: string) => {
        logTrace(message, SeverityLevel.Critical);
      }

    return {reactPlugin, 
        appInsights, 
        initialize, 
        logInformation, 
        logWarning, 
        logError, 
        logDebug, 
        logCritical,
        logException,
        logEvent
    };
};

export const logger = createTelemetryService();
