import DivStyles from 'src/CssModules/div.module.css';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IReleaseEntry, uploadTypes, useFirmwareRelease } from "src/Utils/Hooks/FirmwareRelease/useFirmwareRelease";
import { dateToEpoch, epochToDate } from 'src/Utils/DateHelper';
import Datepicker from 'src/Components/Datepicker/Datepicker';
import DropDown from 'src/Components/DropDown/DropDown';
import InfoBar, {InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import { useGetValidateRoles, UserRoles } from 'src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles';

interface IEditReleaseVersion {
    releaseEntry: IReleaseEntry;
    deviceType: string
    updateList: (list: IReleaseEntry[]) => void;
    deleteEntry: (item: IReleaseEntry) => void;
    updateEditReleaseLoader: (load: boolean) => void;
}

const EditReleaseVersions: React.FC<IEditReleaseVersion> = ({ releaseEntry, deviceType, updateList, deleteEntry,  updateEditReleaseLoader }) => {
    const [date, setDate] = useState<Date>(epochToDate(releaseEntry.date));
    const uploadType = useRef(releaseEntry.type);
    const [showInfoBar, setShowInfoBar] = useState(false);
    var { validateUser } = useGetValidateRoles();

    const [msg, setMsg] = useState('')
    const [msgType, setMsgType] = useState(InfoMessageType.none)
      
    var isEditorUser = useMemo(() => validateUser([UserRoles.FirmwareReleaseEditor]),[validateUser]);
    const { addOrUpdateFirmwareReleases, firmwareReleaseLoading, firmwareReleaseError, firmwareReleases } = useFirmwareRelease();
    const updateReleaseDate = useCallback(
        (date: Date) => {
            setDate(date);
        }, []
    );

    const showInfoStatus = (message:string, type:InfoMessageType) => {
        setShowInfoBar(true)
        setMsg(message);
        setMsgType(type)
    }
    const onSave = () => {
        setShowInfoBar(false)
        const timestamp = dateToEpoch(date);
        releaseEntry.date = timestamp;
        releaseEntry.type = uploadType.current;
        addOrUpdateFirmwareReleases(releaseEntry, deviceType);
    }

    useEffect(() => {
        if (firmwareReleases.length > 0) {
            showInfoStatus(`Release ${releaseEntry.version} Updated successfully`, InfoMessageType.success)
            let currentReleaseEntry = firmwareReleases.find(x => x.version === releaseEntry.version);
            if (currentReleaseEntry) {
                updateList(firmwareReleases);
            }
        }
    }, [firmwareReleases, updateList, releaseEntry.version])

    const onDelete = () => {
        deleteEntry(releaseEntry)
    }

    const multichargerAllowedReleaseTypes = ['Release', 'Staging', 'Snapshot'];

    const fetchReleaseTypes = () => {
        if(deviceType !== 'BLK2FLY_CHARGER') {
          return uploadTypes;
        } else {
          return uploadTypes.filter(r => multichargerAllowedReleaseTypes.includes(r.codeData));
        }
      }

    useEffect(() => {
        updateEditReleaseLoader(firmwareReleaseLoading)
    }, [firmwareReleaseLoading, updateEditReleaseLoader])

    useEffect(() => {
        setDate(epochToDate(releaseEntry.date))
    },[releaseEntry])

    useEffect(() => {
        if(firmwareReleaseError && firmwareReleaseError !== '') {
            showInfoStatus(firmwareReleaseError, InfoMessageType.error)
        }
    },[firmwareReleaseError])

    return (
        <>
            <div style={{ marginLeft: '40px' }}>
                {showInfoBar && 
                  <div className='col-md-11'>
                    <InfoBar
                        Message={msg}
                        MessageType={msgType}
                        closeClick={() => { setShowInfoBar(false) }}
                    />
                  </div>
                }
                
                <h3 style={{ color: 'white' }}>{deviceType} Version</h3>
                <div className="row pt-3">
                    <div className={`${DivStyles.width} mb-3 `}>
                        <input
                            data-testid="version"
                            className="form-control"
                            type="text"
                            value={releaseEntry.version}
                            disabled={true}
                        />
                    </div>
                    <div className={`${DivStyles.width} col-sm-3`}>
                        <Datepicker
                            Disabled = {!isEditorUser}
                            SelectedDate={date}
                            setDate={updateReleaseDate}
                            Label="From"
                            MaxDate={new Date()}
                        />
                    </div>
                    <div className="col-sm-3">
                        <DropDown 
                            disabled={!isEditorUser}
                            id="uploadTypes"
                            selectedItem={releaseEntry.type ? releaseEntry.type : ''}
                            items={fetchReleaseTypes()}
                            onSelectionChange={(e) => {
                                uploadType.current = e.codeData
                                if(releaseEntry.type !== e.codeData) {
                                  showInfoStatus(`Release type is changed to ${e.codeData}`, InfoMessageType.warning)
                                } else {
                                  setShowInfoBar(false)
                                }
                            }}
                        />
                    </div>
                </div>
             
                <div className="row">
                    <div className="row col-sm-3">
                        <div className="col-sm">
                            <button className="btn-prim" onClick={onDelete} disabled={!isEditorUser}>
                                Delete
                            </button>
                        </div>
                        <div className="col-sm">
                            <button className="btn-prim" onClick={onSave} data-testid="save" disabled={!isEditorUser}>
                                Save
                            </button>
                        </div>
                    </div>

                </div>
               
            </div>
        </>
    )
}
export default EditReleaseVersions;
