import { useCallback } from "react";
import { useOpenWindowForReplayTool } from "./useOpenWindowForReplayTool";
import { useHandleError } from "./useHandleErrorForReplayTool";
import { useGetReplayDataArrayBuffer } from "./useGetReplayDataArrayBuffer";

export const useRenderReplayTool = (
  replayLog: string,
  setLoading: (flag: boolean) => void,
  setErrorMessage: (msg: string | null) => void,
  deviceType?: string | null
) => {
  const { openWindow } = useOpenWindowForReplayTool(setLoading);
  const { handleError } = useHandleError(setLoading, setErrorMessage);
  const { getReplayLogArrayBuffer } = useGetReplayDataArrayBuffer(
    replayLog,
    deviceType
  );

  const renderReplayTool = useCallback(async () => {
    setLoading(true);
    try {
      await getReplayLogArrayBuffer()
        .then((res: any) => {
          if (res && res.data) {
            setErrorMessage(null);
            openWindow(res.data);
          } else {
            handleError(
              "Error downloading replay log : " + replayLog.split("/")[1]
            );
          }
        })
        .catch((err: any) => {
          handleError(err);
        });
    } catch (error) {
      handleError(error);
    }
  }, [setLoading, getReplayLogArrayBuffer, handleError, openWindow, replayLog, setErrorMessage]);

  return { renderReplayTool, getReplayLogArrayBuffer };
};
