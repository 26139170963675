import React from "react";
import "./Switch.css";
interface ISelectControlData {
	disabled: boolean;
	checked: boolean;
	id?: string;
	onToggleChange: (event: boolean) => void;
}
const Switch: React.FC<ISelectControlData> = ({
	disabled,
	checked,
	id,
	onToggleChange,
}) => {
	const onToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
		onToggleChange(event.target.checked);
	};
	return (
		<>
			<label className="switch">
				<input
				    disabled={disabled}
					checked={checked}
					data-testid={id ? id : 'toggleId'}
					type="checkbox"
					onChange={(e) => onToggle(e)}
				/>
				<span className="slider round"></span>
			</label>
		</>
	);
};
export default React.memo(Switch);
