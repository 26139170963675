import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import { UrlParam_DeviceType } from '../DeviceSearch/DeviceSearch';
import DropDown, { IOptions } from '../DropDown/DropDown';
import { DeviceState, selectDevice, UpdateState } from 'src/Redux/genericSlice';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import DivStyles from 'src/CssModules/div.module.css';
import { IQueryTwinDetailsRequest, useGetQueryTwinDetails } from 'src/Utils/Hooks/GetQueryTwinDetails/useGetQueryTwinDetails';
import { useSearchParams } from 'react-router-dom';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import tableStyles from 'src/CssModules/table.module.css';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { getDeviceTypes } from '../SupportUserDeviceTYpes';

const QueryTwin: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const deviceInfo = useAppSelector(selectDevice);
    const dispatch = useAppDispatch();
    let urlParms = {
        propertyName: searchParams.get('PropertyName'),
        deviceType: searchParams.get(UrlParam_DeviceType),
    };
    let deviceTypeOptions = useMemo(() => getDeviceTypes(),[]);
    let deviceTwinPropertyOptions: IOptions[] =  useMemo(() => Config.GetConfigData().deviceTwinProperties,[]);
    const deviceType = useRef(urlParms.deviceType !== null ? urlParms.deviceType : deviceInfo.data.DeviceType);
    const defaultPropertyName = useRef(urlParms.propertyName !== null ? urlParms.propertyName : deviceTwinPropertyOptions[0].codeData);
    let property = deviceTwinPropertyOptions.filter((obj: { codeData: string }) => obj.codeData === defaultPropertyName.current)[0];
    const [propertyNameDisplayed, setPropertyName] = useState(property.displayData)
    const setUrlParams = useCallback(() => {
        let urlParams = {
            DeviceType: deviceType.current,
            PropertyName: defaultPropertyName.current
        };
        setSearchParams(urlParams);
    }, [setSearchParams]);
    const [queryTwinRequest, setQueryTwinRequest] = useState<IQueryTwinDetailsRequest>({
        PropertyName: defaultPropertyName.current,
        DeviceType: deviceType.current
    });
    const { queryTwinDetails, queryTwinError, queryTwinLoading } = useGetQueryTwinDetails(queryTwinRequest);
    const handleSubmit = useCallback(() => {
        let formRequest: IQueryTwinDetailsRequest = {
            DeviceType: deviceType.current,
            PropertyName: defaultPropertyName.current
        };
        let property = deviceTwinPropertyOptions.filter((obj: { codeData: string }) => obj.codeData === formRequest.PropertyName)[0];
        setPropertyName(property.displayData);
        let areEqual =
            Object.entries(formRequest).toString() ===
            Object.entries(queryTwinRequest).toString();
        if (!areEqual || deviceInfo.data.DeviceType !== deviceType.current) {
            setUrlParams();
            let device: DeviceState = {
                SerialNumber: deviceInfo.data.SerialNumber,
                ProvisioningId: '',
                DeviceType: deviceType.current
            }
            dispatch(UpdateState(device));

            setQueryTwinRequest(formRequest);
        }
    }, [setUrlParams, queryTwinRequest, deviceInfo, defaultPropertyName, deviceTwinPropertyOptions, dispatch]);
    const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSubmit();
    }, [handleSubmit])

    useEffect(() => {
        setUrlParams();
    }, [setUrlParams])
    return (
        <>
            <LayoutWrapper>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className={`${DivStyles.width} mb-3`}>
                            <DropDown
                                selectedItem={deviceType.current}
                                items={deviceTypeOptions}
                                onSelectionChange={(e) => {
                                    deviceType.current = e.codeData;
                                }}
                            />
                        </div>
                        <div className={`${DivStyles.width} mb-3`}>
                            <DropDown
                                selectedItem={defaultPropertyName.current}
                                items={deviceTwinPropertyOptions}
                                onSelectionChange={(e) => {
                                    defaultPropertyName.current = e.codeData
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <button className="btn-prim" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    {queryTwinLoading && <Loader />}
                    {queryTwinError && <ErrorMessage message={queryTwinError} />}
                    {queryTwinDetails && queryTwinDetails.length === 0 ? (
                        <ErrorMessage message="No records found for given query" />
                    ) : (
                        <div className={`${tableStyles.fixTableHead} col-md-12`}>
                            <table style={{ width: '100%' }}>
                                <thead className={`${tableStyles.tableHead}`}>
                                    <tr key={'header'}>
                                        <th className={tableStyles.heading}>{propertyNameDisplayed}</th>
                                        <th className={tableStyles.heading}>Number Of Devices</th>
                                    </tr>
                                </thead>
                                {<tbody>
                                    {queryTwinDetails.map((item, index) => (
                                        <tr key={index}>
                                            <td className={tableStyles.data}>{item.property}</td>
                                            <td className={tableStyles.data}>{item.noOfDevices}</td>
                                        </tr>
                                    ))}
                                </tbody>}
                            </table>
                        </div>
                    )}
                </div>
            </LayoutWrapper>
        </>
    );
};
export default QueryTwin