import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ILogRequest,
  LogType,
  useGetAppDeviceLogs,
} from 'src/Utils/Hooks/AppDeviceLogs/useGetAppDeviceLogs';
import DivStyles from 'src/CssModules/div.module.css';
import { UrlParam_DeviceType } from '../DeviceSearch/DeviceSearch';
import { DeviceState, selectDevice, UpdateState } from 'src/Redux/genericSlice';
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks';
import DropDown from '../DropDown/DropDown';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import Loader from '../Loader';
import tableStyles from 'src/CssModules/table.module.css';
import DateRangePicker, {
  getStartDate,
  UrlParam_FromDate,
  UrlParam_ToDate,
} from '../DateRangePicker/DateRangePicker';
import { useSearchParams } from 'react-router-dom';
import PaginationButtons from '../PaginationButtons/PaginationButtons';
import { getDeviceTypes } from '../SupportUserDeviceTYpes';

const UserFeedback: React.FC = () => {
  // Url params get and set
  const [searchParams, setSearchParams] = useSearchParams();
  let urlParms = {
    fromDate: searchParams.get(UrlParam_FromDate),
    toDate: searchParams.get(UrlParam_ToDate),
    fileName: searchParams.get('FileName'),
    deviceType: searchParams.get(UrlParam_DeviceType),
  };
  const toDate =
  useMemo(() =>urlParms.toDate !== null ? new Date(urlParms.toDate) : new Date(),[urlParms.toDate]);
  let fromDate = useMemo(() => getStartDate(toDate), [toDate]);
  fromDate =
    urlParms.fromDate !== null ? new Date(urlParms.fromDate) : fromDate;

  const from = useRef(fromDate.toUTCString());
  const to = useRef(toDate.toUTCString());
  const filename =
    urlParms.fileName !== null ? `${urlParms.fileName}` : '';
  const [logRequest, setLogRequest] = useState<ILogRequest>({
    FromDateTimeUtc: from.current,
    LogType: LogType.feedback,
    ToDateTimeUtc: to.current,
    FileName: filename,
  });

  let deviceTypeOptions = getDeviceTypes();
  const deviceInfo = useAppSelector(selectDevice);
  const deviceType = useRef(
    urlParms.deviceType !== null
      ? urlParms.deviceType
      : deviceInfo.data.DeviceType
  );
  const dispatch = useAppDispatch();

  const { supportLogDetails, logError, paginationLoading, getPageDetails, getPreviousPageDetails } =
    useGetAppDeviceLogs(logRequest);
  const setUrlParams = useCallback(() => {
    let urlParams = {
      DeviceType: deviceType.current,
      FromDate: from.current.toString(),
      ToDate: to.current.toString(),
    };
    setSearchParams(urlParams);
  }, [setSearchParams]);
  const handleSubmit = useCallback(() => {
    let formRequest: ILogRequest = {
      FromDateTimeUtc: from.current,
      ToDateTimeUtc: to.current,
      LogType: LogType.feedback,
    };
    let areEqual =
      Object.entries(formRequest).toString() ===
      Object.entries(logRequest).toString();

    if (!areEqual || deviceInfo.data.DeviceType !== deviceType.current) {
      setUrlParams();
      let device: DeviceState = {
        SerialNumber: deviceInfo.data.SerialNumber,
        ProvisioningId: '',
        DeviceType: deviceType.current,
      };
      dispatch(UpdateState(device));

      setLogRequest(formRequest);
    }
  }, [from, to, setUrlParams, logRequest, deviceInfo, dispatch]);
  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );
  useEffect(() => {
    if (deviceInfo.data.DeviceType !== deviceType.current) {
      let device: DeviceState = {
        SerialNumber: deviceInfo.data.SerialNumber,
        ProvisioningId: '',
        DeviceType: deviceType.current,
      };
      dispatch(UpdateState(device));
    }
  }, [dispatch, deviceInfo.data.DeviceType, deviceInfo.data.SerialNumber]);

  useEffect(() => {
    setUrlParams();
  }, [setUrlParams]);
  
  const onChangeStartDate = useCallback((date: Date) => {
    from.current = date.toUTCString();
  }, []);
  const onChangeEndDate = useCallback((date: Date) => {
    to.current = date.toUTCString();
  }, []);

  return (
    <>
      <LayoutWrapper>
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className={`${DivStyles.width} mb-3`}>
              <DropDown
                selectedItem={deviceType.current}
                items={deviceTypeOptions}
                onSelectionChange={(e) => {
                  deviceType.current = e.codeData;
                }}
                id="deviceType-id"
              />
            </div>

            <DateRangePicker
              onStartDateChange={onChangeStartDate}
              onEndDateChage={onChangeEndDate}
            />
            <div className="col-sm-3">
              <button className="btn-prim" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </form>
        <div className="row mt-3">
          {paginationLoading && <Loader />}
          {logError? <ErrorMessage message={logError} />: supportLogDetails.data.length === 0 ? (
            <ErrorMessage message="No logs found for given search criteria" />
          ) : (
            <>
             <div style={{paddingLeft:'130px'}}>
              <PaginationButtons 
                              hideNext={supportLogDetails.hideNextButton} 
                              hidePrevious={supportLogDetails.hidePrevButton} 
                              getNextPageDetails = {getPageDetails}
                              getPreviousPageDetails = {getPreviousPageDetails}
                        />
              </div>
            <div className={`${tableStyles.fixTableHead} col-md-12`}>
              <table style={{ width: '100%' }}>
                <thead className={`${tableStyles.tableHead}`}>
                  <tr key={'header'}>
                    <th className={tableStyles.data}>Serial Number</th>
                    <th className={tableStyles.heading}>Created On (UTC)</th>
                    <th className={tableStyles.heading}>Description </th>
                    <th className={tableStyles.heading}>App Version</th>
                    <th className={tableStyles.heading}>Device Version</th>
                  </tr>
                </thead>
                <tbody>
                  {supportLogDetails.data.map((item, index) => (
                    <tr key={index}>
                      <td className={tableStyles.data}>{item.serialNumber}</td>
                      <td className={tableStyles.data}>{item.createdOn}</td>
                      <td className={tableStyles.data}>{item.description}</td>
                      <td className={tableStyles.data}>{item.appVersion}</td>
                      <td className={tableStyles.data}>{item.deviceVersion}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </>
          )}
        </div>
      </LayoutWrapper>
    </>
  );
};
export default UserFeedback;
