import React, { useRef, useEffect, useState } from 'react';
import {
  IDeviceDetails,
  IDeviceSearchRequest,
  ISerialNumberRange,
  useDeviceSearchDetails,
} from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearchDetails';
import {
  IDeviceEnrolmentRequest,
  useDevice,
} from 'src/Utils/Hooks/DeviceEnrollment/useDevice';
import { InfoMessageType } from '../InfoBar/InfoBar';
export interface IStatusParams {
  enrollmentRequest: IDeviceEnrolmentRequest;
  statusLoading: (loading: boolean) => void;
}
const Status: React.FC<IStatusParams> = ({
  enrollmentRequest,
  statusLoading,
}) => {
  const serialnumberRange: ISerialNumberRange = {
    fromSerialNumber: enrollmentRequest.SerialNoFrom,
    toSerialNumber: enrollmentRequest.SerialNoTo,
  };
  let searchRequest: IDeviceSearchRequest = {
    searchDetails: {
      serialNumber: '',
      provisioningId: '',
      provisioningStatus: '',
      isCertificateAvailable: true,
      enrollmentGroup: enrollmentRequest.EnrollmentGroupName,
    },
    serailNoRange: serialnumberRange,
  };
  const { paginationDetails, getPageDetails } =
    useDeviceSearchDetails(searchRequest);
  const devicesCreated = useRef<number>(0);
  const totaDevices =
    +enrollmentRequest.SerialNoTo - +enrollmentRequest.SerialNoFrom + 1;
  const downloadDeviceCertificates = (request: IDeviceEnrolmentRequest) => {
    const deviceList: IDeviceDetails[] = [];
    for (var i = +request.SerialNoFrom; i <= +request.SerialNoTo; i++) {
      var deviceEntity: IDeviceDetails = {
        enrolmentGroupCommonName: request.EnrollmentGroupName,
        serialNumber: i.toString(),
      };
      deviceList.push(deviceEntity);
    }
    downloadDevice(deviceList);
  };
  useEffect(() => {
    let timerID = setInterval(() => {
      if (!(devicesCreated.current === totaDevices)) {
        getPageDetails();
      } else {
        clearInterval(timerID);
      }
    }, 5 * 1000);
    return () => clearInterval(timerID);
  }, [getPageDetails, totaDevices]);

  useEffect(() => {
    if (paginationDetails !== undefined && paginationDetails.data.length > 0) {
      devicesCreated.current = paginationDetails.data.length;
    }
  }, [paginationDetails, devicesCreated]);
  const [msgType, setMsgType] = useState<InfoMessageType>(InfoMessageType.none);
  const {
    enrollmentMessage,
    enrollmentStatus,
    enrollmentLoading,
    addDevice,
    downloadDevice,
  } = useDevice();
  useEffect(() => {
    statusLoading(enrollmentLoading);
  }, [enrollmentLoading, statusLoading]);
  useEffect(() => {
    switch (enrollmentStatus) {
      case 'Error':
        setMsgType(InfoMessageType.error);
        break;
      case 'Success':
        setMsgType(InfoMessageType.success);
        break;
      case 'Warning':
        setMsgType(InfoMessageType.warning);
        break;
      default:
      // code block
    }
  }, [enrollmentStatus]);
  useEffect(() => {
    addDevice(enrollmentRequest);
  }, [enrollmentRequest, addDevice]);

  return (
    <>
      <div className="row" style={{ color: 'white', paddingLeft: '10px' }}>
        {!(devicesCreated.current === totaDevices) ? (
          <p>
            Device Enrollment is in progress. <br />
          </p>
        ) : (
          <p>Certificate Generation is complete. Ready for download</p>
        )}
        <div>
          {devicesCreated.current} of {totaDevices} devices created{' '}
          {!(devicesCreated.current === totaDevices) && (
            <div
              className="spinner-grow"
              role="status"
              style={{ width: '1rem', height: '1rem' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
          <br />
        </div>
        <p>
          Note: The certificate generation process will continue even if the
          dialog is closed. Certificates can also be downloaded from device
          explorer page.
        </p>
        <div
          className={`col-sm-10 alert alert-${msgType}`}
          data-testid="infoBarTest"
          style={{ marginLeft: '10px', padding: '.5rem .5rem' }}
        >
          <span>{enrollmentMessage}</span>
        </div>
        {msgType !== InfoMessageType.error && (
          <div className="col-sm-3">
            <button
              className="btn btn-success"
              disabled={!(devicesCreated.current === totaDevices)}
              onClick={() => downloadDeviceCertificates(enrollmentRequest)}
              data-testid="download"
            >
              Download
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Status;
