import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { UuidValidation } from 'src/Utils/Validateuuid/ValidateUUID';
import { logger } from 'src/Utils/LoggerService/LoggerService';
export interface IData {
  size: number | null;
  name: string;
  type: string;
  id: string;
  downLoadLink: string;
  parentId: string;
  children: string[];
}
export interface IProcessingLogs {
  parentId: string;
  logs: IData[];
}
export enum Stage {
  PROD,
  UAT,
}
export interface IFilePath {
  name: string;
  parentId: string;
}

export interface IPath {
  name: string;
  id: string;
}

let defaultData: IProcessingLogs = {
  parentId: 'logs/',
  logs: [],
};
export enum AssetQueryBy {
  EntityUUID,
  AssetUUID,
}
export interface IProcessinglogsRequest {
  parentType: string;
  stage: Stage;
  queryBy: AssetQueryBy;
  AssetId: string;
}
export const useGetProcessingLogs = (request: IProcessinglogsRequest) => {
  const [logDetails, setLogDetails] = useState<IProcessingLogs>(defaultData);
  const [logError, setLogError] = useState<string>('');
  const [logLoading, setlogLoading] = useState<boolean>(true);

  useEffect(() => {
    setlogLoading(true);
    const scope = Config.GetConfigData().hxdrIntegrationService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;

    let environmentSources = {
      uat: 'https://uat-hxdr.com',
      prod: 'https://hxdr.app'
    }

    let assetIdentifier: string = request.AssetId.toLowerCase();
    let isByHxDRURL: boolean = assetIdentifier.indexOf(`${environmentSources.prod}/assets/`) === 0 || assetIdentifier.indexOf(`${environmentSources.uat}/assets/`) === 0;
    
    if(isByHxDRURL) {
      assetIdentifier = assetIdentifier.split('/assets/')[1]?.split('?')[0];
    }

    request.AssetId = assetIdentifier;

    if (request.AssetId === undefined || request.AssetId === '') {
      setLogError('Asset Id is required');
      setlogLoading(false);
    } else if (!UuidValidation.ValidateUuid(request.AssetId)) {
      logger.logError('Invalid Asset id is provided : ' + request.AssetId)
      setLogError('Invalid Asset Id or HxDR Asset URL');
      setlogLoading(false);
    } else {
      AuthConfig.getToken(scope).then(
        (success) => {
          const headers = {
            Authorization: `Bearer ${success}`,
            'Content-Type': 'application/json',
          };
          logger.logInformation(`Access token is  Received successfully for scope :  ${scope} + 'and requesting Processing logs`);
          axios
            .get<IProcessingLogs>(`${baseUrl}hxdr/logs-assets`, {
              params: {
                ProcessingLogsType:request.parentType,
                AssetId: request.AssetId,
                Stage: request.stage,
                AssetQueryBy: request.queryBy,
              },
              headers,
            })
            .then(
              (response) => {
                setLogDetails(response.data);
                setLogError('');
              },

              (error) => {
                logger.logError('Failed to get processing logs for given request: ' + JSON.stringify(request) + '. error: '+ JSON.stringify(error))
                setLogError(error.response.data.Message);
                setLogDetails(defaultData);
              }
            )
            .finally(() => {
              setlogLoading(false);
            });
        },
        (error) => {
          setLogError(error);
          setlogLoading(false);
        }
      );
    }
  }, [request]);
  return { logDetails, logError, logLoading };
};