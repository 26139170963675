import { useCallback } from "react";
import { Config } from "../../ConfigService/LoadConfig";
import { AuthConfig } from "../../../AuthConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";
import axios from "axios";

export const useGetReplayDataArrayBuffer = (
  replayLog: string,
  deviceType?: string | null
) => {
  const serviceUrl = Config.GetConfigData().serviceUrl;
  const scope = Config.GetConfigData().deviceOpsService?.scopes[0];

  const getReplayLogArrayBuffer = useCallback(async (): Promise<any> => {
    return AuthConfig.getToken(scope)
      .then((bearerToken) => {
        const headers = {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        };

        const url = serviceUrl + "device/replay";

        let params = {
          fileName: replayLog,
          deviceType: deviceType,
        };

        return axios
          .get(url, { responseType: "arraybuffer", headers, params: params })
          .then(
            (res) => {
              return res;
            },
            (err) => {
              let errorMsg =
                "Failed to fetch replay log for given request: " +
                url +
                ".error" +
                JSON.stringify(err);
              logger.logError(errorMsg);
            }
          );
      })
      .catch((error) => {
        throw new Error("Failed to get access token");
      });
  }, [replayLog, deviceType, scope, serviceUrl]);

  return { getReplayLogArrayBuffer };
};
