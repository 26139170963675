import React from 'react';
export interface IDialogParams {
    contactName:string| undefined;
    contactEmail:string| undefined;
    countryCode:string| undefined;
}
const SupportContactInfoDialog: React.FC<IDialogParams> = ({ contactName, contactEmail, countryCode }) => {

    return (
        <div style={{paddingLeft:'70px'}} >
            <div className="form-group row mb-3">
                <label
                    className="col-sm-12 col-form-label"
                    style={{ color: 'white', paddingLeft: '10px' }}
                    >
                        Contact Name : {contactName}
                </label>
            </div>
            <div className="form-group row mb-3">
                <label
                    className="col-sm-12 col-form-label"
                    style={{ color: 'white', paddingLeft: '10px' }}
                    >
                        Contact Email : {contactEmail}
                </label>
                </div>
                <div className="form-group row mb-3">
                <label
                    className="col-sm-12 col-form-label"
                    style={{ color: 'white', paddingLeft: '10px' }}
                    >
                        Country Code  : {countryCode}
                </label>
                </div>
        </div>
    )
}
export default SupportContactInfoDialog;