// https://melvingeorge.me/blog/check-if-string-valid-uuid-regex-javascript

export class UuidValidation {
    static ValidateUuid = (val:string) : boolean => {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        if(val === undefined || val==='') {
            return false;
        }
        return regexExp.test(val);
    }
  }