import React from 'react';
import Card from '../Card/Card';
import DeviceStatusItem from '../DeviceStatusItem/DeviceStatusItem';
import './DeviceStatusList.css';
export const Enrolled_DeviceTwin_Error =
  'Could not retrieve device twin information for this device';
const DeviceStatusList: React.FC<{ twinDetails: any; twinError: string }> = ({
  twinDetails,
  twinError,
}) => {
  const getLastActiveTime = (): string => {
    let date = new Date(twinDetails.lastActivityTime);
    if (1 === date.getFullYear()) {
      return 'Last Activity Time: Not available';
    } else {
      return 'Last Activity Time: ' + twinDetails.lastActivityTime;
    }
  };
  var divStyle = {
    paddingTop: '20px',
    paddingLeft: '40px',
  };
  return (
    <>
      <Card header="Device Status">
        <div className="col-lg-12 col-sm-12  mt-2" style={divStyle}>
          <DeviceStatusItem
            title="Registration Status"
            data={
              twinError === Enrolled_DeviceTwin_Error
                ? 'Enrolled'
                : 'Registered'
            }
            color={twinError === Enrolled_DeviceTwin_Error ? 'yellow' : 'green'}
            icon="bi bi-laptop"
          ></DeviceStatusItem>
          <div className="mt-3">
            <DeviceStatusItem
              title="Connection Status"
              data={twinDetails.connectionState}
              description={getLastActiveTime()}
              color={
                twinDetails.connectionState === 'Connected' ? 'green' : 'red'
              }
              icon="bi bi-link"
            ></DeviceStatusItem>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-12 px-5">
                    <div className="row">
                        <DeviceStatusItem title="Number of data set" data="51" color="yellow" icon="bi bi-files" xl={12} lg={12} md={12} sm={12} xs={12}></DeviceStatusItem>
                    </div>
                    <div className="row mt-3">
                        <DeviceStatusItem title="Free Space left" data="100MB" color="red" icon="bi bi-sd-card" xl={12} lg={12} md={12} sm={12} xs={12}></DeviceStatusItem>
                    </div>
                    <div className="row mt-3">
                        <DeviceStatusItem title="Current Battery level" data="37" color="orange" icon="bi bi-battery-full" xl={12} lg={12} md={12} sm={12} xs={12}></DeviceStatusItem>
                    </div>
                    <div className="row mt-3">
                        <DeviceStatusItem title="Current Temperature" data="39°C" color="blue" icon="bi bi-thermometer" xl={12} lg={12} md={12} sm={12} xs={12}></DeviceStatusItem>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 px-5">
                    <DeviceStatusItem title="Firmware Version" data="1.1.0v" color="royalblue" icon="bi bi-info-circle-fill" xl={12} lg={12} md={12} sm={12} xs={12}></DeviceStatusItem>
                </div>  */}
      </Card>
    </>
  );
};
export default DeviceStatusList;
