import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
export interface IActiveDeviceCountResponse {
    deviceType:string;
    count:number;
}
export const useGetActiveDeviceCount = ( deviceCountRequestList : string[]) =>{
    const [activeDeviceCountLoading, setActiveDeviceCountLoading] = useState<boolean>(true);
    const [activeDeviceCountError, setActiveDeviceCountError] = useState<string>('');
    const [activeDeviceCount, setActiveDeviceCount] = useState<IActiveDeviceCountResponse[]>([]);
    useEffect(() => {
        setActiveDeviceCountLoading(true);
        const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
        const baseUrl = Config.GetConfigData().serviceUrl;
        if (scope === undefined) {
            setActiveDeviceCountError('Invalid scope');
            setActiveDeviceCountLoading(false);
          }else {
            AuthConfig.getToken(scope).then(
              (success) => {
                const headers = {
                  Authorization: `Bearer ${success}`,
                  'Content-Type': 'application/json',
                };
                logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and requesting active device count`);
             

                axios.get<IActiveDeviceCountResponse[]>(`${baseUrl}device/twin/activeDeviceCount?${deviceCountRequestList.map((n, index) => `deviceType=${n}`).join('&')}`, {
                    headers
                  }).then(
                    (response) => {
                        if(response.data.length > 0){
                          const activeDeviceCountResponse = response.data;
                          setActiveDeviceCount(activeDeviceCountResponse);
                        }
                        else{
                          setActiveDeviceCount([]);
                        }
                        setActiveDeviceCountError('');
                      },
                    (error) => {
                        logger.logError(
                        'Failed to get count of active device for given request: ' +
                        JSON.stringify(deviceCountRequestList) +
                            '.error: ' +
                            JSON.stringify(error)
                          );
                          if (error.response !== undefined) {
                            setActiveDeviceCountError(error.response.data.Message);
                          } else {
                            setActiveDeviceCountError(error.response);
                          }
                          setActiveDeviceCount([]);
                    }
                  )
                  .finally(() => {
                    setActiveDeviceCountLoading(false);
                  });
              },
              (error) => {
                setActiveDeviceCountError(error);
                setActiveDeviceCountLoading(false);
              }
            );
          }
        },[deviceCountRequestList]);
        return {activeDeviceCount, activeDeviceCountError, activeDeviceCountLoading};
}