import { Config } from "src/Utils/ConfigService/LoadConfig";
import { IOptions } from "./DropDown/DropDown";

export const getDeviceTypes = (): IOptions[] => {
    const deviceTypes: IOptions[] = Config.GetConfigData().DeviceTypes;
    let options: IOptions[] = [];
    deviceTypes.forEach(item => {
      options.push(item);
    });
    return options;
  };