import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { selectDevice } from 'src/Redux/genericSlice';
import { useAppSelector } from 'src/Redux/hooks';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export const useGetTableauToken = (
  selectedViewType: string
) => {
  const [tableauToken, setTableauToken] = useState<string>('');
  const [tableauError, setTableauError] = useState<string>('');
  const [tableauLoading, setTableauLoading] = useState<boolean>(false);
  const deviceInfo = useAppSelector(selectDevice);

  const getTokenRenewTime = () => {
    const telemetry = Config.GetConfigData().Telemetry;
    return (+telemetry.TokenRenewTimeInSec * 1000); // converting to Milli seconds
  }
  const [tokenGeneratedTime, setTokenGeneratedTime] = useState<Date>()
  const ExpiryTime = useMemo(() => getTokenRenewTime(), [])

  const veriFyTokenExpiry = useCallback(() => {
    if(tokenGeneratedTime) {
      let duration = new Date().getTime() - tokenGeneratedTime.getTime()
      if(duration < ExpiryTime) {
        return false
      }
    }
    return true;
  },[ExpiryTime, tokenGeneratedTime])
  useEffect(() => {
    setTableauLoading(true);
    const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    const requestUrl = `${baseUrl}device/tableau/token`;
    if (selectedViewType === '') {
      setTableauError('Invalid Dashboard');
      setTableauLoading(false);
    } else if (scope === undefined) {
      setTableauError('Invalid scope');
      setTableauLoading(false);
    } else if(!veriFyTokenExpiry()) {
      setTableauLoading(false);
    } else  {
      AuthConfig.getToken(scope).then(
        (success) => {
          const headers = {
            Authorization: `Bearer ${success}`,
          };
          axios
            .get(requestUrl, { headers })
            .then(
              (response) => {
                setTokenGeneratedTime(new Date())
                setTableauError('');
                setTableauToken(response.data);
              },

              (error) => {
                logger.logError('Failed to retrieve tableau trusted token. error: '+ JSON.stringify(error));
                setTableauError(error.response.data.Message);
                setTableauToken('');
              }
            )
            .finally(() => {
              setTableauLoading(false);
            });
        },
        (error) => {
          setTableauLoading(false);
          setTableauError(error);
        }
      );
    }
  }, [selectedViewType,  deviceInfo.data.SerialNumber, deviceInfo.data.DeviceType, veriFyTokenExpiry]);

  return { tableauToken, tableauError, tableauLoading };
};
