import { useCallback } from "react";

export const useHandleError = (
  setLoading: (flag: boolean) => void,
  setErrorMessage: (msg: string | null) => void
) => {
  const handleError = useCallback(
    (err: any) => {
      setLoading(false);
      let errorMessage =
        "Specified blob does not exist or Internal server error";
      setErrorMessage(errorMessage);
      console.error(err);
    },
    [setLoading, setErrorMessage]
  );

  return { handleError, setErrorMessage, setLoading };
};
