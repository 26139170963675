interface IIconDetails {
    icon: string;
    iconColor: string;
    textColor: string;
    children:React.ReactNode
    test_id?: string;
}
const IconWithText: React.FC<IIconDetails> = ({
    icon,
    iconColor,
    textColor,
    children,
    test_id
}) => {

    return (
        <>
            <span style={{ display: 'block', color: textColor }} data-testid="spanElement">
                <div className="row">
                    <div style={{width:'0.2%'}}>
                    <i
                    className={icon}
                    data-testid={test_id}
                    style={{ color: iconColor }}
                ></i>
                    </div>
                    <div style={{width:'92%', paddingLeft:'0%'}}>
                      {children}
                    </div>
                </div>
                
                
            </span>
        </>
    );
};
export default IconWithText;