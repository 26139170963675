// Supports both Assets and logs rename has to be done
import { useCallback, useEffect, useState } from 'react';
import { FormatBytes } from 'src/Utils/FormatBytes';
import {
  AssetQueryBy,
  IData,
  IPath,
  IProcessinglogsRequest,
  Stage,
  useGetProcessingLogs,
} from 'src/Utils/Hooks/ProcessingLogs/useGetProcessingLogs';
import DropDown, { IOptions } from '../DropDown/DropDown';
import Loader from '../Loader';
import './ProcessingLogs.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from 'src/CssModules/downloadFile.module.css';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import DivStyles from 'src/CssModules/div.module.css';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
let assetTypeOptions: IOptions[] = [
  {
    displayData: 'ID from hxdr URL',
    codeData: '0',
  },
  {
    displayData: 'ID from device log',
    codeData: '1',
  },
];
let stageTypeOptions: IOptions[] = [
  {
    displayData: 'hxdr.app',
    codeData: '0',
  },
  {
    displayData: 'uat-hxdr.com',
    codeData: '1',
  },
];
let entityTypeOptions: IOptions[] = [
  {
    displayData: 'Logs',
    codeData: 'logs',
  },
  {
    displayData: 'Assets',
    codeData: 'assets',
  },
];
const ProcessingLogs = () => {
  const [entityQueryBy, setEntityQueryBy] = useState(
    entityTypeOptions[0].codeData
  );
  const [assetId, setAssetId] = useState('');
  // this is to chamge the place holder on text box based on selected
  const [assetQueryBy, setAssetQueryBy] = useState(
    Number(assetTypeOptions[0].codeData)
  );
  const [stageType, setStageType] = useState(
    Number(stageTypeOptions[0].codeData)
  );
  const [logRequest, setLogRequest] = useState<IProcessinglogsRequest>({
    parentType: 'logs',
    stage: Stage.PROD,
    queryBy: AssetQueryBy.EntityUUID,
    AssetId: '',
  });
  const { logDetails, logError, logLoading } = useGetProcessingLogs(logRequest);
  const [displayLogs, setDisplayLogs] = useState<IData[]>([]);
  const [folderPath, setFolderPath] = useState<IPath[]>([]);
  useEffect(() => {
    let defaultPath: IPath[] = [
      {
        name:
          logDetails.parentId === 'logs/'
            ? entityTypeOptions[0].displayData
            : entityTypeOptions[1].displayData,
        id: logDetails.parentId,
      },
    ];
    let displayData = logDetails.logs.filter(
      (item) => item.parentId === logDetails.parentId
    );
    setFolderPath(defaultPath);
    setDisplayLogs(displayData);
  }, [logDetails]);

  const loadFolder = (selectedData: IData) => {
    logger.logEvent(
      'Loading Asset details with selected id:' + selectedData.id
    );
    let displayData = logDetails?.logs.filter(
      (item) => item.parentId === selectedData.id
    );
    setDisplayLogs(displayData);

    // update path
    let newFolderPath: IPath = {
      name: selectedData.name,
      id: selectedData.id,
    };
    setFolderPath((prev) => prev.concat(newFolderPath));
  };

  const updateFolderPath = (selectedPath: IPath) => {
    logger.logEvent(
      'Loading Asset details by selecting path from breadcrumb with selected id:' +
      selectedPath.id
    );
    let selectedPathIndex = folderPath.indexOf(selectedPath);
    let length = folderPath.length;
    let fromIdx = selectedPathIndex + 1;
    let toIdx = length - fromIdx;
    if (fromIdx === length) {
      // when user select/clicks the last path which is already loaded
      return;
    }
    let details = [...folderPath];
    details.splice(fromIdx, toIdx);
    setFolderPath(details);

    // Update the ui with selected folder content
    let displayData = logDetails.logs.filter(
      (item) => item.parentId === selectedPath.id
    );
    setDisplayLogs(displayData);
  };
  const onsubmit = useCallback(() => {
    let req: IProcessinglogsRequest = {
      parentType: entityQueryBy,
      stage: stageType,
      queryBy: assetQueryBy,
      AssetId: assetId,
    };
    setLogRequest(req);
    logger.logEvent(
      'Asset details are requested with query: ' + JSON.stringify(req)
    );

  }, [assetId, assetQueryBy, stageType, entityQueryBy]);

  const onFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onsubmit();
  }, [onsubmit])

  return (
    <>
      <LayoutWrapper>
        <form onSubmit={onFormSubmit}>
          <div className="row mb-3">
            <div className={`${DivStyles.width} mb-3 `}>
              <DropDown
                selectedItem={stageTypeOptions[0].codeData}
                items={stageTypeOptions}
                onSelectionChange={(e) => {
                  setStageType(Number(e.codeData));
                }}
                id="stageType-id"
              />
            </div>
            <div className={`${DivStyles.width} mb-3 `}>
              <DropDown
                selectedItem={entityTypeOptions[0].codeData}
                items={entityTypeOptions}
                onSelectionChange={(e) => {
                  setEntityQueryBy(e.codeData);
                  setFolderPath([]);
                  setDisplayLogs([]);
                }}
                id="entityType-id"
              />
            </div>
            <div className={`${DivStyles.width} mb-3 `}>
              <DropDown
                selectedItem={assetTypeOptions[0].codeData}
                items={assetTypeOptions}
                onSelectionChange={(e) => {
                  setAssetQueryBy(Number(e.codeData));
                }}
                id="assetQueryType-id"
              />
            </div>
            <div className={`${DivStyles.width} mb-3 `}>
              <input
                className="form-control"
                type="text"
                value={assetId}
                placeholder={
                  // as index starts from 0 but our code data values started from 1 we are subtractig 1 to access item
                  `Enter ${assetTypeOptions[assetQueryBy].displayData}`
                }
                onChange={(e) => setAssetId(e.target.value)}
              />
            </div>
            <div className={`${DivStyles.width} bottom-margin `}>
              <button className="btn-prim" onClick={
                (e) => {e.preventDefault() ; onsubmit()}
                }>
                Search
              </button>
            </div>
          </div>
        </form>
        <div className="row mb-3">
          <div className="col-md-10 mt-1" data-testid="breadcrumb">
            {!logError &&
              displayLogs.length > 0 &&
              folderPath &&
              folderPath.map((item) => (
                <span
                  data-testid={item.name}
                  onClick={() => {
                    updateFolderPath(item);
                  }}
                  style={{ cursor: 'pointer', color: 'white' }}
                  key={item.id}
                >
                  <strong>
                    {item.id === logDetails.parentId
                      ? item.name
                      : ' > ' + item.name}
                  </strong>
                </span>
              ))}
          </div>
        </div>
        {logLoading && <Loader />}
        {logError && <p style={{ color: 'white' }}>{logError}</p>}
        {!logError && displayLogs.length <= 0 &&
          <ErrorMessage
            message={`No Logs Found`}
          />}
        {displayLogs.length > 0 && !logError && (
          <div className="row">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr key={'header'}>
                    <th className="heading">Name</th>
                    <th className="heading" style={{ paddingLeft: '0px' }}>
                      Size
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayLogs.map((item) => (
                    <tr key={item.id} style={{ paddingLeft: '20px' }}>
                      {item.children && item.children.length > 0 ? (
                        <td className="table-data" style={{ width: '50%' }}>
                          <i
                            className="bi bi-folder-fill"
                            style={{ paddingRight: '10px' }}
                          ></i>
                          <span
                            onClick={() => loadFolder(item)}
                            style={{ cursor: 'pointer' }}
                          >
                            {item.name}
                          </span>
                        </td>
                      ) : (
                        <td style={{ width: '45%' }} className="table-data">
                          <i
                            className="bi bi-file"
                            style={{ paddingRight: '10px' }}
                          ></i>

                          {item.type === 'File' && (
                            <a
                              className={styles.downloadFile}
                              href={item.downLoadLink}
                            >
                              {item.name}
                            </a>
                          )}
                        </td>
                      )}
                      <td
                        className="table-data"

                      >
                        {item.type === 'File' && FormatBytes(item.size)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </LayoutWrapper>
    </>
  );
};
export default ProcessingLogs;