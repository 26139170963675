import {useCallback, useEffect, useRef, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { useAppSelector } from 'src/Redux/hooks';
import { selectDevice } from 'src/Redux/genericSlice';
import { useSearchParams } from 'react-router-dom';
import { IPageData, IPagingOption } from '../Pagination/useGetPagination';
import {usePaginationWrapper} from '../Pagination/usePaginationWrapper';

export interface ILogResponse {
  fileName: string;
  size: number;
  createdOn: string;
  downLoadLink: string;
  serialNumber: string;
}
export interface ISupportLogResponse {
  description: string;
  type: string;
  createdOn: string;
  appLogDownLoadLink: string;
  appLog: string;
  deviceLogDownLoadLink: string;
  deviceLog: string;
  appVersion: string;
  deviceVersion: string;
  serialNumber: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  countryCode?: string;
  replayLog: string;
  telemetryLog: string;
  replayLogDownLoadLink: string;
  telemetryLogDownLoadLink: string;

  deviceTestResult?: IDeviceTestResult | undefined;
  selfTestsSuccessful?: boolean;
}

export interface IDeviceTestResult {
  error?: string | null;
  results: ISelfTestParams[];
}

export interface ISelfTestParams {
  console_log: string | undefined;
  initialized: boolean | undefined;
  success: boolean | undefined;
  test_name: string | undefined;
}

export interface ILogRequest {
  LogType: number;
  supportType?: string;
  FromDateTimeUtc: string;
  ToDateTimeUtc: string;
  FileName?: string;
}

export enum LogType {
  appLog,
  devicelog,
  issue,
  feedback
}

export const useGetAppDeviceLogs = (logRequest: ILogRequest) => {
  const [logDetails, setLogDetails] = useState<IPageData<ILogResponse>>({data:[], hideNextButton: true, hidePrevButton:true});
  const [supportLogDetails, setSupportLogDetails] = useState<
    IPageData<ISupportLogResponse>
  >({data:[], hideNextButton: true, hidePrevButton:true});
  const [logError, setLogError] = useState<string>('');
  const deviceInfo = useAppSelector(selectDevice);
  const [searchParams] = useSearchParams();
  const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
  const baseUrl = Config.GetConfigData().serviceUrl;
  const dynamicHeight = window.innerHeight;
  const defaultHeightDivisor = 76;
  let searchUrl = `${baseUrl}device/logs`;
  let noOfRows = Math.floor(dynamicHeight / defaultHeightDivisor);
  let pageOptions:IPagingOption = {
    chunkSize : 200,
    itemsPerPage : noOfRows 
  }
  const [status, setStatus] = useState(false);
  const searchQuery: string = JSON.stringify(logRequest);
  const refSearch  = useRef(searchQuery);
  const {paginationDetails, paginationError, paginationLoading, getPageDetails, getPreviousPageDetails, reset} = usePaginationWrapper<any>(pageOptions, searchUrl, scope, refSearch.current, undefined);
  const resetQueryAndGetLogs = useCallback(() => {
    let serialNumber = deviceInfo.data.SerialNumber;
    let deviceType = deviceInfo.data.DeviceType;
    if (logRequest.LogType === LogType.feedback ) {
      serialNumber = '';
    }

    var request = {...logRequest, SerialNumber:serialNumber, DeviceType:deviceType};
    const searchQuery: string = JSON.stringify(request);
    reset(searchQuery);
    getPageDetails();
    setStatus(false);
   },[deviceInfo.data.SerialNumber, deviceInfo.data.DeviceType, logRequest, getPageDetails, reset]) 


   useEffect(() => {
    setStatus(false);
    setLogError('');
    let urlSerialNo = searchParams.get('SerialNumber');
    let urlDeviceType = searchParams.get('DeviceType');

    if (
      (logRequest.LogType !== LogType.feedback &&
        (urlSerialNo !== deviceInfo.data.SerialNumber ||
          urlDeviceType !== deviceInfo.data.DeviceType)) ||
      (logRequest.LogType === LogType.feedback &&
        urlDeviceType !== deviceInfo.data.DeviceType)
    ) {
      setLogError('Invalid State');
      setStatus(false);
    }
    else if(Date.parse(logRequest.FromDateTimeUtc) > Date.parse(logRequest.ToDateTimeUtc)){
      setStatus(false);
      setLogError('From date should be less than To date');      
    }else {
      setStatus(true);
      setLogError('');
    }
  }, [  logRequest,
        deviceInfo.data.SerialNumber,
        deviceInfo.data.DeviceType,
        searchParams
    ])

 
   useEffect(() => {
    if(status && !logError) {
      resetQueryAndGetLogs();
    }
   }, [status, resetQueryAndGetLogs, logError])
  
  useEffect(() =>{
    if(logRequest.LogType === LogType.issue || logRequest.LogType === LogType.feedback) {
      if(paginationDetails?.data) {

        const isSelfTestError = (deviceTestResult?: IDeviceTestResult) => {
          return (deviceTestResult?.error !== undefined && deviceTestResult?.error !== '' && deviceTestResult?.error !== null)
                    || (deviceTestResult?.results?.filter((r: ISelfTestParams) => !r?.initialized)?.length! > 0);
        }

        const isSelfTestResultsSuccess = (supportLogResponse: ISupportLogResponse) => {
          return supportLogResponse.deviceTestResult?.results?.filter((r: ISelfTestParams) => r?.success === false).length === 0
                    && !isSelfTestError(supportLogResponse.deviceTestResult);
        }

         let data: IPageData<ISupportLogResponse> = {
          data : paginationDetails?.data,
          hideNextButton: paginationDetails.hideNextButton,
          hidePrevButton: paginationDetails.hidePrevButton
         }
         data.data.forEach((supportLogResponse: ISupportLogResponse) => {
          supportLogResponse.selfTestsSuccessful = isSelfTestResultsSuccess(supportLogResponse);
         })
         setSupportLogDetails(data);
         setLogError('');
      }
    }else{
      if(paginationDetails?.data) {
        let data: IPageData<ILogResponse> = {
          data : paginationDetails?.data,
          hideNextButton: paginationDetails.hideNextButton,
          hidePrevButton: paginationDetails.hidePrevButton
         }
        setLogDetails(data);
        setLogError('');
     }
    }
  },[paginationDetails, logRequest.LogType]);

  useEffect(() =>{
    setLogError(paginationError);
  },[paginationError]);
  
  return { logDetails, supportLogDetails, logError, paginationLoading, getPageDetails, getPreviousPageDetails };
};
