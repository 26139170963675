import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { Config } from "./Utils/ConfigService/LoadConfig";
import { logger } from "./Utils/LoggerService/LoggerService";

export class AuthConfig {
  private static instance: PublicClientApplication;

  public static getMsalInstance = (): PublicClientApplication => {
    let config = Config.GetConfigData();

    if (config.authConfig === undefined) {
      throw new Error('Invalid client id')
    }
    if (!AuthConfig.instance) {
      const msalConfig: Configuration = {
        auth: {
          clientId: config.authConfig.clientId,
          authority: config.authConfig.authority,
          redirectUri: config.authConfig.redirectUri,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true
        },
        system: {
          tokenRenewalOffsetSeconds: 300
        }
      };
      AuthConfig.instance = new PublicClientApplication(msalConfig);
    }
    return AuthConfig.instance;
  }


  public static signOut = () => {
    AuthConfig.getMsalInstance().logoutRedirect();
  }

  public static signIn = () => {
    AuthConfig.getMsalInstance().loginRedirect();
  }

  public static getToken = (scopId: string): Promise<string> => {
    const requestObj = {
      scopes: [scopId]
    };
    let msg = 'Failed to retrieve the access token. Please logout and re login';
    const authResponse = new Promise<string>((resolve, reject) => {
      logger.logInformation('Requesting access token for scope: ' + scopId);
      AuthConfig.getMsalInstance().acquireTokenSilent(requestObj)
        .then(
          (response) => {
            resolve(response.accessToken)
          },
          (error) => {
            logger.logError(error)
            if (error instanceof InteractionRequiredAuthError) {
               // fallback to interaction when silent call fails
              logger.logInformation('Re generating access token ' +scopId)
              return AuthConfig.getMsalInstance().acquireTokenRedirect(requestObj);
            } else {
              logger.logException(error);
              reject(msg);
            }
          }
        )
        .catch(
          (error) => {
              logger.logException(error);
              reject(msg);
              }
        );
    });
    return authResponse;
  }
}




