import React from 'react';
import { useEffect, useState } from 'react'
import { useGetTableauToken } from 'src/Utils/Hooks/Telemetry/GetTableauToken';
import Loader from '../Loader';
import LoadDashboard from './LoadDashboard';
export interface ITableauOptions {
    workbook: string,
    worksheet: string,
    hostUrl: string,
    provisioningId: string,
}
const TableauView: React.FC<ITableauOptions> = ({ workbook, worksheet, hostUrl, provisioningId }) => {
    const [url, setUrl] = useState<string>('')
    const { tableauLoading, tableauError, tableauToken } = useGetTableauToken(worksheet);
   
    useEffect(() => {
        let localurl = `${hostUrl}/t/hexagon/views/${workbook}/${worksheet}`;
        setUrl(localurl);

    }, [workbook, worksheet, hostUrl, provisioningId])

    
    return (
        <div>
            {tableauLoading && <Loader />}
            {
                tableauError ?
                    <p style={{ color: 'white' }}> {tableauError}</p> :
                    tableauToken &&
                    <LoadDashboard url={url} filter={provisioningId} token={tableauToken}/>
            }
        </div>
    )
}
export default React.memo(TableauView);