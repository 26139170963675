import React from 'react';
type AppProps = {
  message: string;
};
const Loader = ({ message }: AppProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        backgroundColor: '#272727',
        zIndex: 1000,
        position: 'fixed',
        opacity: '50%',
        top: '150px',
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div className="spinner-border  text-light" role="status">
        {/* <span className="sr-only">Loading...</span> */}
      </div>
      {message}
    </div>
    //  Simlar to default props
    //  <div class="ui big text loader">{props.message || "Loading..."}</div>
  );
};
Loader.defaultProps = {
  message: 'Loading...',
};
export default Loader;
