import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfoBar, { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import { IReleaseEntry } from "src/Utils/Hooks/FirmwareRelease/useFirmwareRelease";
import { FormatBytes } from "src/Utils/FormatBytes";
import IconWithText from "src/Components/IconWithText/IconWithText";
import { IOTAFileUploadRequest, useOTAFileUpload } from "src/Utils/Hooks/FirmwareRelease/OTAPackage/useOTAFileUpload";
import { useGetValidateRoles, UserRoles } from "src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles";
import ProgressBar from "src/Components/ProgressBar/ProgressBar";
import Loader from "src/Components/Loader";

interface IUploadOTA {
    releaseEntry: IReleaseEntry;
    deviceType: string;
    updateList: (list: IReleaseEntry[]) => void;
}

const OTAPackage: React.FC<IUploadOTA> = ({ releaseEntry, deviceType, updateList }) => {
    const [dlUrl, setDlUrl] = useState<string | undefined>(releaseEntry.dl_url);
    const [showInfoBar, setShowInfoBar] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const otaMsgType = useRef<InfoMessageType>(InfoMessageType.none);
    const otaMsg = useRef('');
    var { validateUser } = useGetValidateRoles();
    var isEditorUser = useMemo(() => validateUser([UserRoles.FirmwareReleaseEditor]), [validateUser]);
    const request: IOTAFileUploadRequest = {
        file: undefined,
        release: releaseEntry,
        releaseType: deviceType
    }
    const [otaFileUploadRequest, setOtaFileUploadRequest] = useState<IOTAFileUploadRequest>(request);
    const { releaseList, cancelFileUpload, progressPercent, fileUploadError, fileUploadLoading } = useOTAFileUpload(otaFileUploadRequest);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [isUpload, setIsUpload] = useState<boolean>(true);

    const OnUpload = () => {
        setShowInfoBar(false);
        let request: IOTAFileUploadRequest = {
            release: releaseEntry,
            releaseType: deviceType,
            file: file
        }
        setShowProgressBar(true);
        setOtaFileUploadRequest(request);
        setIsUpload(false);
    }
    const OnCancel = () => {
        setShowInfoBar(false);
        setShowProgressBar(false);
        setIsUpload(true);
        cancelFileUpload(); 
    }
    const setInfo = useCallback((msg: string, msgType: InfoMessageType) => {
        otaMsg.current = msg;
        otaMsgType.current = msgType;
        setShowInfoBar(true);
        setProgress(0);
        setShowProgressBar(false);
        setIsUpload(true);
    }, []);

    useEffect(() => {
        if (releaseList.length > 0 ) {
            let currentReleaseEntry = releaseList.find(x => x.version === releaseEntry.version);
            updateList(releaseList)
            let size = currentReleaseEntry?.size;
            setInfo(size && size > 0? 'OTA File uploaded successfully' : 'Upload is successful. It might take few minutes to reflect the size on screen. Try refreshing page after a minute.', InfoMessageType.success);
            setShowInfoBar(true);
            // ToDo Revisit & verify as we are updating actual entry
            setDlUrl(currentReleaseEntry?.dl_url);
        }
    }, [releaseList, updateList , releaseEntry.version, setInfo])

    useEffect(() => {
        setProgress(progressPercent);
    }, [progressPercent])

    useEffect(() => {
        if (fileUploadError !== '') {
            setInfo(fileUploadError, InfoMessageType.error);
            setShowInfoBar(true);
        }
    }, [fileUploadError, setInfo])
    return (
        <div style={{ marginLeft: '10px' }}>
            <div className="row pb-3">
                <IconWithText
                    icon='bi bi-info-circle-fill'
                    test_id="upload ota"
                    iconColor="white"
                    textColor="white" >
                    <p>Upload a local file or choose a file from the server</p>
                </IconWithText>
            </div>
            <div className="row">

                {showInfoBar &&
                    <div className='col-md-11'>
                        <InfoBar
                            Message={otaMsg.current}
                            MessageType={otaMsgType.current}
                            closeClick={() => { setShowInfoBar(false) }}
                        />
                    </div>
                }
                {
                    !dlUrl &&
                    <div className="row pb-3">
                        <IconWithText
                            icon='bi bi-info-circle-fill'
                            test_id="otaNotAdded"
                            iconColor="white"
                            textColor="white" >
                            <p>OTA file not added yet!</p>
                        </IconWithText>
                    </div>
                }
                {showProgressBar && (
                    <div className="pb-4" style={{width: '48%'}}>
                        <ProgressBar
                            progress={progress}
                        />
                    </div>
                )}
                {fileUploadLoading && <Loader/>}
                <div className="row">
                    <div className="col-md-5">
                        <input className="form-control"
                            disabled={!isEditorUser}
                            type="file"
                            id="formFile"
                            accept=".zip,.exe,.bin,.com"
                            data-testid="OTA-file"
                            onChange={(event) => {
                                if (event.target.files != null) {
                                    setFile(event.target.files[0])
                                }
                            }} />
                    </div>

                    {isUpload &&
                        <div className="col-md-2" style={{ marginLeft: '-20px' }}>
                            <button className="btn-prim" onClick={OnUpload} data-testid="upload" disabled={file === undefined || !isEditorUser}>
                                Upload
                            </button>
                        </div>
                    }
                    {!isUpload &&
                        <div className="col-md-2" style={{ marginLeft: '-20px' }}>
                            <button className="btn-prim" onClick={OnCancel} data-testid="cancel" disabled={file === undefined || !isEditorUser}>
                                Cancel
                            </button>
                        </div>
                    }

                </div>

                {dlUrl &&
                    <div className="row pt-3">
                        <IconWithText
                            icon="bi bi-info-circle-fill"
                            test_id="ota url"
                            iconColor="white"
                            textColor="white"
                        >
                            <p>Current URL: <a href={dlUrl} style={{ color: 'white' }} target="_blank" rel="noreferrer">{dlUrl}</a></p>
                        </IconWithText>
                        <div style={{ marginLeft: '27px', color: 'white' }}>Size: {FormatBytes(releaseEntry.size)}</div>
                    </div>
                }
            </div>
        </div>

    )
}
export default OTAPackage;
