import fscreen from 'fscreen';
import { useState, useEffect } from 'react';

export function useFullscreen() {
    const [active, setActive] = useState(false);
    useEffect(() => {
        const handleChange = () => {
            setActive(!active);
        };
        fscreen.addEventListener('fullscreenchange', handleChange);
        return () =>
            fscreen.removeEventListener('fullscreenchange',  handleChange);
    }, [active]);
  
    return {
        fullscreenActive: active,
    };
}