import { configureStore } from '@reduxjs/toolkit'
import deviceReducer from './genericSlice'


export const store = configureStore({
  reducer: {
   device: deviceReducer
  }
})

// Infer the `RootState` and `storeDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch