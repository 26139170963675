import axios from 'axios';
import { useCallback, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export const useHtmlEditor = () => {
  const [htmlEditorContent, setHtmlEditorContent] = useState<string>('');
  const [htmlEditorContentError, setHtmlEditorContentError] = useState<string>('');
  const [htmlEditorContentLoading, setHtmlEditorContentLoading] = useState<boolean>(false);

  const getScope = useCallback(
    () => Config.GetConfigData().firmwareReleaseService?.scopes[0],[]
  );
  const getUrl = useCallback(
    () => Config.GetConfigData().firmwareReleaseService?.serviceUrl + 'releases',[]
  );
  const getHtmlContent = useCallback(
    (version: string, releaseType:string) => {
      setHtmlEditorContentLoading(true);
      setHtmlEditorContentError('')
      var scope = getScope();
      AuthConfig.getToken(scope).then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        logger.logInformation(
          `Access token is  Received successfully  for scope :  ${scope} + 'and add or update firmware releases`
        );
        axios
          .get(`${getUrl()}/attachments/${version}/release_notes.html`, {
            headers,
            params:{
              type:releaseType
            }
          })
          .then(
            (response) => {
              setHtmlEditorContent(response.data);
              setHtmlEditorContentError('');
            },
            (error) => {
              setHtmlEditorContent('');
              logger.logError(
                `Failed to  get Html content: + error:  + ${JSON.stringify(
                  error
                )}`
              );
              if (error.response !== undefined) {
                setHtmlEditorContentError(error.response.data.Message);
              } else {
                setHtmlEditorContentError(JSON.stringify(error));
              }
            }
          )
          .finally(() => {
            setHtmlEditorContentLoading(false);
          });
      });
    },
    [getScope, getUrl]
  );

  return {
    getHtmlContent,
    htmlEditorContent,
    htmlEditorContentError,
    htmlEditorContentLoading,
  };
};
