import { useIsAuthenticated } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import LogFileBrowser from './Components/LogFileBrowser/LogFileBrowser';
import ProcessingLogs from './Components/ProcessingLogs/ProcessingLogs';
import Telemetry from './Components/Telemetry/Telemetry';
import Home from './Components/Home/Home';
import DeviceIssues from './Components/DeviceIssues/DeviceIssues';
import UserFeedback from './Components/UserFeedback/UserFeedback';
import './AppLayout.css';
import DeviceStatus from './Components/DeviceStatus/DeviceStatus';
import FleetTelemetry from './Components/FleetTelemetry/FleetTelemetry';
import QueryTwin from './Components/QueryTwin/QueryTwin';
import NavBar from './Components/Navbar/Navbar';
import DeviceExplorer from './Components/DeviceExplorer/DeviceExplorer';
import DeviceEnrollmentGroup from './Components/DeviceEnrollmentGroup/DeviceEnrollmentGroup';
import { useGetValidateRoles, UserRoles } from './Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles';
import ContactAdmin from './Components/ContactAdmin/ContactAdmin';
import React, { lazy, Suspense } from 'react';
import FirmwareReleases from './Components/FirmwareRelease/FirmwareRelease';
import Loader from './Components/Loader';
const LuciadRiaSampleMap = lazy(() => import('./Components/LuciadRia/LuciadRiaSampleMap'));

const AppLayout = () => {
  const isAuthenticated = useIsAuthenticated();
  var {validateUser} = useGetValidateRoles();
  let supportUser = validateUser([UserRoles.SupportUser]);
  let productionUser = validateUser([UserRoles.ProductionUser]);
  let mapViewer = validateUser([UserRoles.MapViewer]);

  return (
    <>
      <Router>
        <NavBar isSupportUser={supportUser} isProductionUser={productionUser}  isMapUser={mapViewer}/>
          <div className="main-container">
            <div className="main-content">
            <Suspense fallback={<Loader />}>
              {isAuthenticated  && (
                <Routes>
                  {supportUser && 
                  <>
                    <Route path="/device-status" element={<DeviceStatus />} />
                    <Route path="/device-issues" element={<DeviceIssues />} />
                    <Route path="/device-telemetry" element={<Telemetry />} />
                    <Route path="/device-logs" element={<LogFileBrowser />} />
                    <Route path="/fleet-telemetry" element={<FleetTelemetry />} />
                    <Route path="/fleet-feedback" element={<UserFeedback />} />
                    <Route path="/processing-logs" element={<ProcessingLogs />} />
                    <Route path="/fleet-querytwin" element={<QueryTwin />}/>
                    
                  </>
                }
                {productionUser &&
                  <>
                    <Route path="/provisioning-device-explorer" element={<DeviceExplorer />}/>
                    <Route path="/provisioning-enrollment-group" element={<DeviceEnrollmentGroup />}/>
                    </>
                }
                {mapViewer && (
                  <>
                    <Route path="/map-view" element={<LuciadRiaSampleMap />} />
                  </>
                )}
                
                <Route path="/fleet-releases" element={<FirmwareReleases />}/>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/contact-admin" element={<ContactAdmin />} />
                
                </Routes>
                
              )
              }
              </Suspense>
            </div>
          </div>
      </Router>
   
    </>
  );
};
export default React.memo(AppLayout);
