import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../../Loader';
import Modal from 'src/CssModules/modal.module.css';
import DropDown, { IOptions } from '../../DropDown/DropDown';
import Datepicker from '../../Datepicker/Datepicker';
import {
  IReleaseEntry,
  useFirmwareRelease,
} from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import { dateToEpoch } from 'src/Utils/DateHelper';

export interface IModelParams {
  deviceType: string;
  onClose: () => void;
  setReleases: (releases: IReleaseEntry[]) => void;
  setError: (error: string) => void;
}
const FirmwareReleaseAdd: React.FC<IModelParams> = ({
  deviceType,
  onClose,
  setReleases,
  setError,
}) => {
  const releaseTypeOptions: IOptions[] = [
    {
      displayData: 'SELECT',
      codeData: 'SELECT',
    },
    {
      displayData: 'Beta',
      codeData: 'Beta',
    },
    {
      displayData: 'Snapshot',
      codeData: 'Snapshot',
    },
    {
      displayData: 'Staging',
      codeData: 'Staging',
    },
    {
      displayData: 'Release (public!)',
      codeData: 'Release',
    },
    {
      displayData: 'Archived (no OTA file)',
      codeData: 'Archived',
    },
    {
      displayData: 'SpecialBuild',
      codeData: 'SpecialBuild',
    },
  ];
  const [releaseType, setReleaseType] = useState<string>(
    releaseTypeOptions[0].codeData
  );
  const [version, setVersion] = useState<string>('');
  const [enable, setEnable] = useState<boolean>(true);
  const [display, setdisplay] = useState('block');
  const [date, setDate] = useState<Date>(new Date());

  const multichargerAllowedReleaseTypes = ['SELECT', 'Release', 'Staging', 'Snapshot'];

  const {
    addOrUpdateFirmwareReleases,
    firmwareReleases,
    firmwareReleaseError,
    firmwareReleaseLoading,
  } = useFirmwareRelease();
  const verifyNextEnable = useCallback(() => {
    if (releaseType !== 'SELECT' && version !== '') {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [releaseType, version]);
  const close = useCallback(() => {
    setdisplay('none');
    onClose();
  }, [onClose]);
  const createRelease = useCallback(() => {
    let objReleaseEntry: IReleaseEntry = {
      date: dateToEpoch(date),
      type: releaseType,
      version: version,
      revision: '',
      size: 0,
      dl_url: '',
      changelog_url: '',
      attachments: [],
      companions: [],
    };
    addOrUpdateFirmwareReleases(objReleaseEntry, deviceType);
  }, [version, releaseType,addOrUpdateFirmwareReleases,deviceType,date]);

  useEffect(() => {
    if (firmwareReleases.length > 0) {
      setReleases(firmwareReleases);
      close();
    }
  }, [firmwareReleases,setReleases,close]);

  useEffect(() => {
    setError(firmwareReleaseError);
  }, [firmwareReleaseError,setError]);
  useEffect(() => {
    verifyNextEnable();
  }, [verifyNextEnable]);

  const fetchReleaseTypes = () => {
    if(deviceType !== 'BLK2FLY_CHARGER') {
      return releaseTypeOptions;
    } else {
      return releaseTypeOptions.filter(r => multichargerAllowedReleaseTypes.includes(r.codeData));
    }
  }
  return (
    <>
      {firmwareReleaseLoading && <Loader />}
      <div id="myModal" className={Modal.modal} style={{ display: display }}>
        <div className={Modal.content} style={{ width: '30%' }}>
          <div className={Modal.header}>
            <h4 className="text-center">Create New Release</h4>
          </div>
          <hr style={{ color: 'white' }} />
          <div className={Modal.body}>
            <div style={{ paddingLeft: 90 }}>
              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Version
                </label>
                <div className="col-md-7">
                  <input
                    data-testid="version-textBox"
                    className="form-control"
                    placeholder="Version"
                    value={version}
                    onChange={(e) => {
                      setVersion(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Date
                </label>
                <div className="col-md-7">
                  <Datepicker
                    SelectedDate={date}
                    setDate={(e) => setDate(e)}
                    MaxDate={new Date()}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Type
                </label>
                <div className="col-md-7">
                  <DropDown
                    selectedItem={releaseType}
                    items={fetchReleaseTypes()}
                    onSelectionChange={(e) => {
                      setReleaseType(e.codeData);
                    }}
                    id="type-dropDown"
                    // data-testid="type-dropDown"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr style={{ color: 'white' }} />
          <div className={Modal.footer}>
            <div className="row">
              <div className="col-sm-4" style={{ marginLeft: '80px' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => close()}
                  data-testid="cancel"
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-4" style={{ marginLeft: '96px' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => createRelease()}
                  data-testid="create-release"
                  disabled={enable}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FirmwareReleaseAdd;
