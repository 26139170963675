import React from "react";
import IconWithTooltip, {
  TooltipPosition,
} from "src/Components/IconWithTooltip/IconWithTooltip";
import ExpandCollapse from "src/Components/ExpandCollapse/ExpandCollapse";
import {
  IDeviceTestResult,
  ISelfTestParams,
} from "src/Utils/Hooks/AppDeviceLogs/useGetAppDeviceLogs";
import "src/Components/SearchBarWithIcon/SearcBarWithIcon.css";
import "src/Components/SelfTestsReportDialog/SelfTestsReportDialog.css";

const ShowSelfTestDialog: React.FC<any> = (
  deviceTestsResult: IDeviceTestResult
) => {
  let deviceTestObj = (deviceTestsResult as any).deviceTestsResult;
  if (deviceTestObj?.error && deviceTestObj?.error !== "") {
    return (
      <div
        style={{
          paddingLeft: "70px",
          maxHeight: "40vh",
          width: "60vw",
          overflowY: "auto",
        }}
      >
        <div style={{ color: "white" }}>{deviceTestObj?.error}</div>
      </div>
    );
  } else {
    const getResultsFromDeviceTestsObj = () =>
      Object.entries(deviceTestsResult)[0][1].results;

    const getSelfTestNameInFormat = (key: string | undefined) => {
      let result = "";
      if (key) {
        result = key.replaceAll("_", " ");

        if (result.split(" ").length > 1) {
          result = result
            .toLowerCase()
            .split(" ")
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(" ");
        }
      }
      return result;
    };

    const getSortedResults = () =>
      getResultsFromDeviceTestsObj()?.sort(
        (first: ISelfTestParams, next: ISelfTestParams) =>
          first?.test_name! > next?.test_name! ? 1 : -1
      );

    let results = getSortedResults();

    const keys: string[] | undefined = results?.map((r: ISelfTestParams) =>
      getSelfTestNameInFormat(r?.test_name)
    );

    const values: ISelfTestParams[] = results?.map(
      (r: ISelfTestParams) => r
    ) as ISelfTestParams[];

    const expand_collapse_titles = {
      expand: "Show Details",
      collapse: "Hide Details",
    };

    let Convert = require("ansi-to-html");
    let convert = new Convert({ newline: true });

    return (
      <div
        style={{
          paddingLeft: "70px",
          maxHeight: "40vh",
          width: "60vw",
          overflowY: "auto",
        }}
      >
        {keys?.length! > 0 &&
          keys?.map((key: string, index: number) => {
            return (
              <div key={"selftest_" + index} className="form-group row mb-3">
                <div data-testid="self-test-data">
                  {values[index].success === true &&
                  values[index].initialized === true ? (
                    <IconWithTooltip
                      tooltipText=""
                      icon="fa fa-check"
                      iconColor="green"
                      tooltipPosition={TooltipPosition.Top}
                    ></IconWithTooltip>
                  ) : (
                    <IconWithTooltip
                      tooltipText=""
                      icon="fa fa-close"
                      iconColor="red"
                      tooltipPosition={TooltipPosition.Top}
                    ></IconWithTooltip>
                  )}

                  <label style={{ marginLeft: 10, color: "white" }}>
                    {key}
                  </label>

                  {values.length > 0 &&
                  (values[index].success === false ||
                    values[index].initialized === false) ? (
                    <div>
                      <ExpandCollapse
                        data-testid="expand-collapse-id"
                        titles={expand_collapse_titles}
                        log={values[index]?.console_log?.toString()}
                      >
                        <div>
                          <span
                            className="log-default-color-css"
                            dangerouslySetInnerHTML={{
                              __html: convert
                                .toHtml(values[index]?.console_log!)
                                .toString(),
                            }}
                          ></span>
                        </div>
                      </ExpandCollapse>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}

        {keys?.length === 0 ? (
          <div style={{ color: "white" }}>
            Some error occured while initializing self tests!
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default ShowSelfTestDialog;
