import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
export enum UserRoles {
    SupportUser = "SupportUser",
    ProductionUser = "ProductionUser",
    ProductionDeviceAdmin = "ProductionDeviceAdmin",
    ProductionDeviceEditor = "ProductionDeviceEditor",
    MapViewer = "MapViewer",
    FirmwareReleaseEditor= "FirmwareReleaseEditor"
}
export const useGetValidateRoles = () => {
    const { accounts } = useMsal();
    const account = accounts[0];
    let tokenClaims : any = account?.idTokenClaims;
    let userRoles = tokenClaims?.roles;

    const validateUser = useCallback((roles: string[]) => {
        if(!userRoles) {return false}
        for (let i = 0; i < userRoles.length; i++) {
            if (roles.indexOf(userRoles[i]) >= 0) {
                return true;
            }
        }
        return false;
    },[userRoles])

    return {validateUser}
}