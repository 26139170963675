import React from "react";
import IconWithTooltip, {
  TooltipPosition,
} from "../IconWithTooltip/IconWithTooltip";
import { useRenderReplayTool } from "src/Utils/Hooks/ReplayTool/useRenderReplayTool";

export interface IReplayChildParamas {
  replayLogRelativePath: string;
  deviceType?: string | null;
  setLoading: (loading: boolean) => void;
  setErrorMessage: (err: string | null) => void;
}

const FlightReplay: React.FC<any> = ({ replayLogRelativePath, deviceType, setLoading, setErrorMessage }: IReplayChildParamas) => {
  const { renderReplayTool } = useRenderReplayTool(replayLogRelativePath, setLoading, setErrorMessage, deviceType);
  
  return (
    <>
      <span
      data-testid="replay-tool-icon-test-id"
        onClick={async () => {
          await renderReplayTool();
        }}
        style={{ margin: "0px 10px 0px 10px" }}
      >
        <IconWithTooltip
          tooltipText="Replay Tool"
          icon="fa fa-play-circle"
          tooltipPosition={TooltipPosition.Top}
          fontSize="19px"
          top="2px"
        ></IconWithTooltip>
      </span>
    </>
  );
};

export default FlightReplay;
