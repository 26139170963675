import React from 'react';
import './Step.css';
export interface IStepDetails {
  stepNumber: number;
  stepLabel: string;
  stepConfirmMessage?:string;
}
export interface IStepParams {
  stepDetails: IStepDetails;
  selected: boolean;
  updateStep: (step: number) => void;
}
const Step: React.FC<IStepParams> = ({ stepDetails, selected, updateStep }) => {
  return (
    <>
      <div data-testid={`${stepDetails.stepNumber}`} className={'stepBlock' + (selected ? ' selected' : ' ')} style={{ display:'flex' }}>
        <div
          className={'circleWrapper '}
          onClick={() => updateStep(stepDetails.stepNumber)}
        >
          <div className={'circle '}>{stepDetails.stepNumber}</div>
        </div>
        <span >{stepDetails.stepLabel}</span>
      </div>
    </>
  );
};

export default Step;
