import axios from 'axios';
import { useEffect, useState } from 'react';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
export interface Version {
  Version: string;
  ReleaseDate: string;
  Notes: string[];
}
export const useGetVersions = () => {
  const [versionResponse, setVersionResponse] = useState<Version[]>([]);
  const [versionLoading, setVersionLoading] = useState<boolean>(true);
  const blobFileUrl: string = Config.GetConfigData().versionBlobUrl;
  useEffect(() => {
    axios
      .get<Version[]>(blobFileUrl)
      .then(
        (response) => {
          setVersionResponse(response.data);
        },

        (error) => {
          let msg = 'Failed to retreive version blob file from blob storage';
          logger.logError(`${msg} - ${error}`);
        }
      )
      .finally(() => {
        setVersionLoading(false);
      });
  }, [blobFileUrl]);
  return { versionResponse, versionLoading };
};
