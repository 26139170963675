import Accordion from 'src/Components/Accordion/Accordion';
import IconWithTooltip from 'src/Components/IconWithTooltip/IconWithTooltip';
import { IReleaseEntry } from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import OTAPackage from '../OTAPackage/OTAPackage';
import ComapnionSoftware from '../CompanionSoftware/CompanionSoftware';
import EditReleaseVersions from '../EditReleaseVersion/EditReleaseVersion';

import ReleaseNotes from '../ReleaseNotes/ReleaseNotes';
import { useCallback, useState } from 'react';
import Loader from 'src/Components/Loader';

interface IEditReleaseEntry {
  releaseEntry: IReleaseEntry;
  deviceType: string;
  onBack: () => void;
  deleteEntry: (item: IReleaseEntry) => void;
  updateList: (list: IReleaseEntry[]) => void;
}

const EditReleaseManagement: React.FC<IEditReleaseEntry> = ({
  releaseEntry,
  deviceType,
  onBack,
  updateList,
  deleteEntry,
}) => {
  const [entry, setEntry] = useState<IReleaseEntry>(releaseEntry);
  const [editReleaseLoading, setEditReleaseLoading] = useState<boolean>(false);
  const updateEditReleaseLoader = useCallback((load: boolean) => {
    setEditReleaseLoading(load);
  }, []);

  const upldateReleaseList = useCallback(
    (list: IReleaseEntry[]) => {
      let currentReleaseEntry = list.find(
        (x) => x.version === releaseEntry.version
      );
      if (currentReleaseEntry) {
        setEntry(currentReleaseEntry);
      }
      updateList(list);
    },
    [releaseEntry.version, updateList, setEntry]
  );
  return (
    <>
      {editReleaseLoading && <Loader />}
      <span data-testid="backIcon" onClick={() => onBack()} style={{fontSize:'30px', fontWeight: 'bold'}}>
        <IconWithTooltip
          icon="bi bi-arrow-left"
          tooltipText="Back"
          test_id="backTest"
        ></IconWithTooltip>
      </span>
      <EditReleaseVersions
        releaseEntry={entry}
        deviceType={deviceType}
        updateList={upldateReleaseList}
        deleteEntry={deleteEntry}
        updateEditReleaseLoader={updateEditReleaseLoader}
      />
      <Accordion header="OTA Package">
        <OTAPackage
          releaseEntry={entry}
          deviceType={deviceType}
          updateList={upldateReleaseList}
        />
      </Accordion>
      <Accordion header="Release notes">
        <ReleaseNotes
          releaseEntry={entry}
          releaseType={deviceType}
          updateList={upldateReleaseList}
          updateEditReleaseLoader={updateEditReleaseLoader}
        />
      </Accordion>
      <Accordion header="Compatible Companion Software">
        <ComapnionSoftware
          releaseEntry={entry}
          deviceType={deviceType}
          updateEditReleaseLoader={updateEditReleaseLoader}
          updateList={upldateReleaseList}
        />
      </Accordion>
    </>
  );
};
export default EditReleaseManagement;
