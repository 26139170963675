import {
  useGetValidateRoles,
  UserRoles,
} from 'src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles';
import CreateDeviceEnrollment from '../CreateDeviceEnrollment/CreateDeviceEnrollment';
import InfoBar, { InfoMessageType } from '../InfoBar/InfoBar';
import { DeviceAttributes, IDeviceDetails, IDisplayDeviceDetails, useDeviceSearchDetails } from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearchDetails';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import Loader from '../Loader';
import { IDeviceSearchRequest } from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearchDetails';
import { useCallback, useEffect, useRef, useState } from 'react';
import tableStyles from 'src/CssModules/table.module.css';
import IconWithTooltip, {
  TooltipPosition,
} from '../IconWithTooltip/IconWithTooltip';
import { ExportToCsv } from 'export-to-csv';
import { useDevice } from 'src/Utils/Hooks/DeviceEnrollment/useDevice';
import DropDown, { IOptions } from '../DropDown/DropDown';
import { useGetEnrollmentGroups } from 'src/Utils/Hooks/EnrollmentGroupService/useGetEnrollmentGroups';
import { useSearchParams } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import PaginationButtons from '../PaginationButtons/PaginationButtons';

const DeviceExplorer: React.FC = () => {
  const { enrollmentGroups, enrollmentGroupsError, enrollmentGroupsLoading } = useGetEnrollmentGroups(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let urlParms = {
    serialNumber: searchParams.get('SerialNumber'),
    provisioningId: searchParams.get('ProvisioningId'),
    provisioningStatus: searchParams.get('ProvisioningStatus'),
    certificateAvailable: searchParams.get('CertificateAvailable'),
    enrollmentGroup: searchParams.get('GroupName')
  };
  let provisioningStatusTypeOptions: IOptions[] = [
    {
      displayData: 'All (ProvisioningStatus)',
      codeData: 'All',
    },
    {
      displayData: 'ENROLLED',
      codeData: 'ENROLLED',
    },
    {
      displayData: 'REGISTERED',
      codeData: 'REGISTERED',
    },
  ];
  let certificateAvailableTypeOptions: IOptions[] = [
    {
      displayData: 'All (CertificateAvailable)',
      codeData: 'All',
    },
    {
      displayData: 'True',
      codeData: 'True',
    },
    {
      displayData: 'False',
      codeData: 'False',
    },
  ];
  let groupNameTypeOptions: IOptions[] = [];
  groupNameTypeOptions.push({
    displayData: 'All (GroupName)',
    codeData: 'All'
  })
  groupNameTypeOptions.push(...enrollmentGroups.map(
    (item) => {
      return { displayData: item.groupName, codeData: item.groupName };
    }));
  const serialNumber = useRef<string>(
    urlParms.serialNumber === null ? '' : urlParms.serialNumber
  );
  const provisioningId = useRef<string>(
    urlParms.provisioningId === null ? '' : urlParms.provisioningId
  );
  const provisioningStatus = useRef(
    urlParms.provisioningStatus === null ? '' : urlParms.provisioningStatus
  );
  const certificateAvailable = useRef(
    urlParms.certificateAvailable === null ? '' : urlParms.certificateAvailable
  );
  const enrollmentGroup = useRef(
    urlParms.enrollmentGroup === null ? '' : urlParms.enrollmentGroup
  );
  const [searchRequest, setSearchRequest] = useState<IDeviceSearchRequest>({
    searchDetails: {
      serialNumber: serialNumber.current,
      provisioningId: provisioningId.current,
      provisioningStatus: provisioningStatus.current === 'All' ? '' : provisioningStatus.current,
      isCertificateAvailable: certificateAvailable.current === 'All' ? undefined : certificateAvailable.current === 'True' ? true : false,
      enrollmentGroup: enrollmentGroup.current === 'All' ? '' : enrollmentGroup.current,
    }
  });
  const {
    paginationDetails,
    paginationError,
    paginationLoading,
    getPageDetails,
    getPreviousPageDetails,
    reset
  } = useDeviceSearchDetails(searchRequest);
  const {
    enrollmentMessage,
    enrollmentStatus,
    enrollmentLoading,
    downloadDevice
  } = useDevice();
  const [toggleInfoBar, setToggleInfoBar] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isDeviceSelected, setIsDeviceSelected] = useState<boolean>(false);
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<IDeviceDetails[]>([]);
  const [infoBar, setInfoBar] = useState<boolean>(false);
  const msg = useRef('');
  const msgType = useRef<InfoMessageType>(InfoMessageType.success);
  var { validateUser } = useGetValidateRoles();
  const [createEnrollment, setCreateEnrollment] = useState(false);

  // Checks whether all the devices in a page are selected or not. If so enables checkAll checkbox
  const checkAllDevicesSelected = useCallback(() => {
    const selectedDevicesCount = paginationDetails?.data.filter(x => x.isSelected === true).length;
    selectedDevicesCount === paginationDetails?.data.length ? setIsCheckAll(true) : setIsCheckAll(false);
  }, [paginationDetails])

  // function executes when user clicks on the checkAll checkbox
  const selectAll = (value: boolean) => {
    setIsCheckAll(!isCheckAll);

    // adding the pagination details to the list by changing isSelected value 
    if (paginationDetails) {
      const deviceList: IDeviceDetails[] = [];
      paginationDetails.data.forEach(item => {
        item.isSelected = value;
        deviceList.push(item)
      });

      // if user selects checkAll checkbox, add the list to the selectedDevice list
      if (value) {
        setSelectedDeviceDetails(selectedDeviceDetails.concat(deviceList))
      }

      // if user deselects the checkAll checkbox, remove the list from the selectedDevice list
      else {
        setSelectedDeviceDetails(selectedDeviceDetails.filter((elmt) => !deviceList.includes(elmt)))
      }
    }
  }

  // function executes when user clicks on the individual checkbox
  const select = (item: IDeviceDetails, checked: boolean) => {
    item.isSelected = checked;

    // if user deselects the device, removing the item from the selectedDevice list
    if (!checked) {
      setIsCheckAll(false);
      setSelectedDeviceDetails(selectedDeviceDetails.filter(x => x.provisioningId !== item.provisioningId));
    }

    // else adding the item to the selectedDevice list
    else {
      setSelectedDeviceDetails([...selectedDeviceDetails, item])
    }
    checkAllDevicesSelected();
  }

  const addEnrollment = () => {
    setCreateEnrollment(true);
  };

  const downloadCsv = () => {
    const downloadList: IDisplayDeviceDetails[] = [];
    for (let i = 0; i < selectedDeviceDetails.length; i++) {
      const item: IDisplayDeviceDetails = {
        serialNumber: selectedDeviceDetails[i].serialNumber,
        enrollmentGroup: selectedDeviceDetails[i].enrollmentGroup,
        provisioningId: selectedDeviceDetails[i].provisioningId,
        provisioningStatus: selectedDeviceDetails[i].provisioningStatus,
        isCertificateAvailable: selectedDeviceDetails[i].isCertificateAvailable,
        certificateCreatedTime: selectedDeviceDetails[i].certificateCreatedTime,
        createdBy: selectedDeviceDetails[i].createdBy
      };
      downloadList.push(item);
    }

    // https://www.npmjs.com/package/export-to-csv
    const options = {
      filename: 'device-details',
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      // tslint:disable-next-line:max-line-length
      headers: [DeviceAttributes.SerialNumber, DeviceAttributes.EnrollmentGroup, DeviceAttributes.ProvisioningId, DeviceAttributes.ProvisioningStatus, DeviceAttributes.CertificateAvailable, DeviceAttributes.CertificateCreatedTime, DeviceAttributes.CreatedBy]
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(downloadList);
  }
  const downloadCertificates = () => {
    downloadDevice(selectedDeviceDetails);
    setInfoBar(true);
  }

  const validateSearchParams = () => {
    if (serialNumber.current === '' && provisioningId.current === '' && certificateAvailable.current === '' && provisioningStatus.current === '' && enrollmentGroup.current === '') {
      return false
    }
    return true
  };

  const isSearchParams = useRef<boolean>(validateSearchParams())

  const setUrlParams = useCallback(() => {
    let urlParams = {
      SerialNumber: serialNumber.current,
      ProvisioningId: provisioningId.current,
      ProvisioningStatus: provisioningStatus.current,
      CertificateAvailable: certificateAvailable.current,
      GroupName: enrollmentGroup.current
    };
    setSearchParams(urlParams);
  }, [setSearchParams]);

  const reload = () => {
    setSelectedDeviceDetails([])
    reset(JSON.stringify(searchRequest.searchDetails))
    getPageDetails()
  }

  const status = (message: string, type: InfoMessageType) => {
    msgType.current = type;
    msg.current = message;
    setToggleInfoBar(true);
  };

  const setDropdownFieldsIfEmptyOnSubmit = () => {
    if (provisioningStatus.current === '') {
      provisioningStatus.current = 'All'
    }
    if (enrollmentGroup.current === '') {
      enrollmentGroup.current = 'All'
    }
    if (certificateAvailable.current === '') {
      certificateAvailable.current = 'All'
    }
  }

  const handleSubmit = useCallback(() => {
    setDropdownFieldsIfEmptyOnSubmit();
    let formRequest: IDeviceSearchRequest = {
      searchDetails: {
        serialNumber: serialNumber.current,
        provisioningId: provisioningId.current,
        provisioningStatus: provisioningStatus.current === 'All' ? '' : provisioningStatus.current,
        isCertificateAvailable: certificateAvailable.current === 'All' ? undefined : certificateAvailable.current === 'True' ? true : false,
        enrollmentGroup: enrollmentGroup.current === 'All' ? '' : enrollmentGroup.current,
      }
    };
    setUrlParams();
    let areEqual =
      Object.entries(formRequest.searchDetails).toString() ===
      Object.entries(searchRequest.searchDetails).toString();
    if (!areEqual) {
      setSearchRequest(formRequest);
      reset(JSON.stringify(formRequest.searchDetails))
      getPageDetails()
    }
  }, [searchRequest, reset, getPageDetails, setUrlParams]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  useEffect(() => {
    setIsCheckAll(false);
    checkAllDevicesSelected();
  }, [paginationDetails?.data, checkAllDevicesSelected])

  useEffect(() => {
    if (isSearchParams.current) {
      getPageDetails()
    }
    isSearchParams.current = false;
  }, [getPageDetails, isSearchParams])

  useEffect(() => {
    let enable = selectedDeviceDetails.length > 0;
    setIsDeviceSelected(enable);
  }, [selectedDeviceDetails]);

  useEffect(() => {
    setUrlParams();
  }, [setUrlParams]);

  return (
    <LayoutWrapper>
      {toggleInfoBar && (
        <div className="row">
          <InfoBar
            Message={msg.current}
            MessageType={msgType.current}
            closeClick={() => {
              setToggleInfoBar(false);
            }}
          />
        </div>
      )}
      {
        infoBar && enrollmentMessage !== '' &&
        <div className="row">
          <InfoBar
            Message={enrollmentMessage}
            MessageType={
              enrollmentStatus === "Success"
                ? InfoMessageType.success
                : InfoMessageType.error
            }
            closeClick={() => { setInfoBar(false) }}
          />
        </div>
      }
      {createEnrollment && (
        <CreateDeviceEnrollment
          onClose={() => setCreateEnrollment(false)}
          onStatus={(message, messageType) => status(message, messageType)}
        />
      )}
      <div className="row">
        <div
          className="card"
          style={{
            position: 'inherit',
            backgroundColor: 'black',
            border: '1px solid white',
          }}
        >
          <div
            className="card-header"
            style={{
              color: 'white',
              backgroundColor: 'black',
              fontSize: '1.14vw',
              paddingLeft: '4vw',
              borderBottomColor: 'white',
            }}
            data-testid="header"
          >
            <div className="row col-md-12">
              Device Enrollment Details
              <div
                style={{ paddingLeft: '3.1vw' }}
                className="col-sm-2  ms-auto"
              >
                {/* Only Admin users can create device */}
                {(validateUser([UserRoles.ProductionDeviceAdmin]) ||
                  validateUser([UserRoles.ProductionDeviceEditor])) && (
                    <>
                      <span
                        style={{ paddingLeft: '20px', fontSize: '1.4vw' }}
                        data-testid="create-device"
                        onClick={() => addEnrollment()}
                      >
                        <IconWithTooltip
                          tooltipText="Create Device Enrollment"
                          icon="bi bi-plus"
                          tooltipPosition={TooltipPosition.Bottom}
                        ></IconWithTooltip>
                      </span>
                      <span
                        style={{ paddingLeft: '20px' }}
                        onClick={() => downloadCertificates()}
                        data-testid="Download"
                        className={!isDeviceSelected ? "disabled" : ""}
                      >
                        <IconWithTooltip
                          tooltipText="Download Certificates"
                          icon="bi bi-arrow-down"
                          test_id='download'
                          iconColor={isDeviceSelected ? "white" : "grey"}
                          tooltipPosition={TooltipPosition.Bottom}

                        ></IconWithTooltip>
                      </span>
                    </>

                  )}

                <span
                  style={{ paddingLeft: '20px' }}
                  onClick={() => reload()}
                  data-testid="Reload"
                >
                  <IconWithTooltip
                    tooltipText="Reload"
                    icon="bi bi-arrow-clockwise"
                    tooltipPosition={TooltipPosition.Bottom}
                  ></IconWithTooltip>
                </span>
                <span
                  style={{ paddingLeft: '20px' }}
                  onClick={() => downloadCsv()}
                  data-testid="Export"
                  className={!isDeviceSelected ? "disabled" : ""}
                >
                  <IconWithTooltip
                    tooltipText="Export device attributes"
                    test_id='exportCsv'
                    icon="bi bi-file-earmark-spreadsheet"
                    iconColor={isDeviceSelected ? "white" : "grey"}
                    tooltipPosition={TooltipPosition.Bottom}
                  ></IconWithTooltip>
                </span>
              </div>
            </div>
          </div>
          <div
            className="card-body card-body-padding mt-2"
            data-testid="content"
            style={{ backgroundColor: 'black', paddingLeft: '55px' }}
          >
            <form onSubmit={onSubmit}>
              <div className="row mt-4">
                <div className="col-sm-3">
                  <SearchBar
                    placeholder="SerialNumber"
                    value={serialNumber.current}
                    onTextChange={(e) => {
                      serialNumber.current = e;
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <SearchBar
                    placeholder="ProvisioningId"
                    value={provisioningId.current}
                    onTextChange={(e) => {
                      provisioningId.current = e;
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <DropDown
                    id="provisioningStatus"
                    selectedItem={provisioningStatus.current}
                    items={provisioningStatusTypeOptions}
                    onSelectionChange={(e) => {
                      provisioningStatus.current = e.codeData;
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <DropDown
                    id="isCertificateAvailable"
                    selectedItem={certificateAvailable.current}
                    items={certificateAvailableTypeOptions}
                    onSelectionChange={(e) => {
                      certificateAvailable.current = e.codeData;
                    }}
                  />
                </div>
              </div>
              <div className='row mt-4'>
                <div className="col-sm-3">
                  <DropDown
                    id="enrolmentGroup"
                    selectedItem={enrollmentGroup.current}
                    items={groupNameTypeOptions}
                    onSelectionChange={(e) => {
                      enrollmentGroup.current = e.codeData;
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <button className="btn-prim" onClick={handleSubmit}>
                    Search
                  </button>
                </div>
              </div>
            </form>

            <div style={{paddingLeft:'50px'}}>
              <PaginationButtons 
                    hideNext={paginationDetails?.hideNextButton} 
                    hidePrevious={paginationDetails?.hidePrevButton} 
                    getNextPageDetails = {getPageDetails}
                    getPreviousPageDetails = {getPreviousPageDetails}
                    />
            </div>
            <div className="row pb-4">
              {enrollmentGroupsLoading && <Loader />}
              {paginationLoading && <Loader />}
              {enrollmentLoading && <Loader />}
              {enrollmentGroupsError && <ErrorMessage message={enrollmentGroupsError} />}
              {paginationError && <ErrorMessage message={paginationError} />}
              {!validateSearchParams() ? <ErrorMessage message="Please enter search filter" /> :
                paginationDetails?.data.length === 0 ? (
                  <ErrorMessage message="No device details are found" />
                ) : (
                  <div className={`${tableStyles.fixTableHead} col-md-12`} style={{ height: '52.5vh' }}>
                    <table style={{ width: '100%' }}>
                      <thead className={`${tableStyles.tableHead}`}>
                        <tr key={'header'}>
                          <th className={tableStyles.heading}>
                            <input
                              id="selectAll"
                              data-testid="selectAll"
                              type="checkbox"
                              checked={isCheckAll}
                              onChange={(e) => selectAll(e.target.checked)}
                            />
                          </th>
                          <th className={tableStyles.heading}>Serial Number</th>
                          <th className={tableStyles.heading}>Provisioning Id</th>
                          <th className={tableStyles.heading}>
                            Provisioning Status
                          </th>
                          <th className={tableStyles.heading}>
                            CertificateAvailable
                          </th>
                          <th className={tableStyles.heading}>
                            Certificate Created Time
                          </th>
                          <th className={tableStyles.heading}>Group Name</th>
                          <th className={tableStyles.heading}>Created By</th>
                        </tr>
                      </thead>
                      {
                        <tbody className='mb-2'>
                          {paginationDetails &&
                            paginationDetails.data.map((item, index) => (
                              <tr key={index}>
                                <td className={tableStyles.data}>
                                  <input
                                    id={item.provisioningId}
                                    data-testid={item.provisioningId}
                                    type="checkbox"
                                    checked={item.isSelected || false}
                                    onChange={(e) => select(item, e.target.checked)}
                                  />
                                </td>
                                <td className={tableStyles.data}>
                                  {item.serialNumber}
                                </td>
                                <td className={tableStyles.data}>
                                  {item.provisioningId}
                                </td>
                                <td className={tableStyles.data}>
                                  {item.provisioningStatus}
                                </td>
                                <td className={tableStyles.data}>
                                  {item.isCertificateAvailable && <div style={{ color: 'green' }}>✔</div>}
                                  {!item.isCertificateAvailable && <div style={{ color: 'red' }}>✘</div>}
                                </td>
                                <td className={tableStyles.data}>
                                  {item.certificateCreatedTime}
                                </td>
                                <td className={tableStyles.data}>
                                  {item.enrollmentGroup}
                                </td>
                                <td className={tableStyles.data}>
                                  {item.createdBy}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      }
                    </table>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};
export default DeviceExplorer;
