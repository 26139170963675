import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './TopNavBar.css';
import image from 'src/images/logo.png';
import User from '../User/User';
import SubNavBar, { ISubNavbarItemList } from '../SubNavBar/SubNavBar';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthConfig } from 'src/AuthConfig';
import Landing from '../Landing/Landing';
import { InteractionStatus } from '@azure/msal-browser';
import { ITopnavbarItemList } from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { Config } from 'src/Utils/ConfigService/LoadConfig';

const TopNavBar: React.FC<{topNavList:ITopnavbarItemList, subNavList: ISubNavbarItemList }> = (navItemsList) => {
  const [navList, setList] = useState(navItemsList);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const [dropdown, setDropdown] = useState(false);
  const parent = useRef<string>('');
  const [click, setClick] = useState(false);

  const navigate = useNavigate();
  const handleClick = () => {
    setClick(!click);
    setDropdown(false);
    parent.current = '';
  };
  const handleSubNav = useCallback((name: string) => {
    setDropdown(true);
    parent.current = name;
  }, []);

  useEffect(() => {
    setList(navItemsList);
  },[navItemsList]);

  useEffect(() => {
    let location = window.location.href;
    let baseUrl = Config.GetConfigData().authConfig.redirectUri;

    if(baseUrl === location || (!isAuthenticated)){
      return;
    }
    let pathName = window.location.pathname;

    // Find the route path 
    let topNavRoutes = navList.topNavList.items.map(x => x.routePath);
    let subNavRoutes = navList.subNavList.items.map(x => x.path);


    let topNavFound =  topNavRoutes.find(x => pathName === x)
    let subNavFound =  subNavRoutes.find(x => pathName === x)

    //Verify the user has access to the path
    if(topNavFound === undefined && subNavFound === undefined){
      navigate('/contact-admin', {state:pathName})
      setDropdown(false);
    }
    else{
      let navigateUrl = location.substring(baseUrl.length);
      navigate(navigateUrl)
    }
   

  }, [navList, navigate, isAuthenticated])


  useEffect(() => {
    let location = window.location.href;
    if (location.includes('/device')) {
      handleSubNav('device');
    } else if (location.includes('/fleet')) {
      handleSubNav('fleet');
    }else if (location.includes('/provisioning')) {
      handleSubNav('provisioning');
    }
    return () => {};
  }, [handleSubNav]);


  return (
    <>
      <div className="main-navbar">
        <div className="logo">
          <img src={image} alt="BLK" />
          <div className="title">BLK SUPPORT PORTAL</div>
        </div>
        {isAuthenticated ? (
          <>
            <div className="nav-container">
            
              <ul className="nav-menu" data-testid="TopNavBar">
              {
                navItemsList.topNavList.items.map((item,idx) => (
                  <li className="nav-item" key={item.name}>
                    <NavLink
                      data-testid={item.testid}
                      to={item.routePath}
                      className={idx !== (navItemsList.topNavList.items.length -1) ? "nav-links verticalLine" : 'nav-links'}
                      onClick={() => {         
                        setClick(!click);
                        parent.current = item.id;
                        setDropdown(item.hasChildren)
                        
                      }}
                    >
                       <label
                          style={
                            item.id !== '' && parent.current === item.id
                              ? {
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                  fontSize: '1.3vw',
                                }
                              : { cursor: 'pointer' }
                          }
                      >
                          {item.name}
                       </label>
                    </NavLink>
                </li>
                ))
              }
              </ul>
             
              <div className="nav-icon" onClick={handleClick}>
                <i className={click ? 'bi bi-x' : 'bi bi-list'}></i>
              </div>
            </div>
            <div className="user-container">
              <User />
            </div>
          </>
        ) : (
          <div style={{ paddingRight: '5vw' }}>
            <button
              data-testid="lgn-btn"
              className="btn-login"
              onClick={() => {
                AuthConfig.signIn();
              }
            }
            >
              Log In
            </button>
          </div>
        )}
      </div>
      {isAuthenticated && dropdown && (
        <SubNavBar
          menuItems={navItemsList.subNavList.items.filter((item) => {
            return item.parent === parent.current;
          })}
        />
      )}
      {!isAuthenticated && inProgress === InteractionStatus.None && <Landing />}
    </>
  );
};
export default React.memo(TopNavBar);
