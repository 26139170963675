import React, { useCallback, useState } from 'react';
import Modal from 'src/CssModules/modal.module.css';
import './InfoDialog.css';
export interface IDialogParams {
    title: string
    children: React.ReactNode,
    onClose: () => void,
    headerAlign?:any
    dialogWidth?:string
}
const InfoDialog: React.FC<IDialogParams> = ({ title, children, onClose, headerAlign = 'left', dialogWidth='40%' }) => {
    const [display, setdisplay] = useState('block');
    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);
    return (
        <div>
            <div id="myModal" className={Modal.modal} style={{ display: display, zIndex: 2}}>
                <div className={Modal.content} style={{width:dialogWidth }}>
                    <div className={Modal.header} style={{textAlign: headerAlign}}>
                        <p>{title}</p>
                    </div>
                    <hr style={{ color: 'white' }} />
                    <div className={Modal.body}>
                        {children}
                    </div>
                    <hr style={{ color: 'white' }} />
                    <div className={Modal.footer}>
                        <div className="row">
                            <div className="col-sm-3 ms-auto close-btn">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => close()}
                                    data-testid="close"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InfoDialog;