import { useState } from "react";
import './Accordian.css';
interface IAccordion {
    header: string;
    children: React.ReactNode;
  }
const Accordion:React.FC<IAccordion> = ({ header, children }) => {
  const [rotateIcon, setRotate] = useState(false);
  const handleRotate = () => {setRotate(!rotateIcon)};

  const cl = rotateIcon? "fa fa-chevron-right rotate down" :"fa fa-chevron-right rotate";
   return(
    <>
      <div className="mt-2" onClick={handleRotate} style={{cursor:'pointer'}} data-testid="iconWithHeader">
        <h3 style={{color:'white'}} data-testid="header" >
            <i 
                style={{color:'white', marginRight: '20px', paddingTop:'6px', marginTop:'10px'}} 
                className={cl} 
                data-testid="icon">
            </i>
                    {header}
            </h3>
      </div>
      {rotateIcon && 
      <div style={{marginLeft:'40px'}} data-testid="content">
         {children}
      </div>
      }

    </>
   )
}
export default Accordion;