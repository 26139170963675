interface IProgressBarParams {
    progress: number;
}

const ProgressBar: React.FC<IProgressBarParams> = ({ progress }) => {
    const clampedPercent = Math.min(100, Math.max(0, progress));
    return (
        <>
            <div style={{ color: 'white', paddingLeft: '50%' }}>{clampedPercent}%</div>
            <div className='progress' style={{height: '1vw'}}>
            <div
                className="progress-bar bg-success"
                role="progressbar"
                aria-valuenow={clampedPercent}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{width: `${clampedPercent}%`}}
                data-testid = 'progressbar'
            >
            </div>
        </div>
        </>
        
    );
};

export default ProgressBar;
