import React from 'react';
import './Card.css';
interface ICardData {
  header: string;
  children: React.ReactNode;
}
const Card: React.FC<ICardData> = ({ header, children }) => {
  return (
    <div className="card" style={{ position: 'inherit' }}>
      <div
        className="card-header"
        style={{
          color: 'white',
          backgroundColor: 'black',
          fontSize: '1.14vw',
          paddingLeft: '40px',
          borderBottomColor: 'white',
        }}
        data-testid="header"
      >
        {header}
      </div>
      <div
        className="card-body card-body-padding"
        data-testid="content"
        style={{ backgroundColor: 'black', height: '60vh' }}
      >
        {children}
      </div>
    </div>
  );
};
export default Card;
