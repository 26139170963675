import { useEffect } from 'react';

/**
 *
 * @param {*} ref - Ref of your parent div
 * @param {*} callback - Callback which can be used to change your maintained state in your component
 */

const useOutsideClick = (ref: HTMLDivElement|null, callback: () => void ) => {
    useEffect(() => {
        const handleClickOutside = (evt:any) => {
            if (ref && !ref.contains(evt.target)) {
                callback(); 
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};

export default useOutsideClick;