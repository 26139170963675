import axios from 'axios';
import { useCallback, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { IDeviceDetails, IDisplayDeviceDetails } from '../DeviceSearch/UseDeviceSearchDetails';
export interface IDeviceEnrolmentRequest {
  EnrollmentGroupName: string;
  SerialNoFrom: string;
  SerialNoTo: string;
}
export interface IDevicePostRequest extends IDisplayDeviceDetails {
  enrolmentGroupCommonName: string;
}
export const useDevice = () => {
  const [enrollmentMessage, setEnrollmentMessage] = useState<string>('');
  const [enrollmentStatus, setEnrollmentStatus] = useState<string>('');
  const [enrollmentLoading, setEnrollmentLoading] = useState<boolean>(false);
  const scope = Config.GetConfigData().provisioningService?.scopes[0];
  const baseUrl = Config.GetConfigData().serviceUrl;
  const deviceEnrolmentUrl = 'provisioning/enrolment/devices';
  const getToken = (scope: any) =>
    new Promise((resolve, reject) => {
      if (scope === undefined) {
        setEnrollmentMessage('Invalid scope');
        setEnrollmentStatus('Error');
        setEnrollmentLoading(false);
      } else {
        AuthConfig.getToken(scope).then(
          (success) => {
            resolve(success);
          },
          (error) => {
            setEnrollmentMessage(error);
            setEnrollmentStatus('Error');
            setEnrollmentLoading(false);
            reject(error);
          }
        );
      }
    });
  const validateRange = useCallback(
    (request: IDeviceEnrolmentRequest) => {
      setEnrollmentLoading(true);
      getToken(scope).then((token: any) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        logger.logInformation(
          `Access token is  Received successfully  for scope :  ${scope} + 'and requesting enrollment validation`
        );
        axios
          .get<any>(`${baseUrl}${deviceEnrolmentUrl}/validate-range`, {
            params: {
              GroupName: request.EnrollmentGroupName,
              FromSerialNumber: request.SerialNoFrom,
              ToSerialNumber: request.SerialNoTo,
            },
            headers,
          })
          .then(
            (response) => {
              setEnrollmentMessage(response.data.message);
              setEnrollmentStatus(response.data.validationStatus);
            },
            (error) => {
              logger.logError(
                'Failed to get response for validation request: ' +
                  'error: ' +
                  JSON.stringify(error)
              );
              if (error.response !== undefined) {
                setEnrollmentMessage(error.response.data.Message);
                setEnrollmentStatus('Error');
              }
            }
          )
          .finally(() => {
            setEnrollmentLoading(false);
          });
      });
    },
    [baseUrl, scope]
  );
  const addDevice = useCallback(
    (request: IDeviceEnrolmentRequest) => {
      const data: IDevicePostRequest[] = [];
      for (var i = +request.SerialNoFrom; i <= +request.SerialNoTo; i++) {
        var deviceEntity: IDevicePostRequest = {
          enrolmentGroupCommonName: request.EnrollmentGroupName,
          enrollmentGroup: request.EnrollmentGroupName,
          isCertificateAvailable: false,
          provisioningId: '',
          provisioningStatus: 'ENROLLED',
          serialNumber: i.toString(),
        };
        data.push(deviceEntity);
      }
      setEnrollmentLoading(true);
      getToken(scope).then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        logger.logInformation(
          `Access token is  Received successfully  for scope :  ${scope} + 'and adding devices`
        );
        axios
          .post(`${baseUrl}${deviceEnrolmentUrl}`, data, { headers })
          .then(
            (response) => {
              setEnrollmentMessage(response.data.message);
              setEnrollmentStatus(response.data.validationStatus);
            },
            (error) => {
              logger.logError(
                `Failed to add devices: + error:  + ${JSON.stringify(error)}`
              );
              if (error.response !== undefined) {
                setEnrollmentMessage(error.response.data.Message);
                setEnrollmentStatus('Error');
              }
            }
          )
          .finally(() => {
            setEnrollmentLoading(false);
          });
      });
    },
    [baseUrl, scope]
  );
  const downloadDevice = (selectedDeviceDetails: IDeviceDetails[]) => {
    setEnrollmentMessage('');
    setEnrollmentLoading(true);
    getToken(scope).then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
  
        logger.logInformation(
          `Access token is  Received successfully  for scope :  ${scope} + 'and downloading devices`
        );
        axios
          .post(`${baseUrl}${deviceEnrolmentUrl}/certificates/download`, selectedDeviceDetails, {
            headers,
            responseType: 'blob' as 'blob',
          })
          .then(
            (response) => {
              const FileSaver = require('file-saver');
              const zipFile = `device-certificates.zip`;
              FileSaver.saveAs(response.data, zipFile);
              setEnrollmentMessage('Certificates download successfull');
              setEnrollmentStatus('Success');
            },
            (error) => {
              logger.logError(
                `Failed to add devices: + error:  + ${JSON.stringify(error)}`
              );
              if (error.response !== undefined) {
                setEnrollmentMessage(`${error.response.statusText} Please contact admin.`);
                setEnrollmentStatus('Error');
              }
            }
          )
          .finally(() => {
            setEnrollmentLoading(false);
          });
      });
    
  };
  return {
    enrollmentMessage,
    enrollmentStatus,
    enrollmentLoading,
    validateRange,
    addDevice,
    downloadDevice
  };
};
