import ReactDOM from 'react-dom';
import "./license-loader";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import App from './App';
import { AuthConfig } from './AuthConfig';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Provider } from 'react-redux';
import { store } from '../src/Redux/store';

AuthConfig.getMsalInstance().addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    AuthConfig.getMsalInstance().setActiveAccount(account);
  }
});


const accounts = AuthConfig.getMsalInstance().getAllAccounts();

if (accounts.length > 0) {
  AuthConfig.getMsalInstance().setActiveAccount(accounts[0]);
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.querySelector('#root')
);
