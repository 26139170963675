import React from 'react';
import { ISizeOptions } from 'src/Utils/Interfaces/ISizeOptions';
import './DeviceStatusItem.css';
interface IDeviceStatusItem extends ISizeOptions {
  title: string;
  color: string;
  data: string;
  icon: string;
  description?: string;
}
const DeviceStatusItem: React.FC<IDeviceStatusItem> = ({
  title,
  color,
  data,
  icon,
  description,
  xl = 12,
  lg = 12,
  md = 12,
  sm = 12,
  xs = 12,
}) => {
  icon = `${icon} fa-2x text-gray-300`;
  let className = `form-group col-xl-${xl} col-lg-${lg} col-md-${md} col-sm-${sm} col-${xs} mb-2`;
  return (
    <div className={className} data-testid="classesId">
      <div
        className="card"
        style={{
          borderLeft: '.5rem solid',
          borderLeftColor: color,
          width: '80%',
          position: 'inherit',
        }}
        data-testid="cardId"
      >
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col mr-2 card-d">
              <div
                className="text-xs font-weight-bold text-primary text-uppercase mb-1"
                style={{ fontWeight: 550 }}
              >
                {title}
              </div>
              <div
                className="h5 mb-0"
                style={{ fontWeight: 550, fontSize: '18px' }}
              >
                {data}
              </div>
            </div>
            <div className="col-auto mb-2">
              <i className={icon} data-testid="iconId"></i>
            </div>
            {description && (
              <div>
                <hr />
                <div className="h6 mb-0  text-gray-800" data-testid="stateId">
                  {description}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeviceStatusItem;
