import React from 'react';
import './SubNavBar.css';
import { NavLink } from 'react-router-dom';
export interface SubNavItems {
  title: string;
  path: string;
  parent: string;
}
export interface ISubNavbarItemList {
  items: SubNavItems[]
}
const SubNavBar: React.FC<{ menuItems: SubNavItems[] }> = ({ menuItems }) => {
  return (
    <div className="sub-navbar">
      <ul className="nav-menu" data-testid="SubNavBar">
        {menuItems.map((item, index) => {
          return (
            <li className="nav-item" key={index}>
              <NavLink className={`nav-links ${index !== menuItems.length - 1 ? 'verticalLine' : ''}`} 
                 to={item.path}>
                {item.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubNavBar;
