import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export interface IUploadLogFile {
    logFile: File | undefined;
    deviceType: string;
    logType: string;
};
export const useUploadLogFile = (uploadLogFileRequest: IUploadLogFile) => {
    const [uploadLogLoading, setUploadLogLoading] = useState<boolean>(true);
    const [uploadLogError, setUploadLogError] = useState<string>('');
    const [uploadLogResponse, setUploadLogResponse] = useState<boolean>(false);
    useEffect(() => {
        setUploadLogLoading(true);
        const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
        const baseUrl = Config.GetConfigData().serviceUrl;
        const logTypes = ['app', 'device'];
        const logType = logTypes[Number(uploadLogFileRequest.logType)];
        uploadLogFileRequest.logType = logType;
        if (scope === undefined) {
            setUploadLogError('Invalid scope');
            setUploadLogLoading(false);
        }
        else if (uploadLogFileRequest.logFile === undefined || uploadLogFileRequest.logType === '' || uploadLogFileRequest.deviceType === '') {
            setUploadLogLoading(false);
        }
        else {
            AuthConfig.getToken(scope).then(
                (success) => {
                    const headers = {
                        Authorization: `Bearer ${success}`
                    };
                    logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and requesting upload log file`);
                    const formData = new FormData();
                    formData.append('deviceType', uploadLogFileRequest.deviceType);
                    if(uploadLogFileRequest.logFile){
                        formData.append('file', uploadLogFileRequest.logFile);
                    }
                    formData.append('logType', uploadLogFileRequest.logType)
                    axios.post(`${baseUrl}device/logs`, formData, { headers })
                        .then(
                            (response) => {
                                setUploadLogResponse(true);
                                setUploadLogError('');
                            },
                            (error) => {
                                setUploadLogResponse(false);
                                let errorMessage = error;
                                if (error.response !== undefined) {
                                    errorMessage = error.response.data.Message;
                                }
                                logger.logError('Failed to upload log file for given request: ' + uploadLogFileRequest + 'error: ' + JSON.stringify(errorMessage));
                                setUploadLogError(errorMessage);
                            }
                        )
                        .finally(() => {
                            setUploadLogLoading(false);
                        });
                },
                (error) => {
                    setUploadLogError(error);
                    setUploadLogResponse(false);
                    setUploadLogLoading(false);
                }
            );
        }
    }, [uploadLogFileRequest]);
    return { uploadLogResponse, uploadLogError, uploadLogLoading };
}

