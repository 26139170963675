import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IconWithText from 'src/Components/IconWithText/IconWithText';
import InfoBar, { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import { IReleaseNotesRequest, ReleaseNotesType, useReleaseNotesUpload } from 'src/Utils/Hooks/FirmwareRelease/ReleaseNotes/useReleaseNotesUpload';
import { IReleaseEntry } from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import { useGetValidateRoles, UserRoles } from 'src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles';
import HtmlEditor from '../HtmlEditor/HtmlEditor';
import ReleaseNotesAttachments from '../ReleaseNotesAttachments/ReleaseNotesAttachments';
interface IReleaseNotes {
    releaseEntry: IReleaseEntry,
    releaseType: string
    updateList: (list: IReleaseEntry[]) => void;
    updateEditReleaseLoader: (load: boolean) => void
}

const ReleaseNotes: React.FC<IReleaseNotes> = ({ releaseEntry, releaseType, updateList, updateEditReleaseLoader }) => {

    const [file, setFile] = useState<File>()

    const { fileUploadLoading, fileUploadError, releaseList, uploadReleaseNotes, deleteReleaseNotes } = useReleaseNotesUpload();
    const [toggleInfoBar, setToggleInfoBar] = useState<boolean>(false);
    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    var { validateUser } = useGetValidateRoles();
    var isEditorUser = useMemo(() => validateUser([UserRoles.FirmwareReleaseEditor]), [validateUser]);


    const onUpload = () => {
        let request: IReleaseNotesRequest = {
            release: releaseEntry,
            releaseType: releaseType,
            releaseNotesType: ReleaseNotesType.Attachments,
            file: file
        }
        uploadReleaseNotes(request);
    }

    const showStatusBar = useCallback((message: string, messageType: InfoMessageType) => {
        msg.current = message;
        msgType.current = messageType
        setToggleInfoBar(true);

    }, [])

    useEffect(() => {
        if (releaseList.length > 0) {
            updateList(releaseList);
            showStatusBar("Release notes attachments updated successfully.", InfoMessageType.success)
        }
    }, [releaseList, updateList, showStatusBar])



    useEffect(() => {
        if (fileUploadError !== '') {
            showStatusBar(fileUploadError, InfoMessageType.error);
        }
    }, [fileUploadError, showStatusBar])
    useEffect(() => {
        updateEditReleaseLoader(fileUploadLoading)
    }, [fileUploadLoading, updateEditReleaseLoader]);


    return (
        <>

            {toggleInfoBar && msg.current !== undefined && (
                <div className="row" style={{ marginLeft: '10px', marginRight: '100px', width: '50%' }}>
                    <InfoBar
                        Message={msg.current}
                        MessageType={msgType.current}
                        closeClick={() => {
                            setToggleInfoBar(false);
                        }}
                    />
                </div>
            )}
            <div style={{ marginLeft: '10px' }} >

                <div className="row pb-3">
                    <IconWithText
                        icon="bi bi-info-circle-fill"
                        test_id="notes-info"
                        iconColor="white"
                        textColor="white"
                    >
                        <p>There are different ways to add release notes, they can be either uploaded as a file (e.g. pdf) or directly edited here in the HTML editor.</p>
                    </IconWithText>
                </div>
                <div className='row'>
                    <p style={{ color: 'white', paddingLeft: '40px' }}>Note: Only one link can be set as the current release notes. Using the HTML editor will automatically set those as the release notes. Same goes for uploading a new file.</p>
                </div>
                <div data-testid='html_window'>
                    <HtmlEditor
                        releaseEntry={releaseEntry}
                        deviceType={releaseType}
                        updateList={updateList}
                        updateEditReleaseLoader={updateEditReleaseLoader}
                        setHtmlEditorInfo={showStatusBar}
                        isEditor={isEditorUser}
                    />
                </div>

                <div className='row mt-5' data-testid='attachment_window'>
                    <div className='col-md-3'>
                        <input className="form-control "
                            disabled={!isEditorUser}
                            type="file"
                            id="formFile"
                            accept=".pdf"
                            data-testid='release_attachment'
                            onChange={(event) => {
                                if (event.target.files != null) {
                                    setFile(event.target.files[0]);
                                }
                            }}
                        />
                    </div>

                    <div className="col-md-3 mb-3">
                        <button className="btn-prim"
                            onClick={onUpload}
                            data-testid="upload"
                            disabled={file === undefined || !isEditorUser}
                            style={{ marginLeft: '-20px' }}>
                            Upload
                        </button>
                    </div>
                    <ReleaseNotesAttachments
                        isEditor={isEditorUser}
                        deleteReleaseNotes={deleteReleaseNotes}
                        releaseEntry={releaseEntry}
                        releaseType={releaseType}
                        showStatusBar={showStatusBar}
                        updateEditReleaseLoader={updateEditReleaseLoader}
                        updateList={updateList}
                    />

                    <div className="row mt-3">
                        <IconWithText
                            icon="bi bi-info-circle-fill"
                            test_id="notes-info"
                            iconColor="white"
                            textColor="white"
                        >
                            <p>Current URL: <a href={releaseEntry.changelog_url} style={{ color: 'white' }} target="_blank" rel="noreferrer">{releaseEntry.changelog_url}</a></p>
                        </IconWithText>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ReleaseNotes;