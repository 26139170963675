import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextForm from '../FormTextBox/TextForm';
import DropDown, { IOptions } from '../DropDown/DropDown';
import { useGetEnrollmentGroups } from 'src/Utils/Hooks/EnrollmentGroupService/useGetEnrollmentGroups';
import { IDeviceEnrolmentRequest } from 'src/Utils/Hooks/DeviceEnrollment/useDevice';
import {
  useGetValidateRoles,
  UserRoles,
} from 'src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles';

export interface AddDeviceParams {
  addEnrollmentRequest: IDeviceEnrolmentRequest;
  addDeviceLoading: (loading: boolean) => void;
  addDeviceError: (error: string) => void;
  addDeviceData: (data: IDeviceEnrolmentRequest) => void;
  isNext: (isEnable: boolean) => void;
}

const AddDevice: React.FC<AddDeviceParams> = ({
  addEnrollmentRequest,
  addDeviceError,
  addDeviceLoading,
  addDeviceData,
  isNext,
}) => {
  var { validateUser } = useGetValidateRoles();
  const [groups, setGroups] = useState<IOptions[]>([]);
  const { enrollmentGroups, enrollmentGroupsError, enrollmentGroupsLoading } =
    useGetEnrollmentGroups(true);

  const [enableNext, setEnableNext] = useState(false);
  const refRequest = useRef<IDeviceEnrolmentRequest>(addEnrollmentRequest);
  const refAdmin = useRef<boolean>(
    validateUser([UserRoles.ProductionDeviceAdmin])
  );
  const verifyNextEnable = useCallback(() => {
    if (
      refRequest.current.EnrollmentGroupName !== '' && refRequest.current.EnrollmentGroupName !== 'SELECT' &&
      refRequest.current.SerialNoFrom !== '' &&
      !isNaN(+refRequest.current.SerialNoFrom) &&
      refRequest.current.SerialNoTo !== '' &&
      !isNaN(+refRequest.current.SerialNoTo)
    ) {
      setEnableNext(true);
    }
    else{
      setEnableNext(false);
    }

  }, []);

  useEffect(() => {
    let productionGroups = refAdmin.current
      ? enrollmentGroups
      : enrollmentGroups.filter((obj) => {
          return obj.isProduction === true;
        });
    const enrollmentGroupOptions: IOptions[] = [];
    enrollmentGroupOptions.push({
      displayData: 'SELECT',
      codeData: 'SELECT'
      })
    enrollmentGroupOptions.push(...productionGroups.map(
      (item, index) => {
          return { displayData: item.groupName, codeData: item.groupName };
      }));
    setGroups(enrollmentGroupOptions);
  }, [enrollmentGroups, refAdmin]);

  useEffect(() => {
    addDeviceLoading(enrollmentGroupsLoading);
  }, [enrollmentGroupsLoading, addDeviceLoading]);
  useEffect(() => {
    addDeviceError(enrollmentGroupsError);
  }, [enrollmentGroupsError, addDeviceError]);

  useEffect(() => {
    verifyNextEnable();
    isNext(enableNext);
    addDeviceData(refRequest.current);
  }, [enableNext, isNext, verifyNextEnable, addDeviceData]);

  return (
    <>
      <div className="form-group row mb-3">
        <label
          className="col-sm-4 col-form-label"
          style={{ color: 'white', paddingLeft: '10px' }}
        >
          Enrollment Group
        </label>
        <div className="col-md-7">
          <DropDown
            selectedItem={
              refRequest.current.EnrollmentGroupName !== ''
                ? refRequest.current.EnrollmentGroupName
                : groups.length > 0
                ? groups[0].codeData
                : ''
            }
            items={groups.length > 0 ? groups : []}
            onSelectionChange={(val) => {
              refRequest.current.EnrollmentGroupName = val.codeData;
              verifyNextEnable();
            }}
          />
        </div>
        {/* )} */}
      </div>
      <TextForm
        lableName="Serial Number From"
        placeholder="Enter first Serial Number"
        testid="SerialNumberFrom"
        onTextChange={(val) => {
          refRequest.current.SerialNoFrom = val;
          verifyNextEnable();
        }}
        value={addEnrollmentRequest.SerialNoFrom}
      />
      <TextForm
        lableName="Serial Number To"
        placeholder="Enter last Serial Number"
        testid="SerialNumberTo"
        onTextChange={(val) => {
          refRequest.current.SerialNoTo = val;
          verifyNextEnable();
        }}
        value={addEnrollmentRequest.SerialNoTo}
      />
    </>
  );
};

export default AddDevice;
