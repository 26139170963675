import React, { useCallback, useEffect } from 'react'
import IconWithText from 'src/Components/IconWithText/IconWithText';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import Loader from 'src/Components/Loader';
import { useCdnInvalidate } from 'src/Utils/Hooks/FirmwareRelease/CdnInvalidate/useCdnInvalidate';

export interface ICdnParams {
    releaseType: string;
    disable: boolean;
    onStatus: (message: string, messageType: InfoMessageType) => void;
    updateLoader: (load: boolean) => void;
}
const CdnInvalidate: React.FC<ICdnParams> = ({ releaseType, disable, onStatus, updateLoader }) => {
    const {
        cdnInvalidateResponse,
        cdnInvalidateError,
        cdnInvalidateLoading,
        forceCdnInvalidate,
    } = useCdnInvalidate();
    const onSynchronize = useCallback(() => {
        forceCdnInvalidate(releaseType);
    }, [forceCdnInvalidate, releaseType]);
    useEffect(() => {
        if (cdnInvalidateResponse) {
            onStatus('Forced cdn successfully', InfoMessageType.success);
        }
    }, [cdnInvalidateResponse, onStatus])
    useEffect(() => {
        if (cdnInvalidateError !== '') {
            onStatus(cdnInvalidateError, InfoMessageType.error);
        }
    }, [cdnInvalidateError, onStatus])

    useEffect(() => {
        updateLoader(cdnInvalidateLoading)
    }, [cdnInvalidateLoading, updateLoader])
    return (
        <>
            {cdnInvalidateLoading && <Loader />}
            <div className="row" style={{ color: '#575757', fontSize: "0.8vw" }}>
                <div className="col-sm-10 pt-2">
                    <IconWithText
                        icon='bi bi-info-circle-fill'
                        iconColor='white'
                        textColor='grey' >
                             <p>If releases are not synchronized across regions, use this button to force synchronize it. Use this option if you are observing delay, otherwise it may cause adverse effect in synchronization.</p>
                        </IconWithText>
                </div>
               
                <div className="col-sm-2">
                    <button className="btn-prim" onClick={onSynchronize} data-testid='synchronize' disabled={disable} style={{ width: '8vw', marginLeft: '6.7vw' }}>
                        Synchronize
                    </button>
                </div>
            </div>
        </>
    )
}
export default CdnInvalidate