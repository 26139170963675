import { useEffect, useRef, useState } from "react";
import { IOptions } from "src/Components/DropDown/DropDown";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";

interface IWorkSheets {
    options: IOptions;
    show: string;
  }
  
  interface IWorkbook {
    name: string;
    workSheets: IWorkSheets[];
  }
  interface IDashboard {
    deviceType: string;
    workBook: IWorkbook[];
  }
  interface ITelemetry {
    hostUrl: string;
    dashboards: IDashboard[];
  }
  interface ISelectedItem {
    workBookName: string;
    WorkSheetName: string;
  }
  let defaultProps: ISelectedItem = {
    WorkSheetName: '',
    workBookName: '',
  };
export const useTelemetry = (deviceType:string, selectedView: string) => {
    const [viewOptions, setViewOptions] = useState<IOptions[]>([]);

    // defaultProps.WorkSheetName = selectedView;
    const [details, setDetails] = useState<ISelectedItem>(defaultProps);

    // Dashboard for device type
    const dashBoard = useRef<IDashboard>({
        deviceType: deviceType,
        workBook: [],
      });

    // // Host Url for tableau server
    const hostUrl = useRef<string>('');

    const GetFilterDetails = (view: string): ISelectedItem => {
        logger.logInformation(
          'Get Selected item Properties for given view:' + view
        );
        let prop: ISelectedItem = defaultProps;
        if (dashBoard.current.workBook.length > 0) {
          let workBooks = dashBoard.current.workBook;
          workBooks.forEach((workBook) => {
            let workSheets = workBook.workSheets.filter(
              (x) => x.options.codeData === view
            );
            if (workSheets.length > 0) {
              prop = {
                WorkSheetName: workSheets[0].options.codeData,
                workBookName: workBook.name,
              };
              return prop;
            }
          });
        }
        logger.logWarning(
          'No Selected item properties found returning default props'
        );
        return prop;
      };

    useEffect(() => {
        const GetTableauViewOptions = (): IOptions[] => {
          logger.logInformation(
            'Readidng tableau configuration for given device type: ' + deviceType
          );
          const telemetry: ITelemetry = Config.GetConfigData().Telemetry;
          let deviceDashboards = telemetry.dashboards.filter(
            (x) => x.deviceType === deviceType
          );
          if (deviceDashboards.length > 0) {
            dashBoard.current = deviceDashboards[0];
            hostUrl.current = telemetry.hostUrl;
            let workBooks = deviceDashboards[0].workBook;
            let allWorkShets = workBooks.map((x) => x.workSheets);
            let viewList = allWorkShets.reduce((val, el) => val.concat(el));
            viewList = viewList.filter((x) => Boolean(JSON.parse(x.show) === true));
            let viewOpts = viewList.map((x) => x.options);
            return viewOpts;
          } else {
            logger.logInformation(
              'Could not find dashboards for given device type: ' + deviceType
            );
            return [];
          }
        };
        let viewOpts = GetTableauViewOptions();
        if (viewOpts.length > 0) {
          setViewOptions(viewOpts);
        } else {
          setViewOptions([]);
        }
      }, [deviceType]);

    useEffect(() => {
      if(viewOptions.length > 0) {
          let view = selectedView !== '' ? selectedView : viewOptions[0].codeData
          if(view !== details.WorkSheetName) {
              setDetails(GetFilterDetails(view) );
          }
      }   
    }, [viewOptions, details.WorkSheetName, selectedView]);

    return {viewOptions, details, hostUrl};
}