import axios from 'axios';
import { useEffect, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import {
  UpdateState,
  DeviceState,
  // selectDevice,
  // deviceInitialState,
} from 'src/Redux/genericSlice';
import {
  useAppDispatch,
  //useAppSelector
} from 'src/Redux/hooks';

export interface IRequestQuery {
  SerialNumber?: string;
  DeviceType?: string;
}
export interface IDataResponse {
  entityList: DeviceState[];
  continuationToken: string;
}
export interface DeviceStateRequest {
  deviceType: string;
  searchTerm: string;
  continuationToken?: string;
}

export const Required_Serial_Number = 'Required - serial number';
export const Default_DeviceType = 'BLK2FLY';
export const useDeviceSearch = (request: DeviceStateRequest) => {
  const dispatch = useAppDispatch();
  const [deviceSearchDetails, setDeviceSearchDetails] = useState<
    DeviceState | undefined
  >(undefined);
  const [deviceSearchError, setDeviceSearchError] = useState<string>('');
  const [deviceSearchLoading, setDeviceSearchLoading] = useState<boolean>(true);
  useEffect(() => {

    const updateDeviceStateData = (response: any) => {

      response.data.entityList.sort((a: any, b: any) => (new Date(a.certificateCreatedTime) > new Date(b.certificateCreatedTime) ? -1 : 1));
  
      let item = response.data.entityList[0] as any;
      let device: DeviceState = {
                      SerialNumber: item.serialNumber,
                      ProvisioningId: item.provisioningId,
                      DeviceType: request.deviceType,
                    };
      setDeviceSearchLoading(false);
      dispatch(UpdateState(device));
      setDeviceSearchDetails(device);
      setDeviceSearchError('');
    }

    setDeviceSearchLoading(true);
    const scope = Config.GetConfigData().provisioningService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    if (scope === undefined) {
      setDeviceSearchError('Invalid scope');
    } else if (request.searchTerm === '' || request.searchTerm === undefined) {
      setDeviceSearchError(Required_Serial_Number);
      setDeviceSearchDetails(undefined);
      setDeviceSearchLoading(false);
    } else if (request.deviceType === '' || request.deviceType === undefined) {
      setDeviceSearchError('Required - device type');
      setDeviceSearchDetails(undefined);
      setDeviceSearchLoading(false);
    } else {
      AuthConfig.getToken(scope).then(
        (success) => {
          const headers = {
            Authorization: `Bearer ${success}`,
            'Content-Type': 'application/json',
          };
          let requestQuery: IRequestQuery = {};
          requestQuery.SerialNumber = request.searchTerm;
          if (request.deviceType !== '') {
            requestQuery.DeviceType = request.deviceType;
          }
          logger.logInformation(
            `Access token is  Received successfully  for scope :  ${scope} + 'and requesting device details`
          );
          axios
            .get<IDataResponse>(`${baseUrl}provisioning/enrolment/devices`, {
              params: {
                chunkSize: 1000,
                query: JSON.stringify(requestQuery),
                continuationTokenJson: JSON.stringify(
                  request.continuationToken
                ),
              },
              headers,
              timeout: 60000,
            })
            .then(
              (response) => {
                if (response.data.entityList.length < 1) {
                  setDeviceSearchError('No Device Found');
                  setDeviceSearchDetails(undefined);
                } else {
                  updateDeviceStateData(response);
                }
              },

              (error) => {
                logger.logError(
                  'Failed to get device list for given request: ' +
                    JSON.stringify(request) +
                    '.error' +
                    JSON.stringify(error)
                );
                setDeviceSearchLoading(false);
                setDeviceSearchError(error.message);
                setDeviceSearchDetails(undefined);
              }
            )
            .finally(() => {
              setDeviceSearchLoading(false);
            });
        },
        (error) => {
          setDeviceSearchError(error);
          setDeviceSearchLoading(false);
        }
      );
    }
  }, [request, dispatch]);
  return {
    deviceSearchDetails,
    deviceSearchError,
    deviceSearchLoading,
  };
};
