import { useCallback } from "react";
import { Config } from "../../ConfigService/LoadConfig";

export const useOpenWindowForReplayTool = (
  setLoading: (flag: boolean) => void
) => {
  const replayToolBaseUrl = Config.GetConfigData().replayToolUrl;

  const openWindow = useCallback(
    (replayArrayBuffer: ArrayBuffer | undefined) => {
      let childWindowRef = window.open(replayToolBaseUrl, "_blank");
      childWindowRef?.focus();

      if (
        replayArrayBuffer === undefined ||
        replayArrayBuffer.byteLength === 0
      ) {
        childWindowRef?.close();
        childWindowRef = null;
        throw new Error();
      }

      setLoading(false);
      const eventType = "message";
      const postArrayBufferAsMessage = async (event: MessageEvent) => {
        if (
          event.data !== "Finished loading" &&
          event.origin !== replayToolBaseUrl
        ) {
          return;
        } else {
          childWindowRef?.postMessage(
            { type: "replay", replay: replayArrayBuffer },
            replayToolBaseUrl
          );
        }
        window.removeEventListener(eventType, postArrayBufferAsMessage);

      };
      if (childWindowRef) {
        window.addEventListener(eventType, postArrayBufferAsMessage);
      }
    },
    [replayToolBaseUrl, setLoading]
  );

  return { openWindow };
};
