import axios from 'axios';
import { useCallback, useState } from 'react';
import { Config } from '../../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export const useCdnInvalidate = () => {
    const [cdnInvalidateLoading, setCdnInvalidateLoading] = useState<boolean>(false);
    const [cdnInvalidateError, setCdnInvalidateError] = useState<string>('');
    const [cdnInvalidateResponse, setCdnInvalidateResponse] = useState<boolean>(false);

    const getScope = useCallback(() =>  Config.GetConfigData().firmwareReleaseService?.scopes[0],[]);
    const getUrl = useCallback(() =>  Config.GetConfigData().firmwareReleaseService?.serviceUrl + "releases", []);

    const forceCdnInvalidate = useCallback((releaseType: string) => {
        setCdnInvalidateLoading(true);
        setCdnInvalidateResponse(false);
        var scope = getScope();
        AuthConfig.getToken(scope).then(
            (token) => {
                const headers = {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                };
        
                logger.logInformation(
                  `Access token is  Received successfully  for scope :  ${scope} + 'and force cdn invalidate`
                );
                axios
                  .post(`${getUrl()}/cdn_invalidate`, releaseType, {
                    params: {
                      type: releaseType
                    },
                    headers,
                  })
                  .then(
                    (response) => {
                      setCdnInvalidateResponse(response.data.success);
                      setCdnInvalidateError(response.data.error_message);
                    },
                    (error) => {
                      setCdnInvalidateResponse(false);
                      logger.logError(
                        `Failed to  force synchronise across regions, error: ${JSON.stringify(error)}`
                      );
                      if (error.response !== undefined) {
                        setCdnInvalidateError(error.response.data);
                      } else {
                        setCdnInvalidateError(JSON.stringify(error));
                      }
                    }
                  )
                  .finally(() => {
                    setCdnInvalidateLoading(false);
                  });
              }
        )
    }, [getScope, getUrl]);
    return { forceCdnInvalidate, cdnInvalidateResponse, cdnInvalidateError, cdnInvalidateLoading };
}

