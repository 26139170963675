import { useRef, useState } from 'react'
import useOutsideClick from 'src/Utils/Hooks/UseOutSideClick/UseOutSideClick';
import Logout from './Logout';
import './User.css';

const User = () => {
    const [show, setShow] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    
    useOutsideClick(wrapperRef.current, () => {setShow(false)})

  return(
      <>
       <div className={show? "btn-group show": "btn-group"}
            ref={wrapperRef}>
              <button type="button" 
                data-testid='buttonGroupId'
                 className="btn dropdown-toggle" 
                 data-toggle="dropdown" 
                 aria-haspopup="true" 
                 aria-expanded={show?"true":"false"} 
                 style={{backgroundColor: 'white'}}
                 onClick={() => setShow(!show)}
                 >
                <i className="fa fa-user" style={{fontSize:'19px', color : 'black'}}></i>
              </button>
              <div id="logout-div"
                className={show? "dropdown-menu dropdown-primary show": "dropdown-menu dropdown-primary"} 
                data-testid='dropdownMenuId'
                style={{transform: 'translate3d(-101px, 38px, 0px)', 
                backgroundColor: '#ffffff'}}>
                  <Logout/>
              </div>
            </div>
      </>
  )
}
export default User;
