import React from 'react';
import Card from '../Card/Card';
import { Enrolled_DeviceTwin_Error } from '../DeviceStatusList/DeviceStatusList';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import './DeviceTwin.css';
export const Enrolled_DeviceTwin_Info =
  'Device data is not available as device has not yet connected to IoT hub';
const DeviceTwin: React.FC<{ twinDetails: any; twinError: string }> = ({
  twinDetails,
  twinError,
}) => {
  return (
    <>
      {
        <div className="col-lg-12 col-sm-12">
          <Card header="Device Twin Raw Data">
            {twinError ? (
              <div style={{ paddingTop: `1rem`, paddingLeft: `40px` }} data-testid="error-msg">
                <ErrorMessage
                  message={
                    twinError === Enrolled_DeviceTwin_Error
                      ? Enrolled_DeviceTwin_Info
                      : twinError
                  }
                ></ErrorMessage>
              </div>
            ) : (
              <div className="twin-text">
                <textarea
                  data-testid="txt"
                  className="form-group col-md-12 col-12 col-sm-12 col-lg-12 text"
                  value={JSON.stringify(twinDetails, null, 4)}
                  rows={19}
                  readOnly
                />
              </div>
            )}
          </Card>
        </div>
      }
    </>
  );
};
export default DeviceTwin;
