import './IconWithTooltip.css';
export enum TooltipPosition {
  Right = 'tooltiptext-right',
  Bottom = 'tooltiptext-bottom',
  Left = 'tooltiptext-left',
  Top = 'tooltiptext-top',
}
const IconWithTooltip: React.FC<any> = ({
  tooltipText,
  icon,
  backgroundColor,
  test_id,
  iconColor,
  toolTipTextColor,
  tooltipPosition,
  click, 
  mouseLeave,
  mouseEnter,
  iconFloat,
  tooltipMargin,
  cursor,
  imageLogo,
  fontSize,
  top
}) => {
  let className = `${icon} sub`;
  let tooltipPos = tooltipPosition ? tooltipPosition : TooltipPosition.Right;
  let toolTipStyles = `${tooltipPos} tooltiptext`;

  return !imageLogo ? (
    <>
      <i
        className={className}
        data-testid={test_id}
        style={{ color: iconColor, float: iconFloat, cursor: cursor, fontSize: fontSize, top: top }}
        onClick={click}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <span
          className={toolTipStyles}
          style={{ backgroundColor: backgroundColor, color: toolTipTextColor, margin: tooltipMargin }}
        >
          &nbsp;&nbsp;{tooltipText}
        </span>
      </i>
    </>
  ) 
  : 
  (
<span className="custom-tooltip">
  <img className="img-css" src={imageLogo} alt='Self Test Results'/>
  <span className="custom-tooltiptext">{tooltipText}</span>
  </span>
  )
};
export default IconWithTooltip;
