import React from 'react';
import { Version } from 'src/Utils/Hooks/Versions/useGetVersions';
import IconWithTooltip from '../IconWithTooltip/IconWithTooltip';

interface IVersionDetails {
  versionData: Version[];
  onBack: () => void;
}
const VersionInfo: React.FC<IVersionDetails> = ({ versionData, onBack }) => {
  return (
    <>
      <span
        data-testid="backIcon"
        onClick={() => onBack()}
        style={{ marginLeft: '5vw' }}
      >
        <IconWithTooltip
          icon="bi bi-arrow-left"
          tooltipText="Back"
          test_id="backTest"
        ></IconWithTooltip>
      </span>
      <br />
      <br />
      <div className="row" style={{ color: 'white', marginLeft: '10vw' }}>
        <div>
          <h4>Release notes</h4>
        </div>
        <div style={{ marginTop: '3vh' }}>
          {versionData.map((item) => (
            <div key={item.Version} className="row">
              <div className="col-lg-3">
                <b>V{item.Version}</b>
              </div>
              <div className="col-lg-9">
                <b>Released on {item.ReleaseDate}</b>
                <br />
                <ul style={{ marginTop: '1vh' }}>
                  {item.Notes.map((note) => (
                    <li key={note}>{note}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VersionInfo;
