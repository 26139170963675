import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { ILogRequest, LogType } from './useGetAppDeviceLogs';

export interface ILogsCountResponse {
  deviceType:string;
  count:number;
  logType:LogType;
}

export interface ILogsCountRequest extends ILogRequest
{
  DeviceType: string
}
export const useGetLogsCount = (logRequestList : ILogsCountRequest[]) =>{

    const [logsCountLoading, setLogsCountLoading] = useState<boolean>(true);
    const [logsCountError, setlogsCountError] = useState<string>('');
    const [logsCount, setlogsCount] = useState<ILogsCountResponse[]>([]);
    useEffect(() => {
        setLogsCountLoading(true);
        const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
        const baseUrl = Config.GetConfigData().serviceUrl;
        if (scope === undefined) {
            setlogsCountError('Invalid scope');
            setLogsCountLoading(false);
          }else {
            AuthConfig.getToken(scope).then(
              (success) => {
                const headers = {
                  Authorization: `Bearer ${success}`,
                  'Content-Type': 'application/json',
                };
                logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and requesting logs count`);
             

                axios.get<ILogsCountResponse[]>(`${baseUrl}device/logs-count`, {
                    params:{
                      request:JSON.stringify(logRequestList)
                    },
                    headers
                  }).then(
                    (response) => {
                        if(response.data.length > 0){
                          const logCountResponse = response.data;
                          setlogsCount( logCountResponse);
                        }
                        else{
                          setlogsCount([]);
                        }
                        setlogsCountError('');
                      },
                      
                    (error) => {
                      logger.logError(
                        'Failed to get count of logs for given request: ' +
                          JSON.stringify(logRequestList) +
                          '.error: ' +
                          JSON.stringify(error)
                      );
                      if (error.response !== undefined) {
                        setlogsCountError(error.response.data.Message);
                      } else {
                        setlogsCountError(error.response);
                      }
                      setlogsCount([]);
                    }
                  )
                  .finally(() => {
                    setLogsCountLoading(false);
                  });
              },
              (error) => {
                setlogsCountError(error);
                setLogsCountLoading(false);
              }
            );
          }
        },[logRequestList]);
        return {logsCount, logsCountError, logsCountLoading};
      }
