interface IFileFormInput {
    acceptType:string;
    lableText: string;
    disable:boolean;
    testid?:string;
    paddingLeft?:string;
    onFileSelected: (data:FileList) => void;
}
const FormFileControl:React.FC<IFileFormInput> = ({acceptType, lableText, disable, testid, paddingLeft, onFileSelected}) => {
    return (
        <>
         <div className="form-group row mb-3">
                            <label  className="form-label col-sm-4" style={{ color: 'white', paddingTop: '10px', paddingLeft: paddingLeft }}>{lableText}</label>
                            <div className="col-sm-7">
                               <input className="form-control col-sm-7" 
                                    disabled={disable}
                                    type="file" 
                                    id="formFile" 
                                    accept={acceptType} 
                                    data-testid = {testid}
                                    onChange={(event) => 
                                            {
                                                if(event.target.files != null) {
                                                    onFileSelected(event.target.files)
                                                }
                                            }}/>
                            </div>
        </div>
        </>
    )
}
export default FormFileControl;