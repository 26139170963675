import { useRef, useState } from "react";
import spanStyles from "src/CssModules/span.module.css";
import useOutsideClick from "src/Utils/Hooks/UseOutSideClick/UseOutSideClick";

import "./DownloadLogs.css";
import IconWithTooltip, {
  TooltipPosition,
} from "src/Components/IconWithTooltip/IconWithTooltip";

const DownloadLogs: React.FC<any> = ({ item }) => {
  const [show, setShow] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperRef.current, () => {
    setShow(false);
  });

  return (
    <div className={show ? "btn-group show" : "btn-group"} ref={wrapperRef}>
      <button
        type="button"
        data-testid="buttonGroupId2"
        className="btn dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        style={{ paddingLeft: 0, paddingRight: 0 }}
        onClick={() => setShow((show) => !show)}
      >
        <IconWithTooltip
          tooltipText="Download Logs"
          icon="bi bi-download"
          iconColor="white"
          tooltipPosition={TooltipPosition.Top}
        ></IconWithTooltip>
      </button>
      <div
        className={
          show
            ? "dropdown-menu dropdown-primary show"
            : "dropdown-menu dropdown-primary"
        }
        data-testid="logsMenuId"
        style={{
          transform: "translate3d(-101px, 38px, 0px)",
          marginLeft: "50px",
          minWidth: "190px",
        }}
      >
        {item && item.appLogDownLoadLink && item.appLogDownLoadLink !== "" && (
          <div id="div-applog-id">
            <span style={{ margin: "0px 10px 0px 10px" }}>
              <a
                href={item.appLogDownLoadLink}
                className={spanStyles.sub}
                style={{
                  display: "inline-block",
                  textDecoration: "none",
                  margin: "2px 0px 5px 0px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    border: "black",
                    paddingLeft: "0px",
                    color: "black",
                    width: "150px",
                    textAlign: "left",
                  }}
                >
                  Download App Log
                </span>
              </a>
            </span>
          </div>
        )}

        {item &&
          item.deviceLogDownLoadLink &&
          item.deviceLogDownLoadLink !== "" && (
            <div id="div-devicelog-id">
              <span style={{ margin: "0px 10px 0px 10px" }}>
                <a
                  href={item.deviceLogDownLoadLink}
                  className={spanStyles.sub}
                  style={{
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "2px 0px 5px 0px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      border: "black",
                      paddingLeft: "0px",
                      color: "black",
                      width: "150px",
                      textAlign: "left",
                    }}
                  >
                    Download Device Log
                  </span>
                </a>
              </span>
            </div>
          )}

        {item && item.replayLog && item.replayLog !== "" && (
          <div id="div-applog-id">
            <span style={{ margin: "0px 10px 0px 10px" }}>
              <a
                href={item.replayLogDownLoadLink}
                className={spanStyles.sub}
                style={{
                  display: "inline-block",
                  textDecoration: "none",
                  margin: "2px 0px 5px 0px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    border: "black",
                    paddingLeft: "0px",
                    color: "black",
                    width: "150px",
                    textAlign: "left",
                  }}
                >
                  Download Replay Log
                </span>
              </a>
            </span>
          </div>
        )}

        {item &&
          item.telemetryLogDownLoadLink &&
          item.telemetryLogDownLoadLink !== "" && (
            <div id="div-applog-id">
              <span style={{ margin: "0px 10px 0px 10px" }}>
                <a
                  href={item.telemetryLogDownLoadLink}
                  className={spanStyles.sub}
                  style={{
                    display: "inline-block",
                    textDecoration: "none",
                    margin: "2px 0px 5px 0px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      border: "black",
                      paddingLeft: "0px",
                      color: "black",
                      width: "150px",
                      textAlign: "left",
                    }}
                  >
                    Download Telemetry Log
                  </span>
                </a>
              </span>
            </div>
          )}
      </div>
    </div>
  );
};

export default DownloadLogs;
