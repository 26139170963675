import { AuthConfig } from 'src/AuthConfig';

const Logout = () => {
  return(
            <span 
              className="dropdown-item" 
              onClick={() => AuthConfig.signOut()}
              style={{cursor:'pointer', color:'black', backgroundColor: '#ffffff'}}>
              Logout </span>
  )
}
export default Logout;
