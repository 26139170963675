import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'src/CssModules/modal.module.css';
import Datepicker from 'src/Components/Datepicker/Datepicker';
import {
  IReleaseEntry,
  IReleaseEntryCompanion,
  useFirmwareRelease,
} from 'src/Utils/Hooks/FirmwareRelease/useFirmwareRelease';
import { dateToEpoch } from 'src/Utils/DateHelper';
export interface IAddCompanionParams {
  releaseEntry: IReleaseEntry;
  deviceType: string;
  setCompanions: (companions: IReleaseEntryCompanion[] | undefined) => void;
  onClose: () => void;
  setCompanionError: (error: string) => void;
  updateEditReleaseLoader: (load: boolean) => void;
}
const AddCompanionSoftware: React.FC<IAddCompanionParams> = ({
  releaseEntry,
  deviceType,
  setCompanions,
  onClose,
  setCompanionError,
  updateEditReleaseLoader
}) => {
  const [display, setdisplay] = useState('block');
  const [companionVersion, setCompanionVersion] = useState<string>('');
  const [companionName, setCompanionName] = useState<string>('');
  const [enable, setEnable] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(new Date());
  const {
    addOrUpdateFirmwareReleases,
    firmwareReleases,
    firmwareReleaseError,
    firmwareReleaseLoading,
  } = useFirmwareRelease();
  const verifyNextEnable = useCallback(() => {
    if (companionName !== '' && companionVersion !== '') {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [companionName, companionVersion]);
  const close = useCallback(() => {
    setdisplay('none');
    updateEditReleaseLoader(false);
    onClose();
  }, [onClose, updateEditReleaseLoader]);
  const createCompanion = useCallback(() => {
    releaseEntry.companions?.push({
      version: companionVersion,
      name: companionName,
      release_date: dateToEpoch(date),
    });
    addOrUpdateFirmwareReleases(releaseEntry, deviceType);
  }, [
    companionVersion,
    companionName,
    releaseEntry,
    deviceType,
    addOrUpdateFirmwareReleases,
    date,
  ]);
  useEffect(() => {
    if (firmwareReleases.length > 0) {
      var updatedRelease = firmwareReleases.filter(
        (x) => x.version === releaseEntry.version
      );
      setCompanions(updatedRelease[0].companions);
      close();
    }
  }, [firmwareReleases, setCompanions, releaseEntry, close]);

  useEffect(() => {
    updateEditReleaseLoader(firmwareReleaseLoading)
  }, [firmwareReleaseLoading, updateEditReleaseLoader])
  useEffect(() => {
    setCompanionError(firmwareReleaseError);
  }, [firmwareReleaseError, setCompanionError]);
  useEffect(() => {
    verifyNextEnable();
  }, [verifyNextEnable]);
  return (
    <>
      <div id="myModal" className={Modal.modal} style={{ display: display }}>
        <div className={Modal.content} style={{ width: '30%' }}>
          <div className={Modal.header}>
            <h4 className="text-center">Add Companion Software</h4>
          </div>
          <hr style={{ color: 'white' }} />
          <div className={Modal.body}>
            <div style={{ paddingLeft: 90 }}>
              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Name
                </label>
                <div className="col-md-7">
                  <input
                    data-testid="name-textBox"
                    className="form-control"
                    placeholder="Name"
                    value={companionName}
                    onChange={(e) => {
                      setCompanionName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Version
                </label>
                <div className="col-md-7">
                  <input
                    data-testid="version-textBox"
                    className="form-control"
                    placeholder="Version"
                    value={companionVersion}
                    onChange={(e) => {
                      setCompanionVersion(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Date
                </label>
                <div className="col-md-7">
                  <Datepicker
                    SelectedDate={date}
                    setDate={(e) => setDate(e)}
                    MaxDate={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr style={{ color: 'white' }} />
          <div className={Modal.footer}>
            <div className="row">
              <div className="col-sm-4" style={{ marginLeft: '80px' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => close()}
                  data-testid="cancel"
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-4" style={{ marginLeft: '96px' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => createCompanion()}
                  data-testid="create-release"
                  disabled={enable}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddCompanionSoftware;
