import React, { useEffect, useState } from 'react';
import {
  IDeviceEnrolmentRequest,
  useDevice,
} from 'src/Utils/Hooks/DeviceEnrollment/useDevice';
import { InfoMessageType } from '../InfoBar/InfoBar';

export interface ISummaryParams {
  enrollmentRequest: IDeviceEnrolmentRequest;
  summaryLoading: (loading: boolean) => void;
  isNext: (isEnable: boolean) => void;
}
const Summary: React.FC<ISummaryParams> = ({
  enrollmentRequest,
  summaryLoading,
  isNext,
}) => {
  const {
    enrollmentMessage,
    enrollmentStatus,
    enrollmentLoading,
    validateRange,
  } = useDevice();
  const [msgType, setMsgType] = useState<InfoMessageType>(InfoMessageType.none);
  const [enableNext, setEnableNext] = useState(false);
  useEffect(() => {
    isNext(enableNext);
  }, [enableNext, isNext]);
  useEffect(() => {
    summaryLoading(enrollmentLoading);
  }, [enrollmentLoading, summaryLoading]);
  
  useEffect(() => {
    validateRange(enrollmentRequest);
  }, [enrollmentRequest,validateRange]);
  useEffect(() => {
    switch (enrollmentStatus) {
      case 'Error':
        setMsgType(InfoMessageType.error);
        setEnableNext(false);
        break;
      case 'Success':
        setMsgType(InfoMessageType.success);
        setEnableNext(true);
        break;
      case 'Warning':
        setMsgType(InfoMessageType.warning);
        setEnableNext(true);
        break;
      default:
      // code block
    }
  }, [enrollmentStatus]);
  return (
    <>
      <div>
        <div
          className="form-group row mb-3"
          style={{ color: 'white', paddingLeft: '10px' }}
        >
          <label className="col-sm-9 col-form-label" data-testid='group'>
            Enrollment Group : {enrollmentRequest.EnrollmentGroupName}
          </label>
        </div>
        <div
          className="form-group row mb-3"
          style={{ color: 'white', paddingLeft: '10px' }}
        >
          <label className="col-sm-5 col-form-label">
            Serial Number From : {enrollmentRequest.SerialNoFrom}
          </label>

          <label className="col-sm-5 col-form-label">
            Serial Number To : {enrollmentRequest.SerialNoTo}
          </label>
        </div>
        <div
          className="form-group row mb-3"
          style={{ color: 'white', paddingLeft: '10px' }}
        >
          <label className="col-sm-4 col-form-label">
            Total Devices :{' '}
            {+enrollmentRequest.SerialNoTo -
              +enrollmentRequest.SerialNoFrom +
              1}
          </label>
        </div>
        <div
          className={`col-sm-10 alert alert-${msgType}`}
          data-testid="infoBarTest"
          style={{ marginLeft: '10px', padding: '0.5rem 0.5rem' }}
        >
          <span>{enrollmentMessage}</span>
        </div>
      </div>
    </>
  );
};

export default Summary;
