import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { selectDevice } from 'src/Redux/genericSlice';
import { useAppSelector } from 'src/Redux/hooks';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';
export const Invalid_Pid_Error_Message = 'Invalid Provisioning id';
export interface ITwinInput {
  provisioningId: string;
}
export const useGetDeviceTwin = () => {
  const [twinDetails, setTwinDetails] = useState<any>('');
  const [twinError, setTwinError] = useState<string>('');
  const [twinLoading, setTwinLoading] = useState<boolean>(true);
  const deviceInfo = useAppSelector(selectDevice);
  
  const getDeviceTwin = useCallback( () => {
    setTwinLoading(true);
    let pid = deviceInfo.data.ProvisioningId;
    const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    const requestUrl = `${baseUrl}device/twin/${pid}`;
    if (pid === undefined || pid === '') {
      setTwinError("No Device Found");
      setTwinLoading(false);
    } else if (scope === undefined) {
      setTwinError('Invalid scope');
      setTwinLoading(false);
    } else {
      AuthConfig.getToken(scope).then(
        (success) => {
          const headers = {
            Authorization: `Bearer ${success}`,
          };
          logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and requesting device twin`);
          axios
            .get(requestUrl, { headers })
            .then(
              (response) => {
                setTwinDetails(response.data);
                setTwinError('');
              },

              (error) => {
                logger.logError(
                  'Failed to get twin details with given request: ' +
                  deviceInfo.data.ProvisioningId
                );
                setTwinError(error.response.data);
                setTwinDetails('');
              }
            )
            .finally(() => {
              setTwinLoading(false);
            });
        },
        (error) => {
          setTwinLoading(false);
          setTwinError(error);
        }
      );
    }
  },[deviceInfo.data.ProvisioningId])
  
  useEffect(() => {
    getDeviceTwin();
  }, [ deviceInfo.data.ProvisioningId, getDeviceTwin]);

  return { twinDetails, twinError, twinLoading, getDeviceTwin };
};
