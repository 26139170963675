import React, { useEffect } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Datepicker from '../Datepicker/Datepicker';
const defaultSearchIntervalInDays = 8;
interface IDateRangePicker {
  onStartDateChange: (startDate: Date) => void;
  onEndDateChage: (endData: Date) => void;
}
export const UrlParam_FromDate = 'FromDate';
export const UrlParam_ToDate = 'ToDate';
export const getStartDate = (endDate: Date): Date => {
  let toDate = new Date(endDate);
  toDate.setDate(endDate.getDate() - defaultSearchIntervalInDays);
  return toDate;
};
const DateRangePicker: React.FC<IDateRangePicker> = ({
  onStartDateChange,
  onEndDateChage,
}) => {
  const [searchParams] = useSearchParams();
  let urlParamFromDate = useMemo(() => {
    return searchParams.get(UrlParam_FromDate);
  }, [searchParams]);
  let urlParamToDate = useMemo(() => {
    return searchParams.get(UrlParam_ToDate);
  }, [searchParams]);

  const [endDate, setEndDate] = useState<Date>(
    urlParamToDate ? new Date(urlParamToDate) : new Date()
  );
  const fromDate = useMemo(() => getStartDate(endDate), [endDate]);

  const [startDate, setStartDate] = useState<Date>(
    urlParamFromDate ? new Date(urlParamFromDate) : fromDate
  );

  useEffect(() => {
    onStartDateChange(startDate);
    onEndDateChage(endDate);
  }, [startDate, onStartDateChange, endDate, onEndDateChage]);

  const updateAndNotifyStartDate = useCallback(
    (date: Date) => {
      setStartDate(date);
      onStartDateChange(date);
    },
    [onStartDateChange]
  );
  const updateAndNotifyEndDate = useCallback(
    (date: Date) => {
      setEndDate(date);
      onEndDateChage(date);
    },
    [onEndDateChage]
  );
  return (
    <>
      <div className="col-sm-3">
        <Datepicker
         testId='fromDate'
          SelectedDate={startDate}
          setDate={updateAndNotifyStartDate}
          Label="From"
          MaxDate={endDate}
        />
      </div>
      <div className="col-sm-3">
        <Datepicker
        testId='toDate'
          SelectedDate={endDate}
          setDate={updateAndNotifyEndDate}
          Label="To"
          MaxDate={new Date()}
        />
      </div>
    </>
  );
};
export default React.memo(DateRangePicker);
