import axios from 'axios';
import { useCallback, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { IOptions } from 'src/Components/DropDown/DropDown';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export interface IReleaseEntryAttachment {
  url: string;
  size: Number;
}
export interface IReleaseEntryCompanion {
  name: String;
  version: String;
  release_date: number;
}

export interface IReleaseEntry {
  version: string;
  revision: string;
  date: number;
  size?: number;
  type: string;
  dl_url?: string;
  changelog_url?: string;
  attachments?: IReleaseEntryAttachment[];
  companions?: IReleaseEntryCompanion[];
}

export let uploadTypes: IOptions[] = [
  {
    displayData: 'Snapshot',
    codeData: 'Snapshot',
  },
  {
    displayData: 'Staging',
    codeData: 'Staging',
  },
  {
    displayData: 'Release',
    codeData: 'Release',
  },
  {
    displayData: 'SpecialBuild',
    codeData: 'SpecialBuild',
  },
  {
    displayData: 'Archived',
    codeData: 'Archived',
  },
];

export const useFirmwareRelease = () => {
  const [firmwareReleases, setFirmwareReleases] = useState<IReleaseEntry[]>([]);
  const [firmwareReleaseError, setFirmwareReleaseError] = useState('');
  const [firmwareReleaseLoading, setFirmwareReleaseLoading] =
    useState<boolean>(false);

  const getScope = useCallback(
    () => Config.GetConfigData().firmwareReleaseService?.scopes[0],
    []
  );
  const getUrl = useCallback(
    () =>
      Config.GetConfigData().firmwareReleaseService?.serviceUrl + 'releases',
    []
  );
  const addOrUpdateFirmwareReleases = (
    release: IReleaseEntry,
    releaseType: string
  ) => {
    setFirmwareReleaseLoading(true);
    var scope = getScope();
    AuthConfig.getToken(scope).then((token) => {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      logger.logInformation(
        `Access token is  Received successfully  for scope :  ${scope} + 'and add or update firmware releases`
      );
      axios
        .post(getUrl(), release, {
          params: {
            type: releaseType,
          },
          headers,
        })
        .then(
          (response) => {
            setFirmwareReleases(response.data);
            setFirmwareReleaseError('');
          },
          (error) => {
            logger.logError(
              `Failed to  add or update firmware releases: + error:  + ${JSON.stringify(
                error
              )}`
            );
            if (error.response.data.Message !== undefined) {
              setFirmwareReleaseError(error.response.data.Message);
            } else if (error.response.data !== undefined) {
              setFirmwareReleaseError(error.response.data);
            } else {
              setFirmwareReleaseError(JSON.stringify(error));
            }
          }
        )
        .finally(() => {
          setFirmwareReleaseLoading(false);
        });
    });
  };

  const getFirmwareReleases = useCallback(
    (releaseType: string) => {
      setFirmwareReleaseLoading(true);
      var scope = getScope();
      AuthConfig.getToken(scope).then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        logger.logInformation(
          `Access token is  Received successfully  for scope :  ${scope} + 'and get firmware releases`
        );
        axios
          .get<IReleaseEntry[]>(getUrl(), {
            params: {
              type: releaseType,
            },
            headers,
          })
          .then(
            (response) => {
              setFirmwareReleases(response.data);
            },
            (error) => {
              logger.logError(
                `Failed to get firmware releases: + error:  + ${JSON.stringify(
                  error
                )}`
              );
              if (error.response !== undefined) {
                setFirmwareReleaseError(error.response.data.Message);
              }
            }
          )
          .finally(() => {
            setFirmwareReleaseLoading(false);
          });
      });
    },
    [getUrl, getScope]
  );

  const deleteReleaseEntry = useCallback((version: string, releaseType: string) => {
    setFirmwareReleaseLoading(true);
    var scope = getScope();
    AuthConfig.getToken(scope).then((token) => {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      logger.logInformation(
        `Access token is  Received successfully  for scope :  ${scope} + 'and delete firmware releases`
      );
      axios
        .delete(getUrl(), {
          params: {
            type: releaseType,
          },
          data: { version: version },
          headers,
        })
        .then(
          (response) => {
            setFirmwareReleases(response.data);
            setFirmwareReleaseError('');
          },
          (error) => {
            logger.logError(
              `Failed to  delete firmware releases: + error:  + ${JSON.stringify(
                error
              )}. given version: ${version} and type: ${releaseType} `
            );
            if (error.response.data !== undefined) {
              setFirmwareReleaseError(error.response.data);
            } else {
              setFirmwareReleaseError(JSON.stringify(error));
            }
          }
        )
        .finally(() => {
          setFirmwareReleaseLoading(false);
        });
    });
  },[getUrl, getScope]);
  return {
    addOrUpdateFirmwareReleases,
    getFirmwareReleases,
    deleteReleaseEntry,
    firmwareReleases,
    firmwareReleaseError,
    firmwareReleaseLoading,
  };
};
