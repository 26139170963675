import { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'src/CssModules/modal.module.css';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import {
  ICreateEnrollmentGroup,
  useCreateEnrollmentGroup,
} from 'src/Utils/Hooks/EnrollmentGroupService/useCreateEnrollmentGroup';
import { IEnrolmentGroup } from 'src/Utils/Hooks/EnrollmentGroupService/useGetEnrollmentGroups';
import DropDown from '../DropDown/DropDown';
import FileForm from '../FormFileControl/FileForm';
import TextForm from '../FormTextBox/TextForm';
import { InfoMessageType } from '../InfoBar/InfoBar';
import Loader from '../Loader';
export interface IModelParams {
  onClose: () => void;
  onStatus: (message: string, messageType: InfoMessageType) => void;
}

const CreateEnrollmentGroup: React.FC<IModelParams> = ({
  onClose,
  onStatus,
}) => {
  const [display, setdisplay] = useState('block');
  let deviceTypeOptions = Config.GetConfigData().DeviceTypes;
  const dpsName: string = Config.GetConfigData().dpsName;
  const deviceType = useRef(deviceTypeOptions[0].codeData);
  const [addCertSelected, setAddCertSelected] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);

  let group: IEnrolmentGroup = {
    groupName: '',
    commonName: '',
    dpsName: dpsName,
    deviceType: deviceType.current,
    isCertificateAvailable: false,
    isProduction: false,
    groupIssuerFile: undefined,
    groupCrtFile: undefined,
    groupPasswordFile: undefined,
    groupKeyFile: undefined,
  };
  let groupDetails: ICreateEnrollmentGroup = {
    group: group,
    withCertificates: false,
  };

  const refGroup = useRef<IEnrolmentGroup>(groupDetails.group);

  const [enrollmentGroup, setGroup] =
    useState<ICreateEnrollmentGroup>(groupDetails);
  const { groupCreateDetails, groupCreateError, groupCreateLoading } =
    useCreateEnrollmentGroup(enrollmentGroup);

  const onSubmit = () => {
    let groupDetails: ICreateEnrollmentGroup = {
      group: refGroup.current,
      withCertificates: addCertSelected,
    };
    setGroup(groupDetails);
  };

  const close = useCallback(() => {
    setdisplay('none');
    onClose();
  }, [onClose]);

  const verifySubmitEnable = useCallback(() => {
    let enableButton = false;
    if (
      refGroup.current.groupName !== '' &&
      refGroup.current.commonName !== '' &&
      refGroup.current.dpsName !== ''
    ) {
      enableButton = true;
    }

    if (addCertSelected) {
      if (
        refGroup.current.groupCrtFile === undefined ||
        refGroup.current.groupKeyFile === undefined ||
        refGroup.current.groupIssuerFile === undefined ||
        refGroup.current.groupPasswordFile === undefined
      ) {
        enableButton = false;
      } else {
        enableButton = true;
      }
    }
    setEnableSubmit(enableButton);
  }, [addCertSelected]);

  useEffect(() => {
    verifySubmitEnable();
  }, [addCertSelected, verifySubmitEnable]);

  useEffect(() => {
    if (groupCreateDetails !== '') {
      close();
      onStatus(
        `${refGroup.current.groupName} is created successfully`,
        InfoMessageType.success
      );
    } else if (groupCreateError !== '') {
      close();
      onStatus(groupCreateError, InfoMessageType.error);
    }
  }, [groupCreateDetails, groupCreateError, close, onStatus]);

  return (
    <>
      {groupCreateLoading && <Loader />}
      {
        <div id="myModal" className={Modal.modal} style={{ display: display }}>
          <div className={Modal.content}>
            <div className={Modal.header}>
              <h4 className='text-center'>Create Enrollment Group</h4>
            </div>
            <hr style={{ color: 'white' }} />
            <div className={Modal.body}>
              <div style={{marginLeft:'100px'}}>
              <TextForm
                lableName="Group Name"
                placeholder="Enter Group name"
                testid="groupname"
                onTextChange={(val) => {
                  refGroup.current.groupName = val;
                  verifySubmitEnable();
                }}
              />
              <TextForm
                lableName="Common Name"
                placeholder="Enter Common Name"
                testid="cn-name"
                onTextChange={(val) => {
                  refGroup.current.commonName = val;
                  verifySubmitEnable();
                }}
              />
              <TextForm
                lableName="Dps Name"
                placeholder={dpsName}
                disable={true}
                onTextChange={() => {}}
              />

              <div className="form-group row mb-3">
                <label
                  className="col-sm-4 col-form-label"
                  style={{ color: 'white', paddingLeft: '10px' }}
                >
                  Device Type
                </label>
                <div className="col-md-7">
                  <DropDown
                    selectedItem={deviceType.current}
                    items={deviceTypeOptions}
                    onSelectionChange={(e) => {
                      deviceType.current = e.codeData;
                      refGroup.current.deviceType = deviceType.current;
                    }}
                    id="deviceType-id"
                  />
                </div>
              </div>

              {/* Checkbox */}
              <div className="form-group row mb-3">
                <div style={{ paddingLeft: '10px' }}>
                  <input
                    className="form-check-input col-sm-4 "
                    type="checkbox"
                    data-testid="checkBox-id"
                    onClick={() => {
                      setAddCertSelected(!addCertSelected);
                      verifySubmitEnable();
                    }}
                  />
                  <label
                    className="form-check-label col-sm-7"
                    style={{ color: 'white', paddingLeft: '10px' }}
                  >
                    Add Group With Certificates
                  </label>
                </div>
              </div>

              {/*  Certificate files*/}
              <FileForm
                lableText="Group Crt File"
                acceptType=".crt"
                testid="group-crt"
                disable={!addCertSelected}
                onFileSelected={(files: FileList) => {
                  refGroup.current.groupCrtFile = files[0];
                  verifySubmitEnable();
                }}
              />
              <FileForm
                lableText="Group Key File"
                acceptType=".txt"
                testid="group-key"
                disable={!addCertSelected}
                onFileSelected={(files: FileList) => {
                  refGroup.current.groupKeyFile = files[0];
                  verifySubmitEnable();
                }}
              />
              <FileForm
                lableText="Group Password File"
                acceptType=".txt"
                testid="group-pwd"
                disable={!addCertSelected}
                onFileSelected={(files: FileList) => {
                  refGroup.current.groupPasswordFile = files[0];
                  verifySubmitEnable();
                }}
              />
              <FileForm
                lableText="Group Issuer File"
                acceptType=".crt"
                testid="group-issuer"
                disable={!addCertSelected}
                onFileSelected={(files: FileList) => {
                  refGroup.current.groupIssuerFile = files[0];
                  verifySubmitEnable();
                }}
              />
              </div>
            </div>
            <hr style={{ color: 'white' }} />
            <div className={Modal.footer}>
              <div className="row">
                <div className="col-sm-4" style={{ paddingLeft: '90px' }}>
                  <button
                    className="btn btn-secondary"
                    onClick={() => close()}
                    data-testid="close"
                  >
                    Close
                  </button>
                </div>
                <div className="col-sm-3  ms-auto">
                  <button
                    className="btn btn-primary"
                    disabled={!enableSubmit}
                    onClick={() => onSubmit()}
                    data-testid="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default CreateEnrollmentGroup;
