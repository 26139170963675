import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useGetDeviceTwin } from 'src/Utils/Hooks/GetDeviceTwin/GetDeviceTwin';
import DeviceStatusList from '../DeviceStatusList/DeviceStatusList';
import DeviceTwin from '../DeviceTwin/DeviceTwin';
import Loader from '../Loader';
import './DeviceStatus.css';
import { selectDevice } from 'src/Redux/genericSlice';
import { useAppSelector } from 'src/Redux/hooks';
import DeviceSearch from '../DeviceSearch/DeviceSearch';
import { useSearchParams } from 'react-router-dom';
import DivStyles from 'src/CssModules/div.module.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';

interface IDeviceStatusURLParams {
  deviceTypeString: string | null;
  serialNoString: string | null;
}
const DeviceStatus: React.FC = () => {
  const deviceInfo = useAppSelector(selectDevice);
  const [searchParams, setSearchParams] = useSearchParams();
  const { twinDetails, twinError, twinLoading, getDeviceTwin } = useGetDeviceTwin();
  const [seconds, setSeconds] = useState<number>(0);
  const showLoader = useRef<boolean>(true);
  type DeviceSearchHandle = React.ElementRef<typeof DeviceSearch>;
  const ref = React.useRef<DeviceSearchHandle>(null);
  const [deviceSearchError, setDeviceSearchError] = useState<string>('');
  const [deviceSearhLoading, setDeviceSearchLoaing] = useState<boolean>(false);

  let urlParamObj: IDeviceStatusURLParams = {
    serialNoString: searchParams.get('SerialNumber'),
    deviceTypeString: searchParams.get('DeviceType'),
  };
  const serialNumber = useRef(
    urlParamObj.serialNoString ? urlParamObj.serialNoString : ''
  );
  const deviceType = useRef(
    urlParamObj.deviceTypeString
      ? urlParamObj.deviceTypeString
      : deviceInfo.data.DeviceType
  );

  const setDeviceStatusUrlPrams = useCallback(() => {
    let urlParams = {
      DeviceType: deviceType.current,
    };
    let serialNoParam = {
      SerialNumber: serialNumber.current,
    };
    setSearchParams(
      serialNumber.current !== ''
        ? { ...urlParams, ...serialNoParam }
        : urlParams
    );
  }, [setSearchParams]);

  const onSearch = useCallback(() => {
    ref.current?.UpdateDeviceSearch();
    setDeviceStatusUrlPrams();
    showLoader.current = true;
    setSeconds(0);
  }, [setDeviceStatusUrlPrams]);

  useEffect(() => {
    setDeviceStatusUrlPrams();
  }, [setDeviceStatusUrlPrams]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSearch();
    },
    [onSearch]
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (deviceSearchError === '') {
      interval = setInterval(() => setSeconds(seconds + 1), 1000);
    }
    else {
      setSeconds(0)
    }
    return () => clearInterval(interval);
  }, [setSeconds, seconds, deviceSearchError]);

  useEffect(() => {
    if (twinDetails) 
    {
      showLoader.current = false;
    }
  }, [twinDetails])

  useEffect(() => {
    let timerID = setInterval(function () {
      getDeviceTwin();
      if (twinDetails || twinError !== '') {
        setSeconds(0);
      }

    }, 10 * 1000)
    return () => clearInterval(timerID)
  }, [getDeviceTwin, setSeconds, twinDetails, twinError])

  return (
    <LayoutWrapper>
      <form onSubmit={onSubmit}>
        <div className="row mb-3">
          <DeviceSearch
            onDeviceTypeChange={(val) => {
              deviceType.current = val;
            }}
            onSerialNumberChange={(val) => {
              serialNumber.current = val;
            }}
            ref={ref}
            setErrorMessage={setDeviceSearchError}
            setLoading={setDeviceSearchLoaing}
            deviceType={deviceType.current}
            serialNumber={serialNumber.current}
            isRequired={true}
          />

          <div className={`${DivStyles.width} bottom-margin `}>
            <button className="btn-prim" onClick={onSearch}>
              Search
            </button>
          </div>
          {deviceSearchError === '' && <div className={`${DivStyles.width} timerLabel`}>
            last updated {seconds} seconds ago!
          </div>}
        </div>
      </form>
      <div className="row">
        {deviceSearhLoading && <Loader />}
        {showLoader.current && twinLoading && <Loader />}
        {deviceSearchError ? (
          <ErrorMessage message={deviceSearchError} />
        ) : (
          <div className="row" style={{ paddingRight: 0 }}>
            <div
              className="col-sm-6"
              style={{ paddingRight: 0 }}
            >
              <DeviceStatusList
                twinDetails={twinDetails}
                twinError={twinError}
              />
            </div>
            <div className="col-sm-6" style={{ padding: 0 }}>
              <DeviceTwin twinDetails={twinDetails} twinError={twinError} />
            </div>
          </div>
        )}
      </div>
    </LayoutWrapper>
  );
};
export default DeviceStatus;
