import { useCallback, useEffect, useState } from 'react';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { useFileUpload } from '../useFileUpload';
import { IReleaseEntry, useFirmwareRelease } from '../useFirmwareRelease';

export enum ReleaseNotesType {
  Attachments,
  HtmlEditor,
}
export interface IReleaseNotesRequest {
  release: IReleaseEntry;
  releaseType: string;
  file?: File | undefined;
  releaseNotesType: ReleaseNotesType;
  html?: string;
}
export const useReleaseNotesUpload = (
  
) => {
  const [fileUploadError, setFileUploadError] = useState<any>('');
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [releaseList, setReleaseList] = useState<IReleaseEntry[]>([]);

  const { fileUpload, deleteFile } = useFileUpload();
  const {
    firmwareReleases,
    firmwareReleaseError,
    firmwareReleaseLoading,
    getFirmwareReleases,
  } = useFirmwareRelease();

  const getUrl = useCallback(
    () =>
      Config.GetConfigData().firmwareReleaseService?.serviceUrl + 'releases',
    []
  );

  useEffect(() => {
    if(firmwareReleases.length > 0) {
      setReleaseList(firmwareReleases)
      setFileUploadError('');
    }
  }, [firmwareReleases])

  useEffect(() => {
    if(firmwareReleaseError !== '') {
      setFileUploadError(firmwareReleaseError);
    }
  },[firmwareReleaseError])

  
  useEffect(() => {
    setFileUploadLoading(firmwareReleaseLoading);
  },[firmwareReleaseLoading])

  const deleteReleaseNotes = useCallback((releaseType:string, version:string, attachemetUrl:string) => {
    setFileUploadLoading(true);
    setFileUploadError('');
    deleteFile(releaseType, version, attachemetUrl).then(
      (success) => {
          setReleaseList(success);
          setFileUploadError('');
      },
      (error) => {
        setFileUploadError(error);
      }
    )
    .finally(() => setFileUploadLoading(false))
    .catch((e) => {
      setFileUploadError('Failed to Remove existing release notes')
  });
  },[deleteFile])

  const uploadReleaseNotes = useCallback((releaseNotesrequest: IReleaseNotesRequest) => {
    if (releaseNotesrequest.releaseNotesType === ReleaseNotesType.Attachments) {
      if (releaseNotesrequest.file === undefined) {
        return;
      }
      let fileExtension = releaseNotesrequest.file.name.split('.').pop();
      if (fileExtension !== 'pdf') {
        setFileUploadError('Please do upload files with only .pdf extension');
        return;
      }
    } else {
      if (releaseNotesrequest.html === undefined) {
        return;
      }
    }
    var request = {
      version: releaseNotesrequest.release.version,
      attachment_file:
        releaseNotesrequest.file === undefined
          ? 'release_notes.html'
          : releaseNotesrequest.file.name,
      wysiwyg:
        releaseNotesrequest.releaseNotesType === ReleaseNotesType.HtmlEditor
          ? true
          : false,
    };
    const url = `${getUrl()}/attachments`;
    setFileUploadLoading(true);
    setFileUploadError('');
    fileUpload(
      url,
      request,
      releaseNotesrequest.releaseType,
      releaseNotesrequest.release.version,
      releaseNotesrequest.file,
      releaseNotesrequest.html
    )
      .then(
        (success) => {
          getFirmwareReleases(releaseNotesrequest.releaseType)
        },
        (error) => setFileUploadError(error)
      )
      
      .catch(err => setFileUploadError(err))
      .finally(() => {
        setFileUploadLoading(false);
      })
       
  },[fileUpload, getUrl,getFirmwareReleases])
  
  return { releaseList, fileUploadError, fileUploadLoading, uploadReleaseNotes , deleteReleaseNotes };
};
