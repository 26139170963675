import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export interface IQueryTwinResponse {
    property: string;
    noOfDevices: number;
}
export interface IQueryTwinDetailsRequest {
    DeviceType: string;
    PropertyName: string;
}
export const useGetQueryTwinDetails = (queryTwinDetailsRequest: IQueryTwinDetailsRequest) => {
    const [queryTwinLoading, setQueryTwinLoading] = useState<boolean>(true);
    const [queryTwinError, setQueryTwinError] = useState<string>('');
    const [queryTwinDetails, setQueryTwinDetails] = useState<IQueryTwinResponse[]>([]);
    useEffect(() => {
        setQueryTwinLoading(true);
        const scope = Config.GetConfigData().deviceOpsService?.scopes[0];
        const baseUrl = Config.GetConfigData().serviceUrl;
        if (scope === undefined) {
            setQueryTwinError('Invalid scope');
            setQueryTwinLoading(false);
        } else {
            AuthConfig.getToken(scope).then(
                (success) => {
                    const headers = {
                        Authorization: `Bearer ${success}`,
                        'Content-Type': 'application/json',
                    };
                    logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and requesting device twin query details`);
                    axios.get<IQueryTwinResponse[]>(`${baseUrl}device/twin/queryDeviceTwin`, {
                        params: {
                            DeviceType: queryTwinDetailsRequest.DeviceType,
                            PropertyName: queryTwinDetailsRequest.PropertyName
                        },
                        headers
                    }).then(
                        (response) => {
                            setQueryTwinDetails(response.data);
                            setQueryTwinError('');
                        },
                        (error) => {
                            logger.logError(
                                'Failed to get list of device twin query details for given request: ' +
                                JSON.stringify(queryTwinDetailsRequest) +
                                '.error: ' +
                                JSON.stringify(error)
                            );
                            if (error.response !== undefined) {
                                setQueryTwinError(error.response.data.Message);
                            }
                            setQueryTwinDetails([]);
                        }
                    )
                        .finally(() => {
                            setQueryTwinLoading(false);
                        });
                },
                (error) => {
                    setQueryTwinError(error);
                    setQueryTwinLoading(false);
                }
            );
        }
    }, [queryTwinDetailsRequest]);
    return { queryTwinDetails, queryTwinError, queryTwinLoading };
}