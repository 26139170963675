import React, { useCallback, useRef, useState, useMemo } from 'react';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';
import tableStyles from 'src/CssModules/table.module.css';
import { LogType } from 'src/Utils/Hooks/AppDeviceLogs/useGetAppDeviceLogs';
import {
  ILogsCountRequest,
  useGetLogsCount,
} from 'src/Utils/Hooks/AppDeviceLogs/useGetLogsCount';
import { useGetActiveDeviceCount } from 'src/Utils/Hooks/GetActiveDeviceCount/useGetActiveDeviceCount';
import { getStartDate } from '../DateRangePicker/DateRangePicker';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader';
import './Home.css';
import { UrlParam_DeviceType } from '../DeviceSearch/DeviceSearch';

const Home: React.FC = () => {
  const deviceTypeList = useMemo(() => ['BLK2FLY', 'BLK2GO','BLK2GO Pulse'], []);
  const supportTypes = useMemo(() => ['issue', 'feedback'], []);
  const getLogRequest = useCallback(() => {
    let fromdate = getStartDate(new Date());
    let logsRequest: ILogsCountRequest[] = [];
    deviceTypeList.forEach((device) => {
      supportTypes.forEach((type) => {
        let req: ILogsCountRequest = {
          DeviceType: device,
          FromDateTimeUtc: fromdate.toUTCString(),
          ToDateTimeUtc: new Date().toUTCString(),
          LogType: type === 'issue' ? LogType.issue : LogType.feedback,
        };
        logsRequest.push(req);
      });
    });
    return logsRequest;
  }, [deviceTypeList, supportTypes]);
  const getLogsCount = (devicetype: string, supportType: string): number => {
    let count: number = 0;
    let logType:LogType = supportType === supportTypes[0] ? LogType.issue : LogType.feedback
    if (logsCount.length > 0) {
      let list = logsCount.filter(
        (x) => x.deviceType === devicetype && x.logType === logType
      );
      count = list[0].count;
    }
    return count;
  };
  const getActiveDeviceCount = (): number => {
    let count: number = 0;
    if (activeDeviceCount.length > 0) {
      activeDeviceCount.forEach((element) => {
        count = count + element.count;
      });
    }
    return count;
  };
  const navigatePageHandler = (deviceType: string, supportType: string) => {
    let baseRoute =
      supportType ===  supportTypes[0] ? 'device-issues' : 'fleet-feedback';
    window.location.href = `/${baseRoute}?${UrlParam_DeviceType}=${deviceType}`;
  };

  const [req] = useState<ILogsCountRequest[]>(getLogRequest());
  const { logsCount, logsCountError, logsCountLoading } = useGetLogsCount(req);
  const deviceTypeRequest = useRef<string[]>(deviceTypeList);
  const {
    activeDeviceCount,
    activeDeviceCountError,
    activeDeviceCountLoading,
  } = useGetActiveDeviceCount(deviceTypeRequest.current);

  return (
    <LayoutWrapper>
      <form>
        <h3
          className="row mb-3"
          style={{ color: 'white', paddingLeft: '10px' }}
        >
          Last 7 days Activity
        </h3>
        <div className="row">
          {logsCountLoading && <Loader />}
          {logsCountError ? (
            <ErrorMessage message={logsCountError} />
          ) : (
            <table style={{ width: '50%' }}>
              <thead>
                <tr key={'header'}>
                  <th className={tableStyles.heading}>Device Type</th>
                  <th className={tableStyles.heading}>Issues </th>
                  <th className={tableStyles.heading}>User Feedback</th>
                </tr>
              </thead>
              <tbody>
                {deviceTypeList.map((item) => (
                  <tr key={item}>
                    <td className={tableStyles.data}>{item}</td>
                    {supportTypes.map((type) => (
                      <td className={tableStyles.data} key={type}>
                        <span
                          onClick={() => navigatePageHandler(item, type)}
                          className="logscount"
                        >
                          {getLogsCount(item, type)}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="row mt-5">
          <h3 style={{ color: 'white', paddingLeft: '10px' }}>
            Current Status
          </h3>
        </div>
        <div className="row mt-3">
          {activeDeviceCountLoading && <Loader />}
          {activeDeviceCountError ? (
            <ErrorMessage message={activeDeviceCountError} />
          ) : (
            <ul style={{ color: 'white', paddingLeft: '40px' }}>
              <li>
                {' '}
                {getActiveDeviceCount()} Devices are connected to BLK Cloud
              </li>
            </ul>
          )}
        </div>
      </form>
    </LayoutWrapper>
  );
};

export default React.memo(Home);
