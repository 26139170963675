import {  createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import type { RootState } from './store'
export interface DeviceState {
    SerialNumber: string,
    ProvisioningId: string,
    DeviceType: string,
  }
interface GenericState<T> {
    data: T
    status: 'loading' | 'finished' | 'error'
  }
  
  const createGenericSlice = <T,Reducers extends SliceCaseReducers<GenericState<T>>>(
      {
    name = '',
    initialState,
    reducers,
  }: {
    name: string
    initialState: GenericState<T>
    reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>
  }) => {
          return createSlice({
              name,
              initialState,
              reducers: {
                  start(state) {
                      state.status = 'loading'
                  },
                  /**
                   * If you want to write to values of the state that depend on the generic
                   * (in this case: `state.data`, which is T), you might need to specify the
                   * State type manually here, as it defaults to `Draft<GenericState<T>>`,
                   * which can sometimes be problematic with yet-unresolved generics.
                   * This is a general problem when working with immer's Draft type and generics.
                   */
                  UpdateState(state: GenericState<T>, action: PayloadAction<T>) {
                      state.data = action.payload
                      state.status = 'finished'
                  },
                  ...reducers,
              },
          })
      }
  export const deviceInitialState = {
    SerialNumber: '',
    ProvisioningId: '',
    DeviceType: 'BLK2FLY',
} 
  export const deviceSlice = createGenericSlice({
    name: 'device',
    initialState: { 
        status: 'loading', 
        data:deviceInitialState } as GenericState<DeviceState>,
    reducers: { },
  })
  
export const { UpdateState } = deviceSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectDevice = (state: RootState) => state.device;

export default deviceSlice.reducer