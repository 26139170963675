import { SubNavItems } from "../SubNavBar/SubNavBar";
import { ITopNavbarItems } from "./Navbar";

export const SupportTeamTopItems: ITopNavbarItems[] =  [
    {
        id:'device',
        name:'Device',
        hasChildren: true,
        routePath:'/device-status',
        testid:'device'
    },
    {
        id:'fleet',
        name:'Fleet',
        hasChildren: true,
        routePath:'/fleet-telemetry',
        testid:'fleet'
    },
    {
        id:'',
        name:'HxDR Processing',
        hasChildren: false,
        routePath:'/processing-logs',
        testid:'processing-logs'
    }
]
  
export const SupportSubNavMenuItems: SubNavItems[] = [
    {
      title: 'Device Status',
      path: '/device-status',
      parent: 'device',
    },
    {
      title: 'Issues',
      path: '/device-issues',
      parent: 'device',
    },
    {
      title: 'Telemetry',
      path: '/device-telemetry',
      parent: 'device',
    },
    {
      title: 'Log file browser',
      path: '/device-logs',
      parent: 'device',
    },
    {
      title: 'Telemetry',
      path: '/fleet-telemetry',
      parent: 'fleet',
    },
    {
      title: 'User Feedback',
      path: '/fleet-feedback',
      parent: 'fleet',
    },
    {
      title: 'Query Device Twin',
      path: '/fleet-querytwin',
      parent: 'fleet',
    },
    {
      title: 'Release Management',
      path: '/fleet-releases',
      parent: 'fleet',
    },
  ];


export const ProductionTeamTopItems: ITopNavbarItems[] =  [
    {
        id:'provisioning',
        name:'Provisioning',
        hasChildren: true,
        routePath:'/provisioning-device-explorer',
        testid:'provisioning'
    }
]

export const ProductionSubNavMenuItems: SubNavItems[] = [
{
    title: 'Device Explorer',
    path: '/provisioning-device-explorer',
    parent: 'provisioning',
},
{
    title: 'Enrollment Group',
    path: '/provisioning-enrollment-group',
    parent: 'provisioning',
}
]

export const CommonItems : ITopNavbarItems[] = [
    {
        id:'',
        name:'Home',
        hasChildren: false,
        routePath:'/home',
        testid:'home'
    }
]
export const MapViewItems : ITopNavbarItems[] = [
  {
    id:'',
    name:'Map View',
    hasChildren: false,
    routePath:'/map-view',
    testid:'map-view'
}
]