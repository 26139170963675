import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useFullscreen } from "src/Utils/Hooks/useFullscreen";
import IconWithTooltip from "../IconWithTooltip/IconWithTooltip";
import Loader from "../Loader";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': any,
      'viz-filter': any
    }
  }
}

export interface ITableauParams {
  url: string;
  token: string;
  filter?: string;
}
const LoadDashboard: React.FC<ITableauParams> = ({ url, filter, token }) => {
  const [loading, setLoading] = useState<boolean>(true);

const { fullscreenActive} = useFullscreen();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const viewInFullScreen = () => {
    if (iFrameRef.current !== null) {
      iFrameRef.current.requestFullscreen();
    }
  }

  useEffect(() => {
    let timerID = setInterval(function () {
      setLoading(false);
    }, 1 * 1000)
    return () => clearInterval(timerID)
  }, [])

  return (
    <>
      <div style={{height:'71vh'}}>
        {loading && <Loader />}
        <div  ref={iFrameRef} >
          <tableau-viz
            data-testid="view-id"
            id="tableauViz"
            src={url}
            token={token}
            width='100vw'
            height={fullscreenActive? '100vh' : '71vh'}
            toolbar='hidden'>
            {
              filter &&
              <viz-filter field="device_id" value={filter}> </viz-filter>
            }
          </tableau-viz>
        </div>
        {!loading &&
          <div style={{background:'white'}}>
            <span data-testid="fullscreen" style={{color:'black'}} onClick={() => viewInFullScreen()}>
              <div className="col-md-12" style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft:'20px' }}>
                <IconWithTooltip
                  icon="bi bi-arrows-fullscreen"
                  tooltipText="View Full screen"
                  test_id="Full screenTest"
                  iconColor="black"
                  backgroundColor="white"
                  toolTipTextColor="black"
                ></IconWithTooltip>
              </div>
            </span>
          </div>
      }
      </div>
     

    </>
  )
}


export default React.memo(LoadDashboard);