import { useCallback, useEffect, useRef, useState } from 'react';
import { selectDevice } from 'src/Redux/genericSlice';
import DropDown from '../DropDown/DropDown';
import TableauView from '../TableauView/TableauView';
import './Telemetry.css';
import DeviceSearch, {
  DeviceSearchRequest,
} from '../DeviceSearch/DeviceSearch';
import { useAppSelector } from 'src/Redux/hooks';
import { Required_Serial_Number } from 'src/Utils/Hooks/DeviceSearch/UseDeviceSearch';
import React from 'react';
import Loader from '../Loader';
import { useSearchParams } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useTelemetry } from 'src/Utils/Hooks/Telemetry/useTelemetry';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';


const Telemetry: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const deviceInfo = useAppSelector(selectDevice);

  let urlParamObj = {
    viewNameString: searchParams.get('ViewName'),
    serialNoString: searchParams.get('SerialNumber'),
    deviceTypeString: searchParams.get('DeviceType'),
  };
  let selectedDeviceType = urlParamObj.deviceTypeString
    ? urlParamObj.deviceTypeString
    : deviceInfo.data.DeviceType;

  const [deviceType, setDeviceType] = useState(selectedDeviceType);
  const refDeviceSearch = useRef<DeviceSearchRequest>({
    SerialNumber: urlParamObj.serialNoString
      ? urlParamObj.serialNoString
      : deviceInfo.data.SerialNumber,
    DeviceType: selectedDeviceType,
  });


  const [selectedView, setSelectedView] = useState(urlParamObj.viewNameString ?? '')
  const { viewOptions, details, hostUrl } = useTelemetry(deviceType, selectedView);

  const [loadView, setLoadView] = useState(true);

  const [deviceSearchError, setDeviceSearchError] = useState<string>('');
  const [deviceSearhLoading, setDeviceSearchLoaing] = useState<boolean>(false);

  type DeviceSearchHandle = React.ElementRef<typeof DeviceSearch>;
  const ref = React.useRef<DeviceSearchHandle>(null);

  const onViewSelectionChange = (codeData: string) => {
    setLoadView(false);
    setSelectedView(codeData);
  };
  const setTelemetryUrlParams = useCallback(() => {
    let urlParams = {
      DeviceType: deviceType,
      ViewName: details.WorkSheetName,
    };
    let serialNoParam = {
      SerialNumber: refDeviceSearch.current.SerialNumber,
    };
    setSearchParams(
      refDeviceSearch.current.SerialNumber !== ''
        ? { ...urlParams, ...serialNoParam }
        : urlParams
    );
  }, [details, deviceType, setSearchParams]);

  const onViewClicked = useCallback(() => {
    if (deviceInfo.data.SerialNumber !== refDeviceSearch.current.SerialNumber) {
      ref.current?.UpdateDeviceSearch();
    }
    setTelemetryUrlParams();
    setLoadView(true);
  },[setTelemetryUrlParams, deviceInfo.data.SerialNumber]);
  useEffect(() => {
    setTelemetryUrlParams();
  }, [details, setTelemetryUrlParams]);


  const onDeviceTypeChanged = (deviceType: string) => {
    refDeviceSearch.current.DeviceType = deviceType;
    setDeviceType(refDeviceSearch.current.DeviceType);
    setLoadView(false);
  };

  const onSerialNoChanged = (serialno: string) => {
    refDeviceSearch.current.SerialNumber = serialno;
  };

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onViewClicked();
    },
    [onViewClicked]
  );

  return (
    <>
      <LayoutWrapper>
        <form onSubmit={onSubmit}>
          <div className="row">
            <DeviceSearch
              showSerialNumber={true}
              onDeviceTypeChange={onDeviceTypeChanged}
              onSerialNumberChange={onSerialNoChanged}
              ref={ref}
              setErrorMessage={setDeviceSearchError}
              setLoading={setDeviceSearchLoaing}
            />
            <div className="col-md-3">
              <DropDown
                selectedItem={selectedView}
                items={viewOptions}
                id="view-list"
                onSelectionChange={(e) => {
                  onViewSelectionChange(e.codeData);
                }}
              />
            </div>

            <div className="col-md-3">
              <button
                className="btn-prim"
                onClick={onViewClicked}
                disabled={
                  refDeviceSearch.current.SerialNumber === '' &&
                  viewOptions.length <= 0
                }
              >
                View
              </button>
            </div>
          </div>
        </form>
        <div className="row">{deviceSearhLoading && <Loader />}</div>

        {viewOptions.length <= 0 && (
          <ErrorMessage
            message={`${refDeviceSearch.current.DeviceType} is not supported.`}
          />
        )}

        {deviceSearchError && deviceSearchError !== Required_Serial_Number ? (
          <ErrorMessage message={deviceSearchError} />
        ) : viewOptions.length > 0 &&
          refDeviceSearch.current.SerialNumber === '' ? (
          <>
            <div className="row mb-3">
              <p style={{ color: 'white' }}>{Required_Serial_Number}</p>
            </div>
          </>
        ) : (

          viewOptions.length > 0 &&
          deviceInfo.data.ProvisioningId &&
          loadView && (
            <div className="row">
              <div className="col-md-12">
                <TableauView
                  hostUrl={hostUrl.current}
                  workbook={details.workBookName}
                  worksheet={details.WorkSheetName}
                  provisioningId={deviceInfo.data.ProvisioningId}
                />
              </div>
            </div>
          )
        )}
      </LayoutWrapper>
    </>
  );
};
export default Telemetry;
