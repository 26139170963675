import { useCallback, useEffect, useRef, useState } from 'react';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { useFileUpload } from '../useFileUpload';
import { IReleaseEntry, useFirmwareRelease } from '../useFirmwareRelease';

export interface IOTAFileUploadRequest {
  release: IReleaseEntry;
  releaseType: string;
  file: File | undefined;
}
export const useOTAFileUpload = (
  otaFileUploadRequest: IOTAFileUploadRequest
) => {
  const [fileUploadError, setFileUploadError] = useState<any>('');
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [releaseList, setReleaseList] = useState<IReleaseEntry[]>([]);
  const count = useRef<number>(0);
  const { progressPercent, cancelFileUpload, fileUpload } = useFileUpload();
  const sizeFound = useRef<boolean>(false);
  const {
    firmwareReleases,
    firmwareReleaseError,
    getFirmwareReleases,
  } = useFirmwareRelease();

  const getUrl = useCallback(
    () =>
      Config.GetConfigData().firmwareReleaseService?.serviceUrl + 'releases',
    []
  );

  useEffect(() => {
    let size = firmwareReleases.find(x => x.version === otaFileUploadRequest.release.version)?.size;
    if (size && size !== 0) {
      sizeFound.current = true;
      setReleaseList(firmwareReleases)
    } else if (count.current === 5) {
      setReleaseList(firmwareReleases)
    }
  }, [firmwareReleases, count, otaFileUploadRequest])

  useEffect(() => {
    setFileUploadError(firmwareReleaseError);
    setFileUploadLoading(false);
  }, [firmwareReleaseError])

  useEffect(() => {
    if (otaFileUploadRequest.file === undefined) {
      return;
    }
    setFileUploadError('')
    const url = `${getUrl()}/upload_url`;
    var request = {
      version: otaFileUploadRequest.release.version,
      date: otaFileUploadRequest.release.date,
      type: otaFileUploadRequest.release.type,
    };
    fileUpload(
      url,
      request,
      otaFileUploadRequest.releaseType,
      otaFileUploadRequest.release.version,
      otaFileUploadRequest.file
    )
      .then(
        (success) => {
          sizeFound.current = false;
          setFileUploadLoading(true);
          count.current = 0;
          let timerId = setInterval(() => {
            if (!sizeFound.current) {
              count.current = count.current + 1;
              getFirmwareReleases(otaFileUploadRequest.releaseType);
              if (count.current === 5) {
                clearInterval(timerId);
                setFileUploadLoading(false);
              }
            }
            else {
              clearInterval(timerId);
              setFileUploadLoading(false);
            }
            return () => clearInterval(timerId);
          }, 1000);
        },
        (error) => {
          setFileUploadError(error);
          setFileUploadLoading(false);
        }
      )
  }, [otaFileUploadRequest, fileUpload, getUrl, getFirmwareReleases]);
  return { releaseList, progressPercent, cancelFileUpload, fileUploadError, fileUploadLoading };
};
