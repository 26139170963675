import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '../../ConfigService/LoadConfig';
import { AuthConfig } from '../../../AuthConfig';
import { logger } from 'src/Utils/LoggerService/LoggerService';

export interface ISetProductionGroup {
    groupName: string;
    dpsName: string;
    isProduction: boolean;
};
export const useSetProductionGroup = (setProductionGroupRequest: ISetProductionGroup) => {
    const [productionGroupLoading, setProductionGroupLoading] = useState<boolean>(true);
    const [productionGroupError, setProductionGroupError] = useState<string>('');
    const [productionGroupResponse, setProductionGroupResponse] = useState<any>(false);
    useEffect(() => {
        setProductionGroupLoading(true);
        const scope = Config.GetConfigData().provisioningService?.scopes[0];
        const baseUrl = Config.GetConfigData().serviceUrl;
        if (scope === undefined) {
            setProductionGroupError('Invalid scope');
            setProductionGroupLoading(false);
        }
        else if(setProductionGroupRequest.dpsName==='' || setProductionGroupRequest.groupName===''){
            setProductionGroupError('');
            setProductionGroupLoading(false);
        } else {
            AuthConfig.getToken(scope).then(
                (success) => {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${success}`,
                            'Content-Type': 'application/json',
                        }
                    };
                    logger.logInformation(`Access token is  Received successfully  for scope :  ${scope} + 'and requesting set IsProduction value`);
                    axios.post<ISetProductionGroup>(`${baseUrl}provisioning/enrolment/groups/set-production`,
                        setProductionGroupRequest,
                        config
                    ).then(
                        (response) => {
                            setProductionGroupResponse(response);
                            setProductionGroupError('');
                        },
                        (error) => {
                            setProductionGroupResponse(false);
                            logger.logError(
                                'Failed to set the isProduction value for given request: ' +
                                'error: ' +
                                JSON.stringify(error)
                            );
                            if (error.response !== undefined) {
                                setProductionGroupError(error.response.data.Message);
                            }
                        }
                    )
                        .finally(() => {
                            setProductionGroupLoading(false);
                        });
                },
                (error) => {
                    setProductionGroupError(error);
                    setProductionGroupResponse(false);
                    setProductionGroupLoading(false);
                }
            );
        }
    }, [setProductionGroupRequest]);
    return { productionGroupResponse, productionGroupError, productionGroupLoading };
}

