import LayoutWrapper from "../LayoutWrapper/LayoutWrapper";
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useGetEnrollmentGroups } from 'src/Utils/Hooks/EnrollmentGroupService/useGetEnrollmentGroups';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import tableStyles from 'src/CssModules/table.module.css';
import IconWithTooltip, { TooltipPosition } from "../IconWithTooltip/IconWithTooltip";
import CreateEnrollmentGroup from "../CreateEnrollmentGroup/CreateEnrollmentGroup";
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar";
import { useGetValidateRoles, UserRoles } from "src/Utils/Hooks/UseGetValidateRoles/UseGetValidateRoles";
import Switch from "../Switch/Switch";
import { IEnrolmentGroup } from 'src/Utils/Hooks/EnrollmentGroupService/useGetEnrollmentGroups';
import { useSetProductionGroup } from "src/Utils/Hooks/EnrollmentGroupService/useSetProductionGroup";
import { ISetProductionGroup } from "src/Utils/Hooks/EnrollmentGroupService/useSetProductionGroup";

const DeviceEnrollmentGroup: React.FC = () => {
    const [load, setLoad] = useState(true);
    const { enrollmentGroups, enrollmentGroupsError, enrollmentGroupsLoading } = useGetEnrollmentGroups(load);
    const [createGroup, setCreateGroup] = useState(false);
    const [toggleInfoBar, setToggleInfoBar] = useState<boolean>(false);
    const [productionGroupInfoBar, setProductionGroupInfoBar] = useState<boolean>(false);
    const [productionGroupMsg, setProductionGroupMsg] = useState<string>('')
    let setProductionRequest: ISetProductionGroup = {
        groupName: '',
        dpsName: '',
        isProduction: false
    }
    const [productionGroupRequest, setProductionGroupRequest] = useState<ISetProductionGroup>(setProductionRequest);
    let { productionGroupResponse, productionGroupError, productionGroupLoading } = useSetProductionGroup(productionGroupRequest);
    const onToggleChange = useCallback((enabled: boolean, request: IEnrolmentGroup) => {
        const enableMsg = enabled ? "enable" : "disable";
		const confirmBox = window.confirm(`Do you want to ${enableMsg} the ${request.groupName} group?`);
        if(confirmBox) {
        const toggleRequest: ISetProductionGroup = {
            groupName: request.groupName,
            dpsName: request.dpsName,
            isProduction: enabled
        };
        setProductionGroupRequest(toggleRequest);
        const msg = enabled ? `Enabled the ${request.groupName} group successfully.` : `Disabled the ${request.groupName} group successfully.`;
        setProductionGroupMsg(msg);
        setProductionGroupInfoBar(true);
     }
    }, []);

    const reload = useCallback(() => {
        setLoad(load => !load)
    },[])

    useEffect(()=>{
        reload()
    },[productionGroupError,productionGroupResponse, reload])

    var { validateUser } = useGetValidateRoles();
    const msg = useRef('')
    const msgType = useRef<InfoMessageType>(InfoMessageType.success)
    const addGroup = () => {
        setCreateGroup(true);
    }
    const status = (message: string, type: InfoMessageType) => {
        msgType.current = type;
        msg.current = message;
        setToggleInfoBar(true);
        reload();
    }

    
    return (
        <>
            <LayoutWrapper>
                {enrollmentGroupsLoading && <Loader />}
                {
                    toggleInfoBar &&
                    <div className="row">
                        <InfoBar Message={msg.current} MessageType={msgType.current} closeClick={() => { setToggleInfoBar(false) }} />
                    </div>
                }
                {productionGroupLoading && <Loader />}
                {
                    productionGroupInfoBar &&
                    <div className="row">
                        <InfoBar
                            Message={productionGroupResponse ? `${productionGroupMsg}` : productionGroupError}
                            MessageType={
                                productionGroupResponse
                                    ? InfoMessageType.success
                                    : InfoMessageType.error
                            }
                            closeClick={() => { setProductionGroupInfoBar(false) }}
                        />
                    </div>
                }
                {
                    createGroup &&
                    <CreateEnrollmentGroup onClose={() => setCreateGroup(false)} onStatus={
                        (message, messageType) => status(message, messageType)
                    } />
                }
                <div className="row">
                    <div className="card" style={{ position: 'inherit', backgroundColor: 'black', border: '1px solid white' }}>
                        <div
                            className="card-header"
                            style={{
                                color: 'white',
                                backgroundColor: 'black',
                                fontSize: '1.14vw',
                                paddingLeft: '40px',
                                borderBottomColor: 'white',
                            }}
                            data-testid="header"
                        >

                            <div className="row col-md-12 pt-1" >
                                Enrollment Groups Details
                                <div className="col-sm-1  ms-auto" >
                                    {/* Only Admin users can create group */}
                                    {validateUser([UserRoles.ProductionDeviceAdmin]) &&
                                        <span style={{ paddingRight: '20px', fontSize: '1.4vw' }}
                                            data-testid='create-group'
                                            onClick={() => addGroup()}
                                        >
                                            <IconWithTooltip
                                                tooltipText='Create enrollment group'
                                                icon='bi bi-plus'
                                                tooltipPosition={TooltipPosition.Bottom}
                                            >
                                            </IconWithTooltip>
                                        </span>
                                    }
                                    <span onClick={() => reload()}
                                        data-testid='Reload'>
                                        <IconWithTooltip
                                            tooltipText='Reload'
                                            icon='bi bi-arrow-clockwise'
                                            tooltipPosition={TooltipPosition.Bottom}
                                        >
                                        </IconWithTooltip>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="card-body card-body-padding mt-4 pt-1"
                            data-testid="content"
                            style={{ backgroundColor: 'black', paddingLeft: '55px' }}
                        >

                            <div className="row">

                                {enrollmentGroupsError && <ErrorMessage message={enrollmentGroupsError} />}
                                {enrollmentGroups && enrollmentGroups.length === 0 ? (
                                    <ErrorMessage message="No Enrollment groups found" />
                                ) : (
                                    <div className={`${tableStyles.fixTableHead} col-md-12`}>
                                        <table style={{ width: '100%' }}>
                                            <thead className={`${tableStyles.tableHead}`}>
                                                <tr key={'header'}>
                                                    <th className={tableStyles.heading}>Dps Name</th>
                                                    <th className={tableStyles.heading}>Device Type</th>
                                                    <th className={tableStyles.heading}>Group Name</th>
                                                    <th className={tableStyles.heading}>Common Name</th>
                                                    <th className={tableStyles.heading}>IsCertificateAvailable</th>
                                                    {validateUser([UserRoles.ProductionDeviceAdmin]) &&
                                                        <th className={tableStyles.heading}>IsProduction</th>
                                                    }

                                                </tr>
                                            </thead>
                                            {<tbody>
                                                {enrollmentGroups.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className={tableStyles.data}>{item.dpsName}</td>
                                                        <td className={tableStyles.data}>{item.deviceType}</td>
                                                        <td className={tableStyles.data}>{item.groupName}</td>
                                                        <td className={tableStyles.data}>{item.commonName}</td>
                                                        <td className={tableStyles.data}>{item.isCertificateAvailable.toString()}</td>
                                                        {validateUser([UserRoles.ProductionDeviceAdmin]) &&
                                                            <td className={tableStyles.data}>
                                                                <Switch
                                                                    disabled= {!item.isCertificateAvailable}
                                                                    checked = {item.isProduction}
                                                                    id={item.groupName}
                                                                    onToggleChange={(enabled) => onToggleChange(enabled, item)}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>}
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        </>
    );
};
export default DeviceEnrollmentGroup;