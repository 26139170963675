import axios from 'axios';
import {useCallback, useRef} from 'react';
import { AuthConfig } from 'src/AuthConfig';

export interface IPagingOption {
    chunkSize: number;
    itemsPerPage: number;
  }
  
  export interface IDataResponse<T> {
    entityList: T[];
    continuationToken: string;
  }
  
  export interface IPageData<T> {
    data: T[];
    hidePrevButton: boolean;
    hideNextButton: boolean;
  }
  
  // key as page number and value as response token
  export interface ITokensList {
    [key: string]: any;
  }
  
const usePagination = <T extends Partial<T>>(pageOption: IPagingOption, baseUrl: string, serviceScope: string, query: string, additionalParams:any = undefined) => {

    const url = useRef(baseUrl);
    const pagingOptions = useRef(pageOption);
    const tokensList = useRef<ITokensList>({});
    const currentChunkIndex = useRef(0);
    const currentPageIndex = useRef(0)
    const dataChunk = useRef<T[]>([])
    const displayPage = useRef<T[]>([]);
    const numberOfPages = useRef(0);
    const searchQuery = useRef(query)
    const scope = useRef(serviceScope);
    tokensList.current[1] = "";

    const  getDataWithToken = useCallback(async(token: string | null): Promise<IDataResponse<T>> => {
    
      let res = new Promise<IDataResponse<T>>((resolve, reject) => {
        AuthConfig.getToken(scope.current).then(
          bearerToken => {
            const headers = {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            };
            let queryParams = {
              chunkSize: pagingOptions.current.chunkSize,
              query: searchQuery.current,
              continuationTokenJson: token,
            }
            let params = additionalParams ? {...queryParams, ...additionalParams} : queryParams
            axios
              .get<IDataResponse<T>>(url.current, {
                params: params,
                headers,
              }).then(
                response =>  resolve(response.data),
                error => reject(error)
              )
          },
          error => reject("Failed to get access token")
        )
  
  
      }
      )
      return res;
    },[additionalParams])
  
      const  getData = useCallback(async(token: string | null) => {
        await getDataWithToken(token).then(
          data => {
            dataChunk.current = data.entityList;
            // next continuous token
            const nextPageToken = Object.keys(tokensList.current).length + 1;
            if(data.continuationToken === '') {
                tokensList.current[nextPageToken] = null;
            }else{
                tokensList.current[nextPageToken] = data.continuationToken;
            }
            
            // number of pages
            numberOfPages.current = Math.floor(((dataChunk.current.length - 1) / pagingOptions.current.itemsPerPage) + 1);
          }
        );
      },[getDataWithToken])
      const reset = useCallback((query: string) => {
        searchQuery.current = query
        currentPageIndex.current = 0;
        currentChunkIndex.current = 0;
        numberOfPages.current = 0;
        tokensList.current = {};
        dataChunk.current = [];
        displayPage.current = [];
        tokensList.current[1] = "";
      },[])
      
      const getPartialData = useCallback(() => {
        
        const skipElements = (currentPageIndex.current - 1) * pagingOptions.current.itemsPerPage;
        displayPage.current = dataChunk.current.slice(skipElements, skipElements + pagingOptions.current.itemsPerPage);
        return displayPage.current;
      },[])

      const hideNextButton = useCallback((): boolean => {
        // for the last chunk and last page next button should be disabled
        if(numberOfPages.current  === 0) {
            return true;
          }
          if (currentPageIndex.current === numberOfPages.current &&
               tokensList.current[currentChunkIndex.current + 1] === null) {
            return true;
          } else {
            return false;
          }
      },[])
    
      const hidePrevButton= useCallback((): boolean =>{
        // for the first chunk and first page prev button should be disabled
        if (currentPageIndex.current <= 1 && currentChunkIndex.current <= 1) {
            return true;
        } else {
          return false;
        }
      },[])

      const getDisplayResponse = useCallback((): IPageData<T> => {
        const response: IPageData<T> = {
          data: getPartialData(),
          hideNextButton: hideNextButton(),
          hidePrevButton: hidePrevButton()
        };
        return response;
      },[getPartialData, hideNextButton, hidePrevButton])
    
      const getPreviousPageToken = useCallback((): string | null=>{
        currentChunkIndex.current = currentChunkIndex.current - 1;
        if (currentChunkIndex.current === 0) {
           return null;
        }
        const prevPageToken = tokensList.current[currentChunkIndex.current];
        // clear the next pages token
        // example current page is 3 and want to see  the prev page 2. so removing 3rd and 4th page token.
        // because if query for 2nd page we will getnext page token
        delete tokensList.current[currentChunkIndex.current + 1];
        delete tokensList.current[currentChunkIndex.current + 2];
        return prevPageToken;
      },[])

      const  getPrevPageData = useCallback(async(): Promise<IPageData<T>> => {
        currentPageIndex.current = currentPageIndex.current  - 1;
        if (currentPageIndex.current < 1) {
            const prevPageToken = getPreviousPageToken();
            await getData(prevPageToken);
            currentPageIndex.current = numberOfPages.current;
            return getDisplayResponse();
        } else {
            return getDisplayResponse();
        }
      },[getData, getDisplayResponse, getPreviousPageToken])

      const getNextPageToken = useCallback((): string => {
        currentChunkIndex.current  = currentChunkIndex.current + 1;
        return tokensList.current[currentChunkIndex.current];
      },[])
    

      const  getNextPageData = useCallback(async(): Promise<IPageData<T>> => {
        currentPageIndex.current = currentPageIndex.current + 1;
        if (currentPageIndex.current > numberOfPages.current) {
          currentPageIndex.current = 1;
          const nextPageToken = getNextPageToken();
          await getData(nextPageToken);
          return getDisplayResponse();
        } else {
          return getDisplayResponse();
        }
      },[getData, getDisplayResponse, getNextPageToken])
    
      const getNextDisplayData= useCallback( async(): Promise<IPageData<T>> => {
        return await getNextPageData();
      },[getNextPageData])
    
      const  getPrevDisplayData= useCallback(async(): Promise<IPageData<T>> => {
        return await getPrevPageData();
      },[getPrevPageData])

    return {
        getNextDisplayData,
        getPrevDisplayData,
        reset
    };
}

export default usePagination;
