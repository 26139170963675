import { useState } from 'react';

export interface InputDetails {
  disable?: boolean;
  placeholder: string;
  lableName: string;
  testid?: string;
  value?: string;
  onTextChange: (val: string) => void;
}
const FormTextBox: React.FC<InputDetails> = ({
  lableName,
  onTextChange,
  placeholder,
  disable,
  testid,
  value,
}) => {
  let initialVal = value ? value : '';
  const [vl, setVal] = useState(initialVal);
  return (
    <>
      <div className="form-group row mb-3">
        <label
          className="col-sm-4 col-form-label"
          style={{ color: 'white', paddingLeft: '10px' }}
        >
          {lableName}
        </label>
        <div className="col-sm-7">
          <input
            type="text"
            disabled={disable}
            className="form-control"
            placeholder={placeholder}
            data-testid={testid}
            onChange={(e) => {
              onTextChange(e.target.value);
              setVal(e.target.value);
            }}
            value={vl}
          />
        </div>
      </div>
    </>
  );
};
export default FormTextBox;
