import TableauView from '../TableauView/TableauView';
import React from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useTelemetry } from 'src/Utils/Hooks/Telemetry/useTelemetry';
import LayoutWrapper from '../LayoutWrapper/LayoutWrapper';

const DeviceType = 'Fleet';
const FleetTelemetry: React.FC = () => {

  const { viewOptions, details, hostUrl } = useTelemetry(DeviceType, '');
  const verifyDetails = () => details.WorkSheetName !== '' && details.workBookName !== '';

  return (
    <>
      <LayoutWrapper>
        {viewOptions.length <= 0 && (
          <ErrorMessage
            message='tableau is not supported.'
          />
        )}
        {viewOptions.length > 0 && verifyDetails() &&
          (
            <div className="row">
              <div className="col-md-12">
                <TableauView
                  hostUrl={hostUrl.current}
                  workbook={details.workBookName}
                  worksheet={details.WorkSheetName}
                  provisioningId=''
                />
              </div>
            </div>

          )
        }
      </LayoutWrapper>
    </>
  );
};
export default FleetTelemetry;
